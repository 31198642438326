import { render, staticRenderFns } from "./GroupDisplay.vue?vue&type=template&id=4a521d79&scoped=true&"
import script from "./GroupDisplay.vue?vue&type=script&lang=js&"
export * from "./GroupDisplay.vue?vue&type=script&lang=js&"
import style0 from "./GroupDisplay.vue?vue&type=style&index=0&id=4a521d79&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a521d79",
  null
  
)

export default component.exports