export default {
  phaseContent: {
    q1: {
      answer: "b",
      answered: false,
      correct: false,
      audio: "bremen/q1.mp3",
      a: "Wo bist Du?",
      b: "Wie geht es dir?",
      c: "Woher kommst Du?",
    },
    q2: {
      answer: "a",
      answered: false,
      correct: false,
      audio: "bremen/q2.mp3",
      a: "Wie alt bist du?",
      b: "Eins lebst du?",
      c: "Wie alt bin ich?",
    },
    q3: {
      answer: "c",
      answered: false,
      correct: false,
      audio: "bremen/q3.mp3",
      a: "Wo ist die Post?",
      b: "Wo ist das Rathaus?",
      c: "Wo ist der Supermarkt?",
    },
    q4: {
      answer: "c",
      answered: false,
      correct: false,
      audio: "bremen/q4.mp3",
      a: "Wohin gehen wir?",
      b: "Wo sind wir?",
      c: "Woher kommst du?",
    },
    q5: {
      answer: "a",
      answered: false,
      correct: false,
      audio: "bremen/q5.mp3",
      a: "Wie viel Uhr ist es?",
      b: "Eins lebst du?",
      c: "Wo ist die Toilette?",
    },
  },
  questions: ["q1", "q2", "q3", "q4", "q5"],
};
