export default [
  {
    url: "https://player.vimeo.com/video/646706826",
    correct: "english",
  },
  {
    url: "https://player.vimeo.com/video/686394078",
    correct: "german",
  },
  {
    url: "https://player.vimeo.com/video/686401413",
    correct: "english",
  },
  {
    url: "https://player.vimeo.com/video/686395857",
    correct: "english",
  },
  {
    url: "https://player.vimeo.com/video/686395893",
    correct: "english",
  },
  {
    url: "https://player.vimeo.com/video/686395943",
    correct: "german",
  },
  {
    url: "https://player.vimeo.com/video/686396010",
    correct: "german",
  },
  {
    url: "https://player.vimeo.com/video/686396067",
    correct: "english",
  },
];
