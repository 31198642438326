<template>
  <div class="how-to">
    <h1 class="text-shadow-thin-white">{{ $t("frankfurt.howToPlay") }}</h1>
    <p class="dark-text">
      {{ $t("frankfurt.howP1") }}
      <span class="dark-highlight">
        {{ $t("frankfurt.howP1Highlight") }}
      </span>
    </p>
    <p class="dark-text">
      {{ $t("frankfurt.howP2") }}
    </p>
    <p class="dark-text">
      {{ $t("frankfurt.howP3") }}
      <span class="dark-highlight">
        {{ $t("frankfurt.howP3Highlight") }}
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: "FrankfurtHowTo",
};
</script>
