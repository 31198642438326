export default {
  words: [
    "schiff",
    "anker",
    "meer",
    "matrose",
    "hauptdeck",
    "rostock",
    "welle",
    "boot",
  ],
  wordPositions: {
    schiff: ["1-5", "1-6", "1-7", "1-8", "1-9", "1-10"],
    anker: ["3-3", "4-3", "5-3", "6-3", "7-3"],
    matrose: ["2-18", "3-18", "4-18", "5-18", "6-18", "7-18", "8-18"],
    boot: ["3-6", "4-6", "5-6", "6-6"],
    rostock: ["2-9", "2-10", "2-11", "2-12", "2-13", "2-14", "2-15"],
    hauptdeck: [
      "5-8",
      "5-9",
      "5-10",
      "5-11",
      "5-12",
      "5-13",
      "5-14",
      "5-15",
      "5-16",
    ],
    meer: ["7-10", "7-11", "7-12", "7-13"],
    welle: ["8-4", "8-5", "8-6", "8-7", "8-8"],
  },
  substantives: {
    schiff: "das Schiff",
    anker: "der Anker",
    matrose: "der Matrose",
    boot: "das Boot",
    rostock: "Rostock",
    hauptdeck: "das Hauptdeck",
    meer: "das Meer",
    welle: "die Welle",
  },
};
