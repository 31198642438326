<template>
  <div class="level lobby-level">
    <div class="content">
      <div class="col col-left">
        <div class="content-wrapper">
          <h1>
            {{ $t(`lobby.${contentKeys[contentIndex].title}`) }}
          </h1>
          <hr :style="`width: ${100 - (slideTime / 1500) * 100}%`" />
          <p>{{ $t(`lobby.${contentKeys[contentIndex].content}`) }}</p>
        </div>
      </div>
      <div class="col col-right">
        <div class="clock">
          <p>{{ roomTimeFormatted }}</p>
        </div>
        <component
          :is="`char-${me.int_character}`"
          :color="me.str_color"
          :size="'large'"
          class="player-character"
        />
      </div>
    </div>
    <transition name="slide-down">
      <platform-help :color="'lightgray'" v-if="showHelp" />
    </transition>

    <help-btn :helpShown="showHelp" @showHelp="showHelp = !showHelp" />

    <p class="copyright">Copyright © 2023 Goethe-Institut</p>
  </div>
</template>

<script>
import Char1 from "@/components/characters/Char1.vue";
import Char2 from "@/components/characters/Char2.vue";
import Char3 from "@/components/characters/Char3.vue";
import Char4 from "@/components/characters/Char4.vue";
import Char5 from "@/components/characters/Char5.vue";
import Char6 from "@/components/characters/Char6.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import PlatformHelp from "@/components/utils/PlatformHelp.vue";

export default {
  name: "Lobby",
  props: ["lobbySeconds"],
  data() {
    return {
      me: {},
      roomTime: this.lobbySeconds,
      contentKeys: [
        {
          title: "titleControls",
          content: "contentControls",
        },
        {
          title: "titlePassport",
          content: "contentPassport",
        },
        {
          title: "titleMap",
          content: "contentMap",
        },
        {
          title: "titlePointsKm",
          content: "contentPointsKm",
        },
        {
          title: "titleActivities",
          content: "contentActivities",
        },
      ],
      contentIndex: 0,
      slideTime: 1500,
      showHelp: false,
    };
  },
  components: {
    Char1,
    Char2,
    Char3,
    Char4,
    Char5,
    Char6,
    HelpBtn,
    PlatformHelp,
  },
  created() {
    this.me = JSON.parse(localStorage.getItem("user"));
    this.setTimeoutSlides();
    this.countDownRoom();
  },
  methods: {
    countDownRoom() {
      if (this.roomTime > 0) {
        setTimeout(() => {
          this.roomTime -= 1;
          this.countDownRoom();
        }, 1000);
      } else {
        console.log("end!");
      }
    },
    setTimeoutSlides() {
      setTimeout(() => {
        this.slideTime -= 1;
        if (this.slideTime == 0) {
          this.slideTime = 1500;
          if (this.contentIndex < 4) {
            this.contentIndex += 1;
          } else {
            this.contentIndex = 0;
          }
        }
        this.setTimeoutSlides();
      }, 10);
    },
  },
  computed: {
    roomTimeFormatted() {
      if (this.roomTime <= 0) {
        return "00:00";
      }
      var date = new Date(0);
      date.setSeconds(this.roomTime);
      return date.toISOString().substr(14, 5);
    },
  },
  beforeDestroy() {
    this.showHelp = false;
  },
};
</script>

<style scoped lang="scss">
.lobby-level {
  background: #d4ded5;
  display: grid;
  place-items: center;
  position: relative;
  overflow: hidden;
  .content {
    width: 70%;
    height: 80%;
    display: flex;
    flex-direction: row;
    .col {
      height: 100%;
      width: 50%;
      position: relative;
      box-sizing: border-box;
    }
    .col-left {
      .content-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        h1 {
          margin: 0;
          font-family: "TrashHand", sans-serif;
          font-size: 1.8rem;
        }
        hr {
          border: 2px solid #98c23d;
          border-radius: 5px;
          margin-left: 0;
        }
        p {
          font-family: "GoetheFFClan", sans-serif;
          font-size: 0.8rem;
        }
      }
    }
    .col-right {
      .player-character {
        position: absolute;
        bottom: 10px;
        left: 10px;
      }
      .clock {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 85%;
        max-width: 200px;
        height: 40%;
        max-height: 150px;
        background: #fff;
        border-radius: 20px;
        border: 2px solid #000;
        box-shadow: -0.4rem 0.3rem #000;
        display: grid;
        place-items: center;
        p {
          font-family: "GoetheFFClan", sans-serif;
          font-size: 3rem;
          margin: 0;
        }
      }
    }
  }
  .copyright {
    position: absolute;
    font-family: "GoetheFFClan", sans-serif;
    bottom: 5px;
    font-size: 0.6rem;
    color: rgb(68, 67, 67);
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .lobby-level {
    .content {
      .col-left {
        .content-wrapper {
          h1 {
            font-size: 3rem;
          }
          p {
            font-size: 1.5rem;
          }
        }
      }
      .col-right {
        .clock {
          max-width: 250px;
          max-height: 180px;
          p {
            font-size: 4rem;
          }
        }
      }
    }
  }
}
</style>
