<template>
  <div class="passport-bg" :class="{ tutorial: showTutorial }">
    <div class="passport-modal">
      <span class="close" @click="close">x</span>
      <div class="page upper">
        <table>
          <tbody>
            <tr>
              <td :class="visitedStations.includes('berlin') ? 'visited' : ''">
                {{ $t("cityNames.berlin") }}
              </td>
              <td :class="visitedStations.includes('bonn') ? 'visited' : ''">
                {{ $t("cityNames.bonn") }}
              </td>
              <td :class="visitedStations.includes('bremen') ? 'visited' : ''">
                {{ $t("cityNames.bremen") }}
              </td>
            </tr>
            <tr>
              <td :class="visitedStations.includes('dresden') ? 'visited' : ''">
                {{ $t("cityNames.dresden") }}
              </td>
              <td
                :class="visitedStations.includes('eisenach') ? 'visited' : ''"
              >
                {{ $t("cityNames.eisenach") }}
              </td>
              <td
                :class="visitedStations.includes('frankfurt') ? 'visited' : ''"
              >
                {{ $t("cityNames.frankfurt") }}
              </td>
            </tr>
            <tr>
              <td :class="visitedStations.includes('hamburg') ? 'visited' : ''">
                {{ $t("cityNames.hamburg") }}
              </td>
              <td :class="visitedStations.includes('koln') ? 'visited' : ''">
                {{ $t("cityNames.koln") }}
              </td>
              <td :class="visitedStations.includes('mainz') ? 'visited' : ''">
                {{ $t("cityNames.mainz") }}
              </td>
            </tr>
            <tr>
              <td :class="visitedStations.includes('munchen') ? 'visited' : ''">
                {{ $t("cityNames.munchen") }}
              </td>
              <td :class="visitedStations.includes('munster') ? 'visited' : ''">
                {{ $t("cityNames.munster") }}
              </td>
              <td
                :class="visitedStations.includes('nurnberg') ? 'visited' : ''"
              >
                {{ $t("cityNames.nurnberg") }}
              </td>
            </tr>
            <tr>
              <td :class="visitedStations.includes('rostock') ? 'visited' : ''">
                {{ $t("cityNames.rostock") }}
              </td>
              <td
                :class="visitedStations.includes('stuttgart') ? 'visited' : ''"
              >
                {{ $t("cityNames.stuttgart") }}
              </td>
              <td :class="visitedStations.includes('weimar') ? 'visited' : ''">
                {{ $t("cityNames.weimar") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="page lower">
        <div class="player-photo">
          <component
            :is="`char-${me.int_character}`"
            :color="me.str_color"
            :size="'big'"
            class="player-character"
          />
        </div>

        <div class="general-info">
          <div class="info-wrapper">
            <p class="name">{{ me.str_nickname }}</p>
            <p class="points">
              {{ `${$t("utils.group")}: ${group.int_session_group_number}` }}
            </p>
            <p class="points">{{ $t("utils.pontos") }}: {{ points }}</p>
            <p class="km">Km: {{ km }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Char1 from "@/components/characters/Char1.vue";
import Char2 from "@/components/characters/Char2.vue";
import Char3 from "@/components/characters/Char3.vue";
import Char4 from "@/components/characters/Char4.vue";
import Char5 from "@/components/characters/Char5.vue";
import Char6 from "@/components/characters/Char6.vue";
export default {
  name: "Passport",
  props: ["me", "group", "visitedStations", "points", "km", "showTutorial"],
  components: {
    Char1,
    Char2,
    Char3,
    Char4,
    Char5,
    Char6,
  },
  data() {
    return {
      kek: ["berlin", "koln"],
    };
  },
  methods: {
    close() {
      if (this.showTutorial) {
        this.$emit("nextTutorial", "done");
      }
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.passport-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  .passport-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 80%;
    max-width: 350px;
    max-height: 450px;
    background: #fff;
    border-radius: 15px;
    border: 2px solid #000;
    box-shadow: -0.4rem 0.3rem #000;
    display: flex;
    flex-direction: column;
    .close {
      background: #fff;
      position: absolute;
      top: 0px;
      right: -60px;
      font-family: "TrashHand", sans-serif;
      font-weight: 700;
      font-size: 2rem;
      padding: 5px 17px;
      border-radius: 50%;
      cursor: pointer;
    }
    .page {
      height: calc(50% - 1px);
      width: 100%;
      box-sizing: border-box;
      padding: 0.5rem;
    }
    .upper {
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, #fff 0%, #d5d5d5 100%);
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      table {
        width: 100%;
        height: 100%;
        font-size: 0.65rem;
        text-align: center;
        font-family: "GoetheFFClan", sans-serif;
        font-weight: 700;
        table-layout: fixed;
        color: #761e65;
      }
    }
    .lower {
      border-top: 1px solid #bebebe;
      background: rgb(255, 255, 255);
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
      display: flex;
      flex-direction: row;
      .player-photo {
        height: 100%;
        width: 50%;
        display: grid;
        place-items: center;
      }
      .general-info {
        height: 100%;
        width: 50%;
        display: grid;
        place-items: center;
        .info-wrapper {
          width: 100%;
          .name {
            margin: 0;
            font-family: "TrashHand", sans-serif;
            font-size: 1.3rem;
            letter-spacing: 0.1rem;
          }
          .points {
            margin: 0.5rem 0;
            font-family: "GoetheFFClan", sans-serif;
          }
          .km {
            margin: 0;
            font-family: "GoetheFFClan", sans-serif;
          }
        }
      }
    }
  }

  .visited {
    text-decoration: line-through;
    color: #9aca3b;
  }
}
.tutorial {
  z-index: 10;
}

@media (min-width: 1024px) {
  .passport-bg {
    .passport-modal {
      .upper {
        table {
          font-size: 0.8rem;
        }
      }
      .lower {
        .general-info {
          .info-wrapper {
            .name {
              font-size: 1.5rem;
            }
            .points {
              font-size: 1.1rem;
            }
            .km {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }
}
</style>
