<template>
  <div class="level nurnberg-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <h1>{{ $t("nurnberg.gameTitle") }}</h1>
        <div class="brands-wrapper">
          <div
            class="brand shadowed"
            v-for="brand in brandData[phase]"
            :key="brand.item"
            @click="pickBrand(brand.item, phase)"
          >
            <img
              :src="require(`@/assets/img/games/nurnberg/${brand.item}.jpg`)"
            />
            <p>{{ brand.name }}</p>
          </div>
        </div>
      </div>
      <div class="lower-panel">
        <div class="game-info-display">
          <p>{{ $t(`nurnberg.brandsText[${phase}]`) }}</p>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="gameData.points"
          @timeoutLevel="timeoutLevel"
        />
      </div>
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="4" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import nurnbergEnum from "@/enums/nurnberg.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    PlayerQtt,
  },
  name: "NurnbergLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "nurnberg",
      color: "orange",
      step: null,
      waitingStart: false,
      showHelp: false,
      brandData: nurnbergEnum.game,
      correctOptions: nurnbergEnum.correct,
      phase: 0,
      picks: [],
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }

      if (this.picks.length < this.gameData.picks.length) {
        const correct = this.correctOptions[this.gameData.phase - 1];
        if (this.gameData.picks.at(-1)) {
          this.$emit(
            "notify",
            this.$t("nurnberg.correct"),
            `${this.$t("nurnberg.correctOption")} ${correct}`,
            "success"
          );
        } else {
          this.$emit(
            "notify",
            this.$t("nurnberg.incorrect"),
            `${this.$t("nurnberg.correctOption")} ${correct}`,
            "failure"
          );
        }
      }

      this.picks = this.gameData.picks;
      this.phase = Math.min(this.gameData.phase, 4);
    },
    pickBrand(brand, phase) {
      if (!this.canAct) {
        return;
      }
      const updateData = { brand: brand, phase: phase };
      this.$emit("updateGame", updateData);
    },
  },
};
</script>

<style scoped lang="scss">
.nurnberg-level {
  background: #ed6f23;
}

.game-panel {
  display: grid;
  place-items: center;
  //game exclusive
  position: relative;
  h1 {
    margin: 0;
    position: absolute;
    top: 7px;
    font-family: "GoetheFFClanBold", sans-serif;
    font-size: 0.9rem;
  }
  .brands-wrapper {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .brand {
      width: 25%;
      max-width: 200px;
      border-radius: 10px;
      overflow: hidden;
      text-align: center;
      transition: 0.3s;
      cursor: pointer;
      img {
        width: 90%;
        margin: 0 auto;
      }
      p {
        font-family: "GoetheFFClanBold", sans-serif;
        font-size: 0.7rem;
      }
    }
    .brand:hover {
      border: 3px solid #98c23d;
      box-shadow: -0.3rem 0.2rem #98c23d;
      animation: shake 5s;
      animation-iteration-count: infinite;
    }
  }
}

.game-info-display {
  display: grid;
  place-items: center;
  //game exclusive
  p {
    width: 90%;
    text-align: center;
    margin: 0;
    font-family: "GoetheFFClan", sans-serif;
    font-size: 0.8rem;
  }
}

@media (min-width: 1024px) {
  .game-panel {
    h1 {
      top: 20px;
      font-size: 1.3rem;
    }
    .brands-wrapper {
      .brand {
        width: 30%;
        p {
          font-size: 1rem;
        }
      }
    }
  }

  .game-info-display {
    p {
      font-size: 1.2rem;
    }
  }
}
</style>
