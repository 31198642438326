<template>
  <div class="level bremen-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <h1
          v-if="stepData[questions[phase]].answered"
          :class="
            stepData[questions[phase]].correct
              ? 'correct-title'
              : 'incorrect-title'
          "
        >
          {{
            stepData[questions[phase]].correct
              ? $t(`bremen.correct`)
              : $t(`bremen.incorrect`)
          }}
        </h1>
        <h1 v-else>{{ $t("bremen.gameTitle") }} ({{ this.phase + 1 }}/5)</h1>
        <div class="content shadowed">
          <div class="audio-wrapper">
            <audio-player
              :file="stepData[questions[phase]].audio"
              class="player-btn"
              ref="audioPlayerBtn"
            />
          </div>
          <div class="options">
            <div class="options-wrapper">
              <p
                @click="pickOption('a', questions[phase])"
                :class="
                  stepData[questions[phase]].answered ? '' : 'hover-color'
                "
              >
                a) {{ stepData[questions[phase]].a }}
              </p>
              <p
                @click="pickOption('b', questions[phase])"
                :class="
                  stepData[questions[phase]].answered ? '' : 'hover-color'
                "
              >
                b) {{ stepData[questions[phase]].b }}
              </p>
              <p
                @click="pickOption('c', questions[phase])"
                :class="
                  stepData[questions[phase]].answered ? '' : 'hover-color'
                "
              >
                c) {{ stepData[questions[phase]].c }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="lower-panel">
        <div class="game-info-display">
          <div class="content">
            <div class="answer">
              <p v-if="stepData[questions[phase]].answered">
                {{ $t(`bremen.theQuestionIs`) }}
                <span
                  >"{{
                    stepData[questions[phase]][
                      stepData[questions[phase]].answer
                    ]
                  }}"</span
                >
                {{ $t(`bremen.whichMeans`) }}
                <span>"{{ $t(`bremen.${questions[phase]}`) }}"</span>
              </p>
              <p v-else class="flashing">{{ $t("bremen.waiting") }}</p>
            </div>
            <div class="controls">
              <div>
                <button
                  class="ctrl-btn thinly-shadowed"
                  @click="changeSlide('prev')"
                  :disabled="phase == 0"
                >
                  &lt;
                </button>
                <button
                  class="ctrl-btn thinly-shadowed"
                  @click="changeSlide('next')"
                  :disabled="phase == 4"
                >
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="gameData.points"
          @timeoutLevel="timeoutLevel"
        />
      </div>
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="4" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import AudioPlayer from "@/components/utils/AudioPlayer.vue";
import bremenEnum from "@/enums/bremen.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    AudioPlayer,
    PlayerQtt,
  },
  name: "BremenLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "bremen",
      color: "lightblue",
      step: "intro",
      waitingStart: false,
      stepData: bremenEnum.phaseContent,
      questions: bremenEnum.questions,
      phase: 0,
      showHelp: false,
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }

      let notified = false;
      for (const [index, question] of this.questions.entries()) {
        if (
          this.stepData[question].answered !=
            this.gameData[question].answered &&
          !notified
        ) {
          notified = true;
          const title = this.gameData[question].correct
            ? this.$t("bremen.correct")
            : this.$t("bremen.incorrect");
          const subtitle = `${
            this.stepData[this.questions[index]][
              this.stepData[this.questions[index]].answer
            ]
          } (${this.$t(`bremen.${this.questions[index]}`)})`;
          const context = this.gameData[question].correct
            ? "success"
            : "failure";
          this.$emit("notify", title, subtitle, context);
        }

        if (this.gameData[question].answered) {
          this.stepData[question].answered = true;
          this.stepData[question].correct = this.gameData[question].correct;
        }
      }
    },
    pickOption(option, question) {
      if (!this.canAct || this.stepData[this.questions[this.phase]].answered) {
        return;
      }

      const updateData = {
        option: option,
        question: question,
      };
      this.$emit("updateGame", updateData);
    },
    changeSlide(dir) {
      if (!this.canAct) {
        return;
      }

      if (dir == "prev") {
        this.phase = Math.max(this.phase - 1, 0);
      } else {
        this.phase = Math.min(this.phase + 1, 4);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bremen-level {
  background: #4dc1ec;
}

.game-panel {
  display: grid;
  place-items: center;
  //game exclusive
  position: relative;
  h1 {
    position: absolute;
    top: 10px;
    range: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    font-family: "GoetheFFClanBold", sans-serif;
    font-size: 1rem;
  }
  .correct-title {
    color: #9aca3b;
  }
  .incorrect-title {
    color: #e50019;
  }
  .content {
    width: 90%;
    max-width: 800px;
    height: 60%;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    .audio-wrapper {
      width: 50%;
      display: grid;
      place-items: center;
    }
    .options {
      width: 50%;
      display: grid;
      place-items: center;
      .options-wrapper {
        width: 100%;
        p {
          font-family: "GoetheFFClanBold", sans-serif;
          font-size: 0.8rem;
          margin: 0.9rem 0;
          transition: 0.3s;
        }
        .hover-color {
          color: #000;
          cursor: pointer;
        }
        .hover-color:hover {
          color: #9aca3b;
        }
      }
    }
  }
}

.game-info-display {
  display: grid;
  place-items: center;
  //game exclusive
  .content {
    width: 90%;
    height: 95%;
    display: flex;
    .answer {
      width: 70%;
      height: 100%;
      display: grid;
      place-items: center;
      p {
        width: 90%;
        margin: 0;
        font-family: "GoetheFFClan", sans-serif;
        font-weight: 700;
        font-size: 0.8rem;
      }
      span {
        font-family: "GoetheFFClanBold", sans-serif;
      }
      .flashing {
        animation: opacityFlash 1s linear infinite;
      }
    }
    .controls {
      width: 30%;
      height: 100%;
      display: grid;
      place-items: center;
      .ctrl-btn {
        margin: 0.4rem;
        padding: 0.2rem 0.5rem;
        font-family: "GoetheFFClanBold", sans-serif;
        font-size: 1rem;
        border-radius: 10px;
        background: #fff;
        cursor: pointer;
      }
      .ctrl-btn:disabled {
        background: #d4ded5;
        color: #000;
        cursor: default;
      }
    }
  }
}

@media (min-width: 650px) {
  .game-panel {
    h1 {
      top: 15px;
    }
    .content {
      .options {
        .options-wrapper {
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .game-info-display {
    .content {
      .answer {
        p {
          font-size: 1rem;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .game-panel {
    h1 {
      top: 25px;
      font-size: 1.5rem;
    }
    .content {
      .options {
        .options-wrapper {
          p {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .game-info-display {
    .content {
      .answer {
        p {
          font-size: 1.2rem;
        }
      }
      .controls {
        .ctrl-btn {
          margin: 0.6rem;
          padding: 0.2rem 0.7rem;
          font-size: 1.3rem;
        }
      }
    }
  }
}
</style>
