<template>
  <div class="how-to">
    <h1 class="text-shadow-thin-white">{{ $t("weimar.howToPlay") }}</h1>
    <p>{{ $t("weimar.howP1") }}</p>
    <p>
      {{ $t("weimar.howP2") }}
      <span class="highlight text-shadow-extra-thin-white">
        {{ $t("weimar.howP2Highlight") }}
      </span>
    </p>
    <p>
      {{ $t("weimar.howP3") }}
      <span class="highlight text-shadow-extra-thin-white">
        {{ $t("weimar.howP3Highlight") }}
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: "WeimarHowTo",
};
</script>
