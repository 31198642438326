<template>
  <div class="goethe-logo">
    <img src="@/assets/img/logo_white_vertical.svg" alt="Goethe-Institut" />
  </div>
</template>

<script>
export default {
  name: "GoetheLogo",
};
</script>
<style scoped lang="scss">
.goethe-logo {
  position: absolute;
  bottom: 5px;
  right: 10px;
  img {
    height: 45px;
    width: auto;
  }
}

@media (min-width: 1024px) {
  .goethe-logo {
    bottom: 10px;
    right: 15px;
    img {
      height: 70px;
      width: auto;
    }
  }
}
</style>
