import cityNames from "./content/cityNames.json";
import utils from "./content/utils.json";
import lobby from "./content/lobby.json";
import koln from "./content/koln.json";
import eisenach from "./content/eisenach.json";
import rostock from "./content/rostock.json";
import dresden from "./content/dresden.json";
import hamburg from "./content/hamburg.json";
import mainz from "./content/mainz.json";
import munchen from "./content/munchen.json";
import weimar from "./content/weimar.json";
import berlin from "./content/berlin.json";
import frankfurt from "./content/frankfurt.json";
import munster from "./content/munster.json";
import bremen from "./content/bremen.json";
import bonn from "./content/bonn.json";
import nurnberg from "./content/nurnberg.json";
import stuttgart from "./content/stuttgart.json";
import tutorial from "./content/tutorial.json";
import home from "./content/home.json";
import help from "./content/help.json";
import character from "./content/character.json";
import end from "./content/end.json";

const locale = "pt";

export default {
  cityNames: cityNames[locale],
  utils: utils[locale],
  lobby: lobby[locale],
  koln: koln[locale],
  eisenach: eisenach[locale],
  rostock: rostock[locale],
  dresden: dresden[locale],
  hamburg: hamburg[locale],
  mainz: mainz[locale],
  munchen: munchen[locale],
  weimar: weimar[locale],
  berlin: berlin[locale],
  frankfurt: frankfurt[locale],
  munster: munster[locale],
  bremen: bremen[locale],
  bonn: bonn[locale],
  nurnberg: nurnberg[locale],
  stuttgart: stuttgart[locale],
  tutorial: tutorial[locale],
  home: home[locale],
  help: help[locale],
  character: character[locale],
  end: end[locale],
};
