<template>
  <div class="logout-bg">
    <div class="logout-modal">
      <p>{{ $t("utils.logout") }}</p>
      <div class="btn-wrapper">
        <button @click="close" class="cancel thinly-shadowed">
          {{ $t("utils.cancel") }}
        </button>
        <button @click="logout" class="confirm thinly-shadowed">
          {{ $t("utils.confirmLogout") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logout",
  data() {
    return {
      kek: ["berlin", "koln"],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    logout() {
      this.$emit("logout");
    },
  },
};
</script>

<style scoped lang="scss">
.logout-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  .logout-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    background: #fff;
    border-radius: 15px;
    border: 2px solid #000;
    box-shadow: -0.4rem 0.3rem #000;
    p {
      text-align: center;
      font-family: "GoetheFFClanBold", sans-serif;
    }
    .btn-wrapper {
      width: 60%;
      display: flex;
      justify-content: space-around;
      margin: 1rem auto;
      button {
        cursor: pointer;
        border-radius: 5px;
        font-family: "GoetheFFClanBold", sans-serif;
        padding: 5px 10px;
      }
      .cancel {
        background: #9aca3b;
        color: #fff;
      }
      .confirm {
        background: #fff;
        color: #9aca3b;
      }
    }
  }
}

@media (min-width: 1024px) {
}
</style>
