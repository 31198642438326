<template>
  <div class="waiting-notification shadowed">
    <p class="title">{{ $t("utils.youAreFast") }}</p>
    <p class="subtitle">{{ $t("utils.waitingGroup") }}</p>
  </div>
</template>
<script>
export default {
  name: "WaitingPlayersNotification",
};
</script>

<style scoped lang="scss">
.waiting-notification {
  background: #fff;
  padding: 20px 40px;
  font-family: "GoetheFFClan", sans-serif;
  position: absolute;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title {
    font-size: 1.2rem;
    margin: 0;
  }
  .subtitle {
    margin: 0;
    font-size: 1rem;
  }
}
</style>
