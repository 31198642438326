<template>
  <div class="level frankfurt-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <div class="content-wrapper shadowed">
          <p
            v-for="number in codeData"
            :key="number.number"
            :class="['code-text', number.item, number.found ? 'found' : '']"
            @click="showNumber(number.number)"
          >
            {{ number.number }}
          </p>
          <transition name="bounce">
            <div class="code-player-wrapper">
              <audio-player-frankfurt
                :file="`frankfurt/code.mp3`"
                v-if="allFound"
                ref="codePlayerBtn"
              />
            </div>
          </transition>
        </div>
        <transition name="bounce">
          <div class="end shadowed" v-if="allowEnd" @click="endFrankfurt">
            <p>{{ $t("frankfurt.end") }}</p>
          </div>
        </transition>
      </div>
      <div class="lower-panel">
        <div class="game-info-display">
          <div class="audios-wrapper">
            <div v-for="number in codeData" :key="number.number" class="audio">
              <code-audio-player
                :item="number.number"
                :file="`frankfurt/${number.item}.mp3`"
                v-if="codeData[number.number].found"
              />
            </div>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="'-'"
          @timeoutLevel="timeoutLevel"
        />
      </div>
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="4" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import frankfurtEnum from "@/enums/frankfurt.enum.js";
import CodeAudioPlayer from "@/components/utils/CodeAudioPlayer.vue";
import AudioPlayerFrankfurt from "@/components/utils/AudioPlayerFrankfurt.vue";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    CodeAudioPlayer,
    AudioPlayerFrankfurt,
    PlayerQtt,
  },
  name: "FrankfurtLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
    allFound(val) {
      if (val) {
        setTimeout(() => {
          this.allowEnd = true;
        }, 3000);
      }
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "frankfurt",
      color: "darkblue",
      step: null,
      waitingStart: false,
      showHelp: false,
      codeData: frankfurtEnum.code,
      foundNumbers: [],
      allowEnd: false,
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }

      let notify = false;
      for (let i = 0; i <= 9; i++) {
        if (this.gameData.found.includes(i.toString())) {
          this.codeData[i].found = true;
        }
      }

      for (let number of this.gameData.found) {
        if (!this.foundNumbers.includes(number)) {
          this.foundNumbers.push(number);
          notify = true;
        }
      }

      if (this.foundNumbers.length && notify) {
        this.$emit(
          "notify",
          `${this.$t("frankfurt.numberFound")} ${this.foundNumbers
            .at(-1)
            .toString()}`,
          null,
          "success"
        );
      }
    },
    showNumber(number) {
      if (!this.canAct || this.codeData[number].found) {
        return;
      }

      this.$emit("updateGame", {
        number: number,
      });
    },
    endFrankfurt() {
      if (!this.canAct) {
        return;
      }

      this.$emit("updateGame", {
        end: true,
      });
    },
  },
  computed: {
    allFound() {
      for (let i = 0; i <= 9; i++) {
        if (!this.codeData[i].found) {
          return false;
        }
      }
      return true;
    },
  },
  beforeDestroy() {
    this.$refs.codePlayerBtn.stopSound();
  },
};
</script>

<style scoped lang="scss">
.frankfurt-level {
  background: #0a4879;
}

.game-panel {
  display: grid;
  place-items: center;
  position: relative;
  //game exclusive
  .content-wrapper {
    height: 180px;
    width: 245px;
    background: url("../../assets/img/games/frankfurt/goethe-haus.jpg") center
      no-repeat;
    background-size: cover;
    border-radius: 5px;
    position: relative;
  }
  .end {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
    p {
      margin: 0.5rem;
      font-family: "GoetheFFClan", sans-serif;
      font-size: 0.8rem;
    }
  }
  .code-player-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.game-info-display {
  display: grid;
  place-items: center;
  //game exclusive
  .audios-wrapper {
    width: 95%;
    max-width: 450px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .audio {
      width: 18px;
      text-align: center;
      p {
        margin: 0.5rem 0;
      }
    }
  }
}

.code-text {
  position: absolute;
  color: #4dc1ec;
  font-family: "GoetheFFClanBold", sans-serif;
  font-size: 1.1rem;
  margin: 0;
  opacity: 0.4;
  cursor: default;
}

.zero {
  top: 87%;
  left: 90%;
}

.one {
  top: 50%;
  left: 50%;
}

.two {
  top: 30%;
  left: 40%;
}

.three {
  top: 10%;
  left: 10%;
}

.four {
  top: 85%;
  left: 45%;
}

.five {
  top: 80%;
  left: 5%;
}

.six {
  top: 5%;
  left: 87%;
}

.seven {
  top: 50%;
  left: 80%;
}

.eight {
  top: 20%;
  left: 60%;
}

.nine {
  top: 40%;
  left: 20%;
}

.found {
  opacity: 1;
}

@media (min-width: 650px) {
  .game-panel {
    .content-wrapper {
      height: 200px;
      width: 272px;
    }
  }
}

@media (min-width: 800px) {
  .game-panel {
    .content-wrapper {
      height: 220px;
      width: 300px;
    }
  }
}

@media (min-width: 1024px) {
  .game-panel {
    .content-wrapper {
      height: 350px;
      width: 476px;
    }
    .end {
      p {
        margin: 0.7rem;
        font-size: 1.2rem;
      }
    }
  }
  .code-text {
    font-size: 1.2rem;
  }

  .game-info-display {
    .audios-wrapper {
      max-width: 600px;
      .audio {
        width: 25px;
        p {
          margin: 0.5rem 0;
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .game-panel {
    .content-wrapper {
      height: 500px;
      width: 680px;
    }
  }
}
</style>
