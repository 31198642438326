<template>
  <div class="how-to">
    <h1 class="text-shadow-thin-white">{{ $t("hamburg.howToPlay") }}</h1>
    <p>{{ $t("hamburg.howP1") }}</p>
    <p>
      {{ $t("hamburg.howP2") }}
      <span class="highlight text-shadow-extra-thin-white">
        {{ $t("hamburg.howP2Highlight") }}
      </span>
    </p>
    <p>{{ $t("hamburg.howP3") }}</p>
    <p>{{ $t("hamburg.howP4") }}</p>
  </div>
</template>
<script>
export default {
  name: "HamburgHowTo",
};
</script>
