<template>
  <div class="character-creation-step-screen nickname-screen">
    <h1 class="text-shadow-thin-black">{{ $t(`character.lets`) }}</h1>
    <div class="content-wrapper">
      <div class="nickname-card shadowed">
        <input
          type="text"
          :placeholder="$t(`character.typeIn`)"
          v-model="nickname"
          maxlength="10"
        />
        <transition name="fade">
          <button
            class="char-create-btn shadowed"
            v-if="nickname.length >= 3"
            @click="submitNickname"
          >
            {{ $t(`character.go`) }}
          </button>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nickname",
  data() {
    return {
      nickname: "",
    };
  },
  methods: {
    submitNickname() {
      this.$emit("submitNickname", this.nickname);
    },
  },
};
</script>

<style scoped lang="scss">
.nickname-screen {
  background: #761e65;
  .content-wrapper {
    height: 40%;
    width: 40%;
    max-height: 250px;
    max-width: 530px;
    .nickname-card {
      height: 100%;
      width: 100%;
      background: #fff;
      border-radius: 30px;
      display: grid;
      place-items: center;
      position: relative;
      font-family: "GoetheFFClan", sans-serif;
      input {
        background: #c4c4c4;
        padding: 10px 5px;
        border: 2px solid #b1b1b1;
        border-radius: 50px;
        width: 80%;
        max-width: 250px;
        text-align: center;
        font-weight: 600;
        color: #4b4b4b;
      }
      button {
        bottom: -18px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .nickname-screen {
    .nickname-card {
      input {
        font-size: 1rem;
      }
      button {
        width: 150px !important;
      }
    }
  }
}
</style>
