export default {
  cards: [
    { team: "bayern", card: "bayern-1", flipped: false, found: false },
    { team: "borussia", card: "borussia-1", flipped: false, found: false },
    { team: "nurnberg", card: "nurnberg-1", flipped: false, found: false },
    { team: "hamburger", card: "hamburger-1", flipped: false, found: false },
    { team: "nurnberg", card: "nurnberg-2", flipped: false, found: false },
    { team: "schalke", card: "schalke-1", flipped: false, found: false },
    { team: "borussia", card: "borussia-2", flipped: false, found: false },
    { team: "stuttgart", card: "stuttgart-1", flipped: false, found: false },
    { team: "bayern", card: "bayern-2", flipped: false, found: false },
    { team: "schalke", card: "schalke-2", flipped: false, found: false },
    { team: "hamburger", card: "hamburger-2", flipped: false, found: false },
    { team: "stuttgart", card: "stuttgart-2", flipped: false, found: false },
  ],
  teams: {
    bayern: "Bayern München",
    borussia: "Borussia Dortmund",
    nurnberg: "FC Nürnberg",
    schalke: "Gelsenkirchen-Schalke",
    hamburger: "Hamburger Sport-Verein",
    stuttgart: "VfB Stuttgart",
  },
};
