<template>
  <div class="help" :class="color">
    <div class="help-content">
      <component :is="howTos[level]" />
    </div>
  </div>
</template>
<script>
import RostockHowTo from "@/components/howTos/RostockHowTo";
import HamburgHowTo from "@/components/howTos/HamburgHowTo";
import DresdenHowTo from "@/components/howTos/DresdenHowTo";
import MainzHowTo from "@/components/howTos/MainzHowTo";
import EisenachHowTo from "@/components/howTos/EisenachHowTo";
import KolnHowTo from "@/components/howTos/KolnHowTo";
import MunchenHowTo from "@/components/howTos/MunchenHowTo.vue";
import WeimarHowTo from "@/components/howTos/WeimarHowTo.vue";
import BerlinHowTo from "@/components/howTos/BerlinHowTo.vue";
import FrankfurtHowTo from "@/components/howTos/FrankfurtHowTo.vue";
import MunsterHowTo from "@/components/howTos/MunsterHowTo.vue";
import BremenHowTo from "@/components/howTos/BremenHowTo.vue";
import BonnHowTo from "@/components/howTos/BonnHowTo.vue";
import NurnbergHowTo from "@/components/howTos/NurnbergHowTo.vue";
import StuttgartHowTo from "@/components/howTos/StuttgartHowTo.vue";

export default {
  name: "Help",
  props: ["level", "color"],
  components: {
    RostockHowTo,
    HamburgHowTo,
    DresdenHowTo,
    MainzHowTo,
    EisenachHowTo,
    KolnHowTo,
    MunchenHowTo,
    WeimarHowTo,
    BerlinHowTo,
    FrankfurtHowTo,
    MunsterHowTo,
    BremenHowTo,
    BonnHowTo,
    NurnbergHowTo,
    StuttgartHowTo,
  },
  data() {
    return {
      howTos: {
        rostock: "RostockHowTo",
        hamburg: "HamburgHowTo",
        dresden: "DresdenHowTo",
        mainz: "MainzHowTo",
        eisenach: "EisenachHowTo",
        koln: "KolnHowTo",
        munchen: "MunchenHowTo",
        weimar: "WeimarHowTo",
        berlin: "BerlinHowTo",
        frankfurt: "FrankfurtHowTo",
        munster: "MunsterHowTo",
        bremen: "BremenHowTo",
        bonn: "BonnHowTo",
        nurnberg: "NurnbergHowTo",
        stuttgart: "StuttgartHowTo",
      },
    };
  },
};
</script>

<style scoped lang="scss">
.help {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  .help-content {
    height: 80%;
    width: 65%;
  }
}

.green {
  background: #9aca3b;
}

.lightgray {
  background: #d4ded5;
}

.mediumgray {
  background: #b5b5b5;
}

.darkgray {
  background: #6a7a83;
}

.lightblue {
  background: #4dc1ec;
}

.orange {
  background: #ed6f23;
}

.purple {
  background: #761e65;
}

.darkblue {
  background: #0a4879;
}

.brown {
  background: #48210f;
}
</style>
