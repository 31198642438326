export default {
  data: [
    {
      comment: "c1",
      restaurant: "r1",
      score: "4.5",
      hours: "h1",
      pic: "place1",
      foodList: [
        {
          title: "Donut",
          item: "donut",
        },
        {
          title: "Bretzel",
          item: "bretzel",
        },
        {
          title: "Baguette",
          item: "baguette",
        },
      ],
    },

    {
      comment: "c2",
      restaurant: "r2",
      score: "5",
      hours: "h2",
      pic: "place2",
      foodList: [
        {
          title: "Currywurst",
          item: "currywurst",
        },
        {
          title: "Ramen",
          item: "ramen",
        },
        {
          title: "Yakisoba",
          item: "yakisoba",
        },
      ],
    },

    {
      comment: "c3",
      restaurant: "r3",
      score: "4.7",
      hours: "h3",
      pic: "place3",
      foodList: [
        {
          title: "Pudding",
          item: "pudding",
        },
        {
          title: "Eis",
          item: "eis",
        },
        {
          title: "Apfelstrudel",
          item: "apfelstrudel",
        },
      ],
    },

    {
      comment: "c4",
      restaurant: "r4",
      score: "4.3",
      hours: "h4",
      pic: "place4",
      foodList: [
        {
          title: "Lasagne",
          item: "lasagne",
        },
        {
          title: "Eisbein",
          item: "eisbein",
        },
        {
          title: "Hackbraten",
          item: "hackbraten",
        },
      ],
    },

    {
      comment: "c5",
      restaurant: "r5",
      score: "4.9",
      hours: "h5",
      pic: "place5",
      foodList: [
        {
          title: "Ratatouille",
          item: "ratatouille",
        },
        {
          title: "Sauer gurken",
          item: "sauergurken",
        },
        {
          title: "Sauerkraut",
          item: "sauerkraut",
        },
      ],
    },

    {
      comment: "c6",
      restaurant: "r6",
      score: "5.0",
      hours: "h6",
      pic: "place6",
      foodList: [
        {
          title: "Schnitzel",
          item: "schnitzel",
        },
        {
          title: "Brathähnchen",
          item: "brathahnchen",
        },
        {
          title: "Fish & Chips",
          item: "fishchips",
        },
      ],
    },
  ],

  correctFoods: [
    "Bretzel",
    "Currywurst",
    "Apfelstrudel",
    "Eisbein",
    "Sauerkraut",
    "Schnitzel",
  ],
};
