<template>
  <div class="level dresden-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <div class="dresden-wrapper">
          <div class="dresden-content">
            <div class="dresden-picture">
              <img
                :src="
                  require(`@/assets/img/games/dresden/${dresdenData[currentStep].img}`)
                "
              />
            </div>
            <div class="dresden-details">
              <div>
                <h1>{{ $t(`dresden.${dresdenData[currentStep].item}`) }}</h1>
                <p class="phase-details">
                  {{ $t(`dresden.${dresdenData[currentStep].item}-details`) }}
                </p>
              </div>
            </div>
          </div>
          <div class="dresden-phase-word">
            <div class="word-wrapper">
              <div
                v-for="(letter, index) in dresdenData[currentStep].letters"
                :key="index"
              >
                <p>
                  {{
                    dresdenData[currentStep].taken[
                      dresdenData[currentStep].options.indexOf(letter)
                    ]
                      ? letter
                      : "_"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lower-panel">
        <div class="game-info-display">
          <div class="content">
            <div class="tries-wrapper">
              <p>
                {{ $t(`dresden.remainingTries`) }}
                <span class="text-shadow-extra-thin-white">{{
                  remainingTries
                }}</span>
              </p>
            </div>
            <div class="options-wrapper">
              <div class="options-content text-shadow-extra-thin-white">
                <div
                  v-for="index in dresdenData[currentStep].options.length"
                  :key="index"
                >
                  <p
                    @click="pickLetter(index - 1)"
                    :class="
                      dresdenData[currentStep].taken[index - 1]
                        ? dresdenData[currentStep].correct[index - 1]
                          ? 'selected'
                          : 'wrong-option'
                        : ''
                    "
                  >
                    {{ dresdenData[currentStep].options[index - 1] }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="gameData.points"
          @timeoutLevel="timeoutLevel"
        />
      </div>
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="4" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import dresdenEnum from "@/enums/dresden.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    PlayerQtt,
  },
  name: "DresdenLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "dresden",
      color: "lightblue",
      step: null,
      waitingStart: false,
      showHelp: false,
      dresdenData: dresdenEnum,
      currentStep: 0,
      remainingTries: 15,
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }

      for (let i = 0; i < this.gameData.phases_options.length; i++) {
        this.dresdenData[i].taken = this.gameData.phases_options[i];
      }

      if (
        this.currentStep < Math.min(this.gameData.phase, 5) &&
        !this.isFirstLoad
      ) {
        this.$emit(
          "notify",
          `${this.$t("dresden.hallFound")} ${
            this.dresdenData[this.currentStep].german
          }`,
          null,
          "success"
        );
      }

      if (
        this.remainingTries > Math.max(this.gameData.tries, 0) &&
        !this.isFirstLoad
      ) {
        this.$emit("notify", `${this.$t("dresden.wrongTry")}`, null, "failure");
      }

      this.isFirstLoad = false;
      this.currentStep = Math.min(this.gameData.phase, 4);
      this.remainingTries = Math.max(this.gameData.tries, 0);
    },
    pickLetter(index) {
      if (this.dresdenData[this.currentStep].taken[index] || !this.canAct) {
        return;
      }
      let updateData = {
        step: this.currentStep,
        index: index,
      };
      this.$emit("updateGame", updateData);
    },
  },
};
</script>

<style scoped lang="scss">
.dresden-level {
  background: #4dc1ec;
}

.game-panel {
  display: grid;
  place-items: center;
  //game exclusive
  .dresden-wrapper {
    width: 93%;
    height: 93%;
    display: flex;
    flex-direction: column;
    font-family: "GoetheFFClan", sans-serif;
    .dresden-content {
      width: 100%;
      height: 85%;
      display: flex;
      flex-direction: row;
      .dresden-picture {
        height: 100%;
        width: 40%;
        position: relative;
        img {
          width: 90%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .dresden-details {
        height: 100%;
        width: 60%;
        display: grid;
        place-items: center;
        h1 {
          font-size: 1rem;
        }
        .phase-details {
          font-size: 0.6rem;
          color: #757575;
        }
      }
    }
    .dresden-phase-word {
      width: 100%;
      height: 15%;
      display: grid;
      place-items: center;
      .word-wrapper {
        display: flex;
        flex-direction: row;
        p {
          font-family: "TrashHand", sans-serif;
          margin: 0 0.2rem;
          padding: 0 0.35rem;
        }
      }
    }
  }
}

.game-info-display {
  display: grid;
  place-items: center;
  //game exclusive
  .content {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    .tries-wrapper {
      width: 100%;
      height: 50%;
      display: grid;
      place-items: center;
      p {
        font-family: "GoetheFFClan", sans-serif;
        font-weight: 700;
        margin: 0;
        font-size: 0.8rem;
      }
    }
    .options-wrapper {
      width: 100%;
      height: 50%;
      display: grid;
      place-items: center;
      .options-content {
        display: flex;
        flex-direction: row;
        p {
          font-family: "TrashHand", sans-serif;
          margin: 0 0.2rem;
          padding: 0 0.35rem;
          cursor: pointer;
          font-weight: 700;
        }
      }
    }
  }
  .selected {
    color: #98c23d;
  }

  .wrong-option {
    color: #c2413d;
  }
}

@media (min-width: 1024px) {
  .game-panel {
    .dresden-wrapper {
      .dresden-content {
        .dresden-details {
          h1 {
            font-size: 1.7rem;
          }
          .phase-details {
            font-size: 1rem;
          }
        }
      }
      .dresden-phase-word {
        .word-wrapper {
          p {
            font-size: 1.6rem;
            margin: 0 0.3rem;
            padding: 0 0.5rem;
          }
        }
      }
    }
  }

  .game-info-display {
    .content {
      .tries-wrapper {
        p {
          font-size: 1.5rem;
        }
      }
      .options-wrapper {
        .options-content {
          p {
            font-size: 1.8rem;
            margin: 0 0.4rem;
            padding: 0 0.5rem;
          }
        }
      }
    }
  }
}
</style>
