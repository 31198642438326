<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="char-svg"
    :class="`char-size-${size}`"
    viewBox="0 0 62.323 94.514"
  >
    <g
      id="Group_612"
      data-name="Group 612"
      transform="translate(-422.457 -459.933)"
    >
      <path
        id="Path_516"
        data-name="Path 516"
        d="M225.823,85.345a27.864,27.864,0,0,0-1.74-9.833,12.442,12.442,0,0,0-2.72-4.206c-.221-.331-.439-.664-.678-.98a4.158,4.158,0,0,0-3.126-1.744,12.612,12.612,0,0,0-5.337-1.253c-6.085-.036-10.81,3.432-14.055,8.173-.554-.131-1.112-.244-1.673-.345-3.411-11.419-18.02-9.761-24.288-.931-5.99,8.439-4.5,20.633-3.478,31.148a122.132,122.132,0,0,0,1.579,17.053,4.548,4.548,0,0,0,1.562,2.668c1.869,5.264,5.953,9.414,13.527,11.28.835.206,1.681.371,2.531.518.885,6.158-.023,12.518,2.211,18.461,1.435,3.815,7.606,2.162,6.155-1.7-1.934-5.145-1.492-10.754-1.954-16.211a39.44,39.44,0,0,0,10.014-1.282c2.146,4.822,1.241,11.853,1.841,16.639.505,4.027,6.893,4.076,6.382,0-.748-5.974-.139-12.924-2.337-18.695.777-.35,1.546-.72,2.294-1.125,9.257-4.994,12.273-15,12.269-24.975,0-.281-.022-.559-.028-.839a75.47,75.47,0,0,0,1.123-9.132A75.652,75.652,0,0,0,225.823,85.345Z"
        transform="translate(256.656 394.837)"
        :fill="color"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="4"
      />
      <g id="Group_604" data-name="Group 604" transform="translate(0 1)">
        <path
          id="Path_526"
          data-name="Path 526"
          d="M197.993,81.964a7.385,7.385,0,0,1-9.594.55c-2.033-1.413-3.944,1.908-1.933,3.307a11.221,11.221,0,0,0,14.235-1.15C202.515,82.989,199.8,80.286,197.993,81.964Z"
          transform="translate(261.586 397.801)"
          fill="#1c1c1c"
          stroke="#000"
          stroke-width="1"
        />
        <path
          id="Path_527"
          data-name="Path 527"
          d="M180.708,72.076c-4.117,0-4.117,6.382,0,6.382S184.824,72.076,180.708,72.076Z"
          transform="translate(259.376 395.214)"
          fill="#1c1c1c"
          stroke="#000"
          stroke-width="1"
        />
        <path
          id="Path_528"
          data-name="Path 528"
          d="M203.208,70.576c-4.117,0-4.117,6.382,0,6.382S207.324,70.576,203.208,70.576Z"
          transform="translate(265.597 394.799)"
          fill="#1c1c1c"
          stroke="#000"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Char2",
  props: ["color", "size"],
};
</script>
