<template>
  <div class="notification" @click="close">
    <div class="notification-box thinly-shadowed">
      <h1 :class="context == null ? '' : context">{{ title }}</h1>
      <h2 v-if="subtitle">{{ subtitle }}</h2>
    </div>
  </div>
</template>
<script>
export default {
  name: "Notification",
  props: ["title", "subtitle", "context"],
  data() {
    return {
      timer: null,
    };
  },
  watch: {
    title() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.close();
      }, 5000);
    },
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.close();
    }, 5000);
  },
  methods: {
    close() {
      clearTimeout(this.timer);
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.notification {
  position: absolute;
  top: 0;
  left: 20px;
  display: grid;
  place-items: center;
  padding-top: 5px;
  cursor: pointer;
  .notification-box {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 5px 10px;
    box-sizing: border-box;
    font-family: "GoetheFFClan", sans-serif;
    h1 {
      font-size: 0.85rem;
      margin: 0.2rem 0;
      font-weight: 700;
    }
    .success {
      color: #98c23d;
    }
    .failure {
      color: #e50019;
    }
    h2 {
      font-size: 0.7rem;
      margin: 0.2rem 0;
      font-weight: 500;
    }
  }
}

@media (min-width: 1024px) {
  .notification {
    padding-left: 10px;
    padding-top: 10px;
    .notification-box {
      padding: 5px 10px;
      h1 {
        font-size: 1.2rem;
        margin: 0.3rem 0;
      }
      h2 {
        font-size: 0.9rem;
        margin: 0.3rem 0;
      }
    }
  }
}
</style>
