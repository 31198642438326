<template>
  <div class="how-to">
    <h1 class="text-shadow-thin-white">{{ $t("munster.howToPlay") }}</h1>
    <p class="dark-text">{{ $t("munster.howP1") }}</p>
    <p class="dark-text">
      {{ $t("munster.howP2") }}
      <span class="dark-highlight">
        {{ $t("munster.howP2Highlight") }}
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: "munsterHowTo",
};
</script>
