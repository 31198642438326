<template>
  <div
    class="audio-btn thinly-shadowed"
    :class="playing ? 'playing-btn' : ''"
    @click="controlSound(file)"
  >
    <p>{{ item }}</p>
  </div>
</template>

<script>
export default {
  name: "CodeAudioPlayer",
  props: ["item", "file"],
  data() {
    return {
      playing: false,
      audioObj: null,
    };
  },
  methods: {
    controlSound(song) {
      if (this.playing) {
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        this.audioObj = null;
        this.playing = false;
        return;
      }

      this.audioObj = new Audio(require(`../../assets/audio/${song}`));
      this.audioObj.play();
      this.playing = true;

      const that = this;
      this.audioObj.onended = function () {
        that.playing = false;
      };
    },
    stopSound() {
      if (this.audioObj != null) {
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        this.audioObj = null;
        this.playing = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.audio-btn {
  height: 18px;
  width: 18px;
  background: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  p {
    margin: 0;
    font-family: "GoethFFClanBold", sans-serif;
    font-weight: 700;
  }
}

.audio-btn:hover {
  background: #33a5ce;
  img {
    filter: none;
    z-index: 2;
  }
}

.playing-btn {
  background: #e9a378;
}

.playing-btn:hover {
  background: #e9a378;
  img {
    filter: none;
    z-index: 2;
  }
}

@media (min-width: 1024px) {
  .audio-btn {
    height: 25px;
    width: 25px;
  }
}
</style>
