<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 99.492 100.833"
    class="char-svg"
    :class="`char-size-${size}`"
  >
    <g
      id="Group_610"
      data-name="Group 610"
      transform="translate(-752.374 -455.718)"
    >
      <path
        id="Path_515"
        data-name="Path 515"
        d="M430.333,77.254c-1.906.207-3.8.488-5.694.805.8-2.044,1.755-4.016,2.746-6a4.547,4.547,0,0,0-1.6-6.113,4.489,4.489,0,0,0-6.113,1.6,60.67,60.67,0,0,0-4.705,11.279,4.98,4.98,0,0,0-.163,1.044c-2.281.417-4.565.807-6.861,1.1-2.423-7.194-6.26-13.422-11.954-16.368C382.1,57.421,372.3,66.7,366.4,80.153c-1.9-.373-3.792-.811-5.675-1.275-1.672-3.463-3.127-7.024-4.826-10.473-2.548-5.17-10.253-.639-7.716,4.51.5,1.02.982,2.053,1.453,3.09-1.634-.41-3.27-.8-4.917-1.144-5.623-1.177-8.015,7.435-2.377,8.616,1.7.356,3.4.758,5.084,1.181-1.185,1.4-2.363,2.807-3.651,4.117-4.039,4.113,2.276,10.434,6.318,6.318,2.4-2.441,4.41-5.218,6.807-7.659.082-.084.134-.174.208-.258,2.064.525,4.134,1.022,6.218,1.447-3.694,12.426-4.6,26.084-2.83,33.43a26.648,26.648,0,0,0,14.647,18.16c-.114,5.5-.161,10.942,1.049,16.391.892,4.017,7.045,2.313,6.155-1.7a52.989,52.989,0,0,1-.894-12.734,22.656,22.656,0,0,0,9.942-.232v13.815c0,4.117,6.382,4.117,6.382,0V138.886c8.357-6.077,12.227-18.543,13.335-29.392a74.084,74.084,0,0,0-.989-19.888c2.208-.333,4.408-.737,6.6-1.153a34.306,34.306,0,0,1,7.887,5.789c4.113,4.039,10.435-2.277,6.319-6.317-.56-.55-1.15-1.071-1.739-1.594.378-.046.753-.1,1.132-.14C436,85.574,436.058,76.633,430.333,77.254Z"
        transform="translate(415.262 395.642)"
        :fill="color"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="4"
      />
      <g id="Group_606" data-name="Group 606">
        <path
          id="Path_519"
          data-name="Path 519"
          d="M381.83,85.035a12.4,12.4,0,0,1-9.589-.234c-2.226-1.025-4.173,2.275-1.931,3.307a16.2,16.2,0,0,0,12.537.618C385.121,87.839,384.134,84.135,381.83,85.035Z"
          transform="translate(423.608 401.861)"
          fill="#1c1c1c"
          stroke="#000"
          stroke-width="1"
        />
        <path
          id="Path_520"
          data-name="Path 520"
          d="M368.06,78.384c-3.292,0-3.292,5.106,0,5.106S371.352,78.384,368.06,78.384Z"
          transform="translate(422.578 400.141)"
          fill="#1c1c1c"
          stroke="#000"
          stroke-width="1"
        />
        <path
          id="Path_521"
          data-name="Path 521"
          d="M384.06,78.05c-3.292,0-3.292,5.106,0,5.106S387.352,78.05,384.06,78.05Z"
          transform="translate(427.001 400.049)"
          fill="#1c1c1c"
          stroke="#000"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Char4",
  props: ["color", "size"],
};
</script>
