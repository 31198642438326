<template>
  <div class="how-to">
    <h1 class="text-shadow-thin-white">{{ $t("berlin.howToPlay") }}</h1>
    <p>{{ $t("berlin.howP1") }}</p>
    <p>
      <span class="highlight text-shadow-extra-thin-white">
        {{ $t("berlin.howP2") }}
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: "BerlinHowTo",
};
</script>
