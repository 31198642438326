<template>
  <div
    class="pin"
    :class="city.name"
    @click="handleClick"
    :id="`id-pin-${city.name}`"
  >
    <div v-if="!city.done && !clicked" class="pin-img-holder">
      <img src="@/assets/img/map/pin.svg" class="pin-img" />
    </div>
    <div v-else-if="!city.done && clicked" class="pin-img-holder">
      <img src="@/assets/img/map/pin-clicked.svg" class="pin-img pin-move" />
      <p class="city-name thinly-shadowed">
        {{ $t(`cityNames.${city.name}`) }}
      </p>
    </div>
    <div v-else-if="city.done && clicked" class="pin-img-holder">
      <img src="@/assets/img/map/pin-done.svg" class="pin-img pin-move" />
      <p class="city-name thinly-shadowed">
        {{ $t(`cityNames.${city.name}`) }}
      </p>
    </div>
    <div v-else class="pin-img-holder">
      <img src="@/assets/img/map/pin-done.svg" class="pin-img pin-move" />
    </div>
  </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
export default {
  name: "Pin",
  props: ["city", "km", "canAct"],
  data() {
    return {
      clicked: false,
    };
  },
  mounted() {
    const that = this;
    EventBus.$on("pinSelected", function (city) {
      that.deselect(city);
    });
  },
  methods: {
    handleClick() {
      if (!this.canAct) {
        return;
      }

      if (this.city.done) {
        this.clicked = !this.clicked;
        return;
      }

      if (this.clicked) {
        EventBus.$emit("goToStation", { level: this.city.name, km: this.km });
      } else {
        this.clicked = true;
        EventBus.$emit("pinSelected", this.city.name);
      }
    },
    deselect(city) {
      if (city != this.city.name) {
        this.clicked = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pin {
  position: absolute;
  height: 30px;
  width: 30px;
  .pin-img-holder {
    height: 100%;
    width: 100%;
    position: relative;
    cursor: pointer;
    .pin-img {
      height: 100%;
      width: 100%;
    }
    .pin-move {
      animation: MoveUpDown 1s linear infinite;
      margin-top: 0px;
    }
    .city-name {
      width: 50px;
      position: absolute;
      bottom: -15px;
      left: -15px;
      margin: 0 auto;
      text-align: center;
      font-family: "TrashHand", sans-serif;
      font-size: 0.8rem;
      background: #fff;
      border-radius: 10px;
      padding: 0 0.3rem;
    }
  }
}

.rostock {
  top: 3%;
  left: 62%;
}

.koln {
  top: 38%;
  left: 7%;
}

.dresden {
  top: 38%;
  left: 72%;
}

.eisenach {
  top: 38%;
  left: 35%;
}

.hamburg {
  top: 5%;
  left: 35%;
}

.mainz {
  top: 63%;
  left: 15%;
}

.munchen {
  top: 78%;
  left: 55%;
}

.weimar {
  top: 38%;
  left: 55%;
}

.berlin {
  top: 20%;
  left: 70%;
}

.frankfurt {
  top: 54%;
  left: 30%;
}

.munster {
  top: 30%;
  left: 16%;
}

.bremen {
  top: 15%;
  left: 25%;
}

.bonn {
  top: 46%;
  left: 18%;
}

.nurnberg {
  top: 60%;
  left: 50%;
}

.stuttgart {
  top: 71%;
  left: 25%;
}

@keyframes MoveUpDown {
  0%,
  100% {
    margin-top: 0px;
  }
  50% {
    margin-top: 5px;
  }
}

@media (min-width: 1024px) {
  .pin {
    height: 60px;
    width: 60px;
    .pin-img-holder {
      height: 100%;
      width: 100%;
      .pin-img {
        height: 100%;
        width: 100%;
      }
      .city-name {
        left: -20px;
        width: 90px;
        bottom: -30px;
        font-size: 1.5rem;
      }
    }
  }

  .rostock {
    top: 5%;
  }

  .koln {
    top: 46%;
  }

  .dresden {
    top: 42%;
  }

  .eisenach {
    top: 42%;
  }

  .hamburg {
    top: 7%;
  }

  .mainz {
    top: 67%;
  }

  .munchen {
    top: 80%;
  }

  .weimar {
    top: 42%;
  }

  .berlin {
    top: 24%;
  }

  .frankfurt {
    top: 58%;
  }

  .munster {
    top: 34%;
  }

  .bremen {
    top: 19%;
  }

  .bonn {
    top: 50%;
  }

  .nurnberg {
    top: 64%;
  }

  .stuttgart {
    top: 75%;
  }
}
</style>
