export default {
  order: [
    "car",
    "toothpaste",
    "christmas_tree",
    "thermos",
    "press",
    "newspaper",
    "helicopter",
    "tape",
    "bike",
    "gummy_bear",
  ],
  items: {
    car: { item: "car", found: false, german: "das Auto" },
    toothpaste: { item: "toothpaste", found: false, german: "die Zahnpasta" },
    christmas_tree: {
      item: "christmas_tree",
      found: false,
      german: "der Weihnachtsbaum",
    },
    thermos: { item: "thermos", found: false, german: "die Thermoskanne" },
    press: { item: "press", found: false, german: "die Druckpresse" },
    newspaper: { item: "newspaper", found: false, german: "die Zeitung" },
    helicopter: {
      item: "helicopter",
      found: false,
      german: "der Hubschrauber",
    },
    tape: { item: "tape", found: false, german: "das Klebeband" },
    bike: { item: "bike", found: false, german: "das Fahrrad" },
    gummy_bear: {
      item: "gummy_bear",
      found: false,
      german: "der Gummibärchen",
    },
  },
};
