<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="char-svg"
    :class="`char-size-${size}`"
    viewBox="0 0 72.724 83.962"
  >
    <g
      id="Group_609"
      data-name="Group 609"
      transform="translate(-942.043 -461.944)"
    >
      <path
        id="Path_518"
        data-name="Path 518"
        d="M515.756,115.145c-.7-14.147-18-21.351-31.516-18.483.564-.12,2.937-18.487-.633-20.345-9.576-4.973-14.189,11.031-15.132,16.7-.287,1.722-.534,3.564-.848,5.42a4.494,4.494,0,0,0-.475-.323c0-.868,0-1.7-.015-2.483-.049-2.116-.1-4.234-.248-6.341a40.756,40.756,0,0,0,.765-8.656c.014-3.1-2.846-4.825-5.655-4.308-9.969,1.834-13.085,13.941-14.225,22.462-2.416,18.08.992,33.88,17.148,39.372a40.2,40.2,0,0,0,1.144,14.8c1.047,3.976,7.2,2.287,6.154-1.7a33.614,33.614,0,0,1-1.03-11.611c1.067.163,2.16.3,3.31.4,1.592.129,3.261.2,4.972.216-.374,4.891.583,9.835,4.436,13.252,3.066,2.721,7.6-1.776,4.512-4.512-2.6-2.308-2.969-5.687-2.563-8.985,12.774-1.105,26.09-6.107,29.3-19.056A20.117,20.117,0,0,0,515.756,115.145Z"
        transform="translate(496.989 388.664)"
        :fill="color"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="4"
      />
      <path
        id="Path_529"
        data-name="Path 529"
        d="M472.409,99.253a3.223,3.223,0,0,0-4.514,0l-.425.426a3.191,3.191,0,0,0,4.514,4.512l.425-.426A3.221,3.221,0,0,0,472.409,99.253Z"
        transform="translate(502.373 395.011)"
        fill="#1c1c1c"
        stroke="#000"
        stroke-width="1"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Char5",
  props: ["color", "size"],
};
</script>
