<template>
  <div
    class="help-btn thinly-shadowed"
    :class="{ tutorial: showTutorial }"
    @click="showHelp"
  >
    <p v-if="helpShown">OK</p>
    <img class="help-img" src="@/assets/img/utils/help.svg" v-else />
  </div>
</template>

<script>
export default {
  name: "HelpBtn",
  props: ["helpShown", "showTutorial"],
  methods: {
    showHelp() {
      if (this.showTutorial) {
        this.$emit("nextTutorial", "music");
      }
      this.$emit("showHelp");
    },
  },
};
</script>

<style scoped lang="scss">
.help-btn {
  position: absolute;
  bottom: 5px;
  left: 50px;
  background: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  p {
    margin: 0;
    font-family: "TrashHand", sans-serif;
    font-size: 1rem;
  }
  .help-img {
    height: 70%;
    width: 70%;
  }
}

@media (min-width: 1024px) {
  .help-btn {
    bottom: 20px;
    left: 65px;
    height: 45px;
    width: 45px;
    p {
      font-size: 1.7rem;
    }
  }
}
</style>
