export default {
  people: [
    "beethoven",
    "bach",
    "einstein",
    "grimm",
    "nietzsche",
    "frank",
    "lagerfeld",
    "merkel",
    "schumacher",
  ],
  biographies: [
    "bach",
    "beethoven",
    "einstein",
    "nietzsche",
    "frank",
    "grimm",
    "lagerfeld",
    "schumacher",
    "merkel",
  ],
  found: {
    beethoven: false,
    bach: false,
    einstein: false,
    grimm: false,
    nietzsche: false,
    frank: false,
    lagerfeld: false,
    merkel: false,
    schumacher: false,
  },
};
