<template>
  <svg viewBox="0 0 113 157" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M70.069 151.406C89.5877 151.406 105.411 148.319 105.411 144.51C105.411 140.702 89.5877 137.615 70.069 137.615C50.5502 137.615 34.7271 140.702 34.7271 144.51C34.7271 148.319 50.5502 151.406 70.069 151.406Z"
      fill="#0A0A0A"
    />
    <path
      d="M33.7559 149.668C31.4499 149.395 29.0015 149.052 27.1695 147.584C25.3374 146.116 24.4732 143.098 26.011 141.301L26.3592 137.708C16.0788 110.753 10.3721 82.2712 9.47452 53.4366L9.16365 49.6893C10.4398 43.2022 16.921 39.3719 22.9711 37.07C27.0144 35.5371 31.1684 34.3139 35.3973 33.411C34.2965 29.8459 33.4264 26.2137 32.7924 22.5368C32.4331 20.4501 32.2331 18.0444 33.6089 16.4741C34.5286 15.5905 35.6807 14.9867 36.9306 14.7331C40.6422 13.6592 44.4515 12.9575 48.3021 12.6386C50.6459 12.4407 52.9996 12.3877 55.3499 12.4801C59.6724 12.6264 63.965 13.257 68.1469 14.3603C69.0552 14.4974 69.8819 14.962 70.4713 15.6666C70.8683 16.4315 71.0087 17.3038 70.8718 18.1547C70.548 23.111 69.6719 28.0157 68.2598 32.7777C73.0445 33.5948 77.7489 34.8273 82.3196 36.4613C88.4999 38.6644 95.2073 42.593 96.1836 49.209L97.2601 53.478C97.94 82.6298 92.1245 111.568 80.2354 138.193L80.1316 143.039C80.4936 143.508 80.677 144.091 80.649 144.683C80.6211 145.276 80.3836 145.839 79.9791 146.272C79.1354 147.096 78.0824 147.673 76.9342 147.941C70.1526 150 63.1077 151.061 56.0206 151.091C48.5754 151.12 41.1368 150.645 33.7559 149.668Z"
      fill="white"
      stroke="black"
      stroke-width="4.00141"
      stroke-miterlimit="10"
    />
    <path
      d="M89.7756 67.9377C90.3118 91.3133 85.4999 114.502 75.7073 135.735C76.2057 140.184 43.8173 145.415 31.3141 135.367C22.8746 113.897 18.1634 90.9872 17.1853 64.9473C18.2405 59.7599 23.5816 56.6882 28.5682 54.8395C32.0463 53.5591 35.6182 52.5495 39.2519 51.8198C47.9872 50.0697 56.9671 49.9156 65.7574 51.3648C69.7388 52.0157 73.6571 53.007 77.4691 54.3278C82.5571 56.0911 88.0817 59.2274 88.8835 64.5298L89.7756 67.9377Z"
      :class="color"
      stroke="black"
      stroke-width="4.00141"
    />
    <path
      d="M62.8905 8.1485C63.1337 8.73267 63.2192 9.37044 63.1386 9.99803C62.9403 13.9756 62.3511 17.9161 61.0012 27.451C49.7191 28.7825 45.6508 28.5998 41.5761 27.7809C40.763 24.4247 40.5583 16.0231 40.1512 13.1648C39.9295 11.612 39.8026 9.82634 40.6293 8.66103C41.1436 8.03148 41.8442 7.58116 42.6304 7.37475C44.8482 6.59038 47.1527 6.07701 49.4937 5.8458C50.9069 5.70464 52.3288 5.67095 53.7471 5.74503C56.3764 5.86709 58.9769 6.34723 61.4764 7.17217C61.765 7.23339 62.0385 7.3512 62.2812 7.51878C62.5239 7.68636 62.731 7.90039 62.8905 8.1485Z"
      fill="#C7BD88"
      stroke="black"
      stroke-width="4.00141"
    />
    <path
      d="M43.7707 84.881C52.5588 78.6214 70.9419 81.5247 68.2603 96.0068C67.7637 98.4819 66.572 100.764 64.8247 102.586C63.0905 104.41 60.9408 105.787 58.5589 106.6C52.9429 108.501 43.2748 108.324 39.6155 103.02C35.4551 96.9913 38.2843 88.7777 43.7707 84.881Z"
      fill="#F2F6D4"
      stroke="black"
      stroke-width="4.00141"
    />
    <path
      d="M17.1861 64.9333C17.1861 64.9333 15.2555 73.379 48.1131 64.3825C80.9708 55.386 89.7481 66.4424 89.7481 66.4424"
      stroke="black"
      stroke-width="4.00141"
    />
    <path
      d="M31.0438 84.8527C34.5001 84.7911 37.2632 82.5618 37.2153 79.8733C37.1674 77.1848 34.3267 75.0553 30.8704 75.1168C27.4141 75.1784 24.651 77.4077 24.6989 80.0962C24.7468 82.7847 27.5875 84.9143 31.0438 84.8527Z"
      :class="darkerColor"
    />
    <path
      d="M67.1908 122.468C70.6471 122.407 73.4102 120.178 73.3623 117.489C73.3144 114.801 70.4737 112.671 67.0174 112.733C63.5611 112.794 60.798 115.023 60.8459 117.712C60.8937 120.4 63.7344 122.53 67.1908 122.468Z"
      :class="darkerColor"
    />
    <path
      d="M56.5613 131.004C58.2897 130.973 59.6715 129.859 59.6475 128.515C59.6236 127.171 58.203 126.106 56.4746 126.137C54.7461 126.168 53.3644 127.282 53.3883 128.626C53.4122 129.97 54.8328 131.035 56.5613 131.004Z"
      :class="darkerColor"
    />
    <path
      d="M33.7656 61.8473C35.494 61.8166 36.8758 60.7021 36.8519 59.3581C36.8279 58.0142 35.4074 56.9496 33.6789 56.9804C31.9505 57.0112 30.5687 58.1256 30.5927 59.4696C30.6166 60.8136 32.0372 61.8781 33.7656 61.8473Z"
      :class="darkerColor"
    />
    <path
      d="M50.6886 20.46C51.9066 20.4383 52.8746 19.3312 52.8507 17.9872C52.8267 16.6433 51.8199 15.5714 50.6019 15.593C49.3839 15.6147 48.4159 16.7218 48.4398 18.0658C48.4638 19.4098 49.4706 20.4817 50.6886 20.46Z"
      fill="#B5AC78"
    />
    <path
      d="M55.3658 23.7705C56.5839 23.7488 57.5519 22.6418 57.5279 21.2978C57.504 19.9538 56.4972 18.8819 55.2792 18.9036C54.0611 18.9253 53.0931 20.0324 53.1171 21.3763C53.141 22.7203 54.1478 23.7922 55.3658 23.7705Z"
      fill="#B5AC78"
    />
    <path
      d="M56.3563 15.6787C57.5743 15.657 58.5423 14.55 58.5184 13.206C58.4945 11.862 57.4877 10.7901 56.2696 10.8118C55.0516 10.8335 54.0836 11.9406 54.1075 13.2845C54.1315 14.6285 55.1383 15.7004 56.3563 15.6787Z"
      fill="#B5AC78"
    />
    <path
      d="M77.9476 81.9291C79.676 81.8983 81.0578 80.7839 81.0339 79.4399C81.0099 78.0959 79.5894 77.0314 77.8609 77.0622C76.1325 77.093 74.7507 78.2074 74.7747 79.5514C74.7986 80.8954 76.2192 81.9599 77.9476 81.9291Z"
      :class="darkerColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Flask",
  props: ["color", "darkerColor"],
};
</script>

<style scoped>
path {
  transition: 0.3s;
}

.full-floral {
  fill: #761e65;
}

.full-floral-darker {
  fill: #5a134c;
}

.floral {
  fill: #685794;
}

.floral-darker {
  fill: #422d79;
}

.middle {
  fill: #4dc1ec;
}

.middle-darker {
  fill: #4d9aec;
}

.citric {
  fill: #ca8353;
}

.citric-darker {
  fill: #c8753b;
}

.full-citric {
  fill: #f36f21;
}

.full-citric-darker {
  fill: #f34421;
}

.white {
  fill: #fff;
}
</style>
