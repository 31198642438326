export default {
  game: [
    [
      {
        item: "adidas",
        name: "Adidas",
        correct: true,
      },
      {
        item: "gucci",
        name: "Gucci",
        correct: false,
      },
      {
        item: "ralph-lauren",
        name: "Ralph Lauren",
        correct: false,
      },
    ],
    [
      {
        item: "ford",
        name: "Ford",
        correct: false,
      },
      {
        item: "ferrari",
        name: "Ferrari",
        correct: false,
      },
      {
        item: "mercedes-benz",
        name: "Mercedes-Benz",
        correct: true,
      },
    ],
    [
      {
        item: "fini",
        name: "Fini",
        correct: false,
      },
      {
        item: "haribo",
        name: "Haribo",
        correct: true,
      },
      {
        item: "mms",
        name: "M&M's",
        correct: false,
      },
    ],
    [
      {
        item: "sprite",
        name: "Sprite",
        correct: false,
      },
      {
        item: "coca-cola",
        name: "Coca-Cola",
        correct: false,
      },
      {
        item: "fanta",
        name: "Fanta",
        correct: true,
      },
    ],
    [
      {
        item: "siemens",
        name: "Siemens",
        correct: true,
      },
      {
        item: "philips",
        name: "Philips",
        correct: false,
      },
      {
        item: "nokia",
        name: "NOKIA",
        correct: false,
      },
    ],
  ],
  correct: ["Adidas", "Mercedes-benz", "Haribo", "Fanta", "Siemens"],
};
