<template>
  <div class="hourglass thinly-shadowed">
    <img class="hourglass-img" src="@/assets/img/utils/hourglass.svg" />
  </div>
</template>

<script>
export default {
  name: "Hourglass",
};
</script>

<style scoped lang="scss">
.hourglass {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  animation: shake 8s;
  animation-iteration-count: infinite;
  .hourglass-img {
    height: 70%;
    width: 70%;
  }
}

@media (min-width: 1024px) {
  .hourglass {
    bottom: 20px;
    right: 15px;
    height: 45px;
    width: 45px;
  }
}
</style>
