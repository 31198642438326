<template>
  <div class="character-creation">
    <transition name="slide" mode="out-in">
      <nickname v-if="step == 1" @submitNickname="setNickname" />
      <char-type v-if="step == 2" @submitType="setType" />
      <char-color v-if="step == 3" :char="char" @submitColor="setColor" />
    </transition>
    <goethe-logo />
  </div>
</template>

<script>
import Nickname from "@/components/charCreation/Nickname.vue";
import CharType from "@/components/charCreation/CharType.vue";
import CharColor from "@/components/charCreation/CharColor.vue";
import GoetheLogo from "@/components/utils/GoetheLogo.vue";
import { apiDBUpdateUser } from "@/utils/goethe-apis.js";
export default {
  name: "CharacterCreation",
  components: { Nickname, CharType, CharColor, GoetheLogo },
  data() {
    return {
      step: 1,
      nickname: null,
      char: null,
      color: null,
      me: null,
    };
  },
  created() {
    this.me = JSON.parse(localStorage.getItem("user"));
    if (this.me.bl_character_created) {
      this.$router.push("/game");
    }
  },
  methods: {
    setNickname(val) {
      this.nickname = val;
      this.step = 2;
    },
    setType(val) {
      this.char = val;
      this.step = 3;
    },
    setColor(val) {
      this.color = val;
      this.submitCharacter();
    },
    submitCharacter() {
      if (this.nickname && this.char && this.color) {
        const token = localStorage.getItem("accessToken");
        const updateData = {
          id: this.me.id,
          str_nickname: this.nickname,
          int_character: this.char,
          str_color: this.color,
        };
        apiDBUpdateUser(updateData, token)
          .then((res) => {
            if (res.status == 200) {
              const data = res.data;
              localStorage.setItem("user", JSON.stringify(data.user));
              this.$router.push("/game");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.character-creation {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: url("../assets/img/logo_white_vertical.svg") center no-repeat,
    #9aca3b;
  background-size: 100px auto;
}

.character-creation-step-screen {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  position: relative;
  h1 {
    position: absolute;
    top: 2rem;
    margin: 0;
    text-align: center;
    color: #fff;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0.3rem;
    font-family: "TrashHand", sans-serif;
  }
}

.char-create-btn {
  position: absolute;
  width: 100px;
  padding: 5px 0;
  left: 50%;
  transform: translate(-50%, 0);
  background: #fff;
  color: #000;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 700;
  transition: 0.3s;
}

.char-create-btn:hover {
  background: #9aca3b;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .character-creation-step-screen {
    h1 {
      font-size: 5rem;
      top: 4rem;
    }
  }
}
</style>
