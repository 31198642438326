import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import GameView from "@/views/GameView.vue";
import Character from "../views/Character.vue";

import Login from "../views/admin/Login.vue";
import CreateSession from "../views/admin/CreateSession.vue";
import Sessions from "../views/admin/Sessions.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/character",
    name: "Character",
    component: Character,
  },
  {
    path: "/game",
    name: "Game",
    component: GameView,
  },
  {
    path: "/adm-login",
    name: "AdmLogin",
    component: Login,
    meta: {
      title: "Admin - Login",
      metaTags: [
        {
          name: "description",
          content: "Autobahn Digital Admin Login Page",
        },
        {
          property: "og:description",
          content: "Autobahn Digital Admin Login Page",
        },
      ],
    },
  },
  {
    path: "/create-session",
    name: "CreateSession",
    component: CreateSession,
    meta: {
      title: "Admin - Create Session",
      metaTags: [
        {
          name: "description",
          content: "Autobahn Digital Admin Create Session Page",
        },
        {
          property: "og:description",
          content: "Autobahn Digital Admin Create Session Page",
        },
      ],
    },
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: Sessions,
    meta: {
      title: "Admin - Sessions",
      metaTags: [
        {
          name: "description",
          content: "Autobahn Digital Admin Sessions Page",
        },
        {
          property: "og:description",
          content: "Autobahn Digital Admin Sessions Page",
        },
      ],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "Autobahn Digital";
  });
});

export default router;
