<template>
  <div class="level munster-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel" v-if="signListPhase">
        <h1>{{ $t("munster.listTitle") }}</h1>
        <table>
          <tbody>
            <tr v-for="sign in signList" :key="sign[0]">
              <td>
                <img
                  class="table-img"
                  :src="require(`@/assets/img/games/munster/${sign[0]}.svg`)"
                />
              </td>
              <td>
                <p class="table-text">
                  {{ germanNames[sign[0]] }} ({{ $t(`munster.${sign[0]}`) }})
                </p>
              </td>
              <td>&nbsp;</td>
              <td>
                <img
                  class="table-img"
                  :src="require(`@/assets/img/games/munster/${sign[1]}.svg`)"
                />
              </td>
              <td>
                <p class="table-text">
                  {{ germanNames[sign[1]] }} ({{ $t(`munster.${sign[1]}`) }})
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="game-panel" v-else>
        <p v-if="starPhase == 'preparing'" class="set">{{ $t("munster.prepare") }}</p>
        <div class="star-holder" :class="starPhase" v-else>
          <shooting-star
            v-for="n in 4"
            :key="n"
            :sign="phaseData[phase].stars[n - 1]"
            @chosen="signChosen"
          />
        </div>
      </div>
      <div class="lower-panel text-shadow-extra-thin-black">
        <div class="game-info-display">
          <p v-if="!signListPhase">
            {{ $t(`munster.${phaseData[phase].sign}`) }} ({{
              germanNames[phaseData[phase].sign]
            }})
          </p>
          <img
            v-if="!signListPhase"
            :src="
              require(`@/assets/img/games/munster/${phaseData[phase].sign}.svg`)
            "
          />
          <button
            class="thinly-shadowed"
            @click="startBirthSignGame"
            v-if="signListPhase && !lockEnd"
          >
            {{ $t("munster.continue") }}
          </button>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="pointsDisplay"
          @timeoutLevel="timeoutLevel"
        />
      </div>

      <waiting-players-notification v-if="lockEnd" />
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="1" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import WaitingPlayersNotification from "@/components/utils/WaitingPlayersNotification.vue";
import munsterEnum from "@/enums/munster.enum.js";
import ShootingStar from "@/components/utils/ShootingStar.vue";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    WaitingPlayersNotification,
    ShootingStar,
    PlayerQtt,
  },
  name: "MunsterLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
    phase(val) {
      clearTimeout(this.phaseTimeout);
      if (val <= 4 && (!this.answers || this.answers.length < 5)) {
        setTimeout(() => {
          this.shootStars();
        }, 3000);
      }
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "munster",
      color: "darkblue",
      step: null,
      waitingStart: false,
      showHelp: false,
      phaseData: munsterEnum.phases,
      signList: munsterEnum.signs,
      germanNames: munsterEnum.germanSigns,
      phase: null,
      starPhase: "preparing",
      phaseTimeout: null,
      answers: [],
      lockEnd: false,
      signListPhase: true,
      gameStartRequested: false,
      notifyStarTimeout: false,
      animationPhase: [
        { time: 9000, class: "nine-seconds" },
        { time: 8000, class: "eight-seconds" },
        { time: 7000, class: "seven-seconds" },
        { time: 6000, class: "six-seconds" },
        { time: 5000, class: "five-seconds" },
      ],
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }
      if (this.signListPhase) {
        if (this.gameStartRequested) {
          this.gameStartRequested = false;
          this.signListPhase = false;
        } else {
          if (this.gameData.players[this.me].answers?.length == 5) {
            this.lockEnd = true;
          }
          return;
        }
      }

      if (this.gameData.players[this.me].answers) {
        if (this.gameData.players[this.me].answers.length == 5) {
          this.lockEnd = true;
        } else {
          this.phase = Math.min(
            this.gameData.players[this.me].answers.length,
            4
          );
        }
      } else {
        this.phase = 0;
      }
      const dataLength = this.gameData.players[this.me].answers
        ? this.gameData.players[this.me].answers.length
        : 0;
      const notify = this.answers.length < dataLength;
      this.answers = this.gameData.players[this.me].answers
        ? this.gameData.players[this.me].answers
        : [];
      if (notify) {
        if (this.answers.at(-1)) {
          this.$emit("notify", this.$t("munster.correctStar"), null, "success");
        } else if (this.notifyStarTimeout) {
          this.$emit("notify", this.$t("munster.noStar"), null, "failure");
          this.notifyStarTimeout = false;
        } else {
          this.$emit(
            "notify",
            this.$t("munster.incorrectStar"),
            null,
            "failure"
          );
        }
      }
    },
    startBirthSignGame() {
      if (!this.canAct || this.lockEnd) {
        return;
      }

      this.gameStartRequested = true;
      this.$emit("forceFetch");
    },
    signChosen(sign) {
      if (!this.canAct || this.lockEnd) {
        return;
      }

      clearTimeout(this.phaseTimeout);
      this.starPhase = this.phase <= 3 ? "preparing" : "end";
      this.$emit("updateGame", { sign: sign, phase: this.phase });
    },
    shootStars() {
      this.starPhase = this.animationPhase[this.phase].class;
      clearTimeout(this.phaseTimeout);
      this.phaseTimeout = setTimeout(() => {
        this.starPhase = this.phase <= 3 ? "preparing" : "end";
        this.notifyStarTimeout = true;
        this.$emit("updateGame", { sign: "timeout", phase: this.phase });
      }, this.animationPhase[this.phase].time);
    },
  },
  beforeDestroy() {
    clearTimeout(this.phaseTimeout);
  },
  computed: {
    pointsDisplay() {
      return this.gameData.players[this.me]?.points
        ? this.gameData.players[this.me]?.points
        : 0;
    },
  },
};
</script>

<style scoped lang="scss">
.munster-level {
  background: #0a4879;
}

.game-panel {
  display: grid;
  place-items: center;
  //game exclusive
  position: relative;
  background-image: url("../../assets/img/games/munster/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: hidden;
  h1 {
    font-size: 1rem;
    color: #fff;
    font-family: "GoetheFFClanBold", sans-serif;
    margin: 0;
  }
  .table-img {
    width: 20px;
  }
  .table-text {
    font-family: "GoetheFFClan", sans-serif;
    font-weight: 700;
    color: #fff;
    margin: 0;
    font-size: 0.7rem;
  }
  .set {
    color: #fff;
    font-family: "GoetheFFClanBold", sans-serif;
    font-weight: 700;
    font-size: 1rem;
  }
  .star-holder {
    position: absolute;
    top: -306px;
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
  }
}

.nine-seconds {
  animation: shooting-star;
  animation-duration: 9s;
}

.eight-seconds {
  animation: shooting-star;
  animation-duration: 8s;
}

.seven-seconds {
  animation: shooting-star;
  animation-duration: 7s;
}

.six-seconds {
  animation: shooting-star;
  animation-duration: 6s;
}

.five-seconds {
  animation: shooting-star;
  animation-duration: 5s;
}

@keyframes shooting-star {
  0% {
    top: -306px;
  }
  100% {
    top: 100%;
  }
}

@keyframes shooting-star-desktop {
  0% {
    top: -450px;
  }
  100% {
    top: 100%;
  }
}

.game-info-display {
  display: grid;
  place-items: center;
  //game exclusive
  p {
    color: #fff;
    font-family: "GoetheFFClanBold", sans-serif;
    font-weight: 700;
    font-size: 0.8rem;
    margin: 0;
  }
  img {
    width: 35px;
  }
  button {
    background: #fff;
    font-family: "GoetheFFClanBold", sans-serif;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
  }
}

@media (min-width: 1024px) {
  .game-panel {
    h1 {
      font-size: 1.5rem;
    }
    .table-img {
      width: 40px;
    }
    .table-text {
      font-size: 1rem;
    }
    .set {
      font-size: 1.5rem;
    }
    .star-holder {
      top: -450px;
    }

    .nine-seconds {
      animation: shooting-star-desktop;
      animation-duration: 9s;
    }

    .eight-seconds {
      animation: shooting-star-desktop;
      animation-duration: 8s;
    }

    .seven-seconds {
      animation: shooting-star-desktop;
      animation-duration: 7s;
    }

    .six-seconds {
      animation: shooting-star-desktop;
      animation-duration: 6s;
    }

    .five-seconds {
      animation: shooting-star-desktop;
      animation-duration: 5s;
    }
  }

  .game-info-display {
    p {
      font-size: 1.2rem;
    }
    img {
      width: 50px;
    }
    button {
      font-size: 1.5rem;
      padding: 1rem;
    }
  }
}
</style>
