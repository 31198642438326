<template>
  <div class="level bonn-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <div class="biography-wrapper" v-if="phase <= 8">
          <div class="img-wrapper">
            <img
              :src="require(`@/assets/img/games/bonn/${people[phase]}.jpg`)"
            />
          </div>
          <div class="text-wrapper">
            <div>
              <h1>{{ $t(`bonn.people.${people[phase]}.name`) }}</h1>
              <p>{{ $t(`bonn.people.${people[phase]}.biography`) }}</p>
            </div>
          </div>
        </div>
        <div class="people-wrapper" v-else>
          <h1>{{ $t("bonn.gameTitle") }}</h1>
          <div class="people">
            <div
              class="person"
              :class="
                found[person]
                  ? person
                  : selectedPerson == person
                  ? 'selected'
                  : 'idle'
              "
              v-for="person in phasePeople"
              :key="person"
              @click="selectPerson(person)"
            >
              <div class="person-img">
                <img :src="require(`@/assets/img/games/bonn/${person}.jpg`)" />
              </div>
              <div class="person-name">
                <p>{{ $t(`bonn.people.${person}.name`) }}</p>
              </div>
            </div>
          </div>
          <div class="biographies">
            <div
              class="biography"
              :class="
                found[biography]
                  ? biography
                  : selectedBiography == biography
                  ? 'selected'
                  : 'idle'
              "
              v-for="biography in phaseBiograpies"
              :key="biography"
              @click="selectBiography(biography)"
            >
              <p>{{ $t(`bonn.people.${biography}.biography`) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="lower-panel">
        <div class="game-info-display">
          <div class="content">
            <div class="tries-wrapper">
              <p>
                {{ $t("bonn.tries") }}
                <span class="text-shadow-extra-thin-white">{{ tries }}</span>
              </p>
            </div>
            <div class="buttons-wrapper">
              <button
                class="ctrl-btn thinly-shadowed"
                :disabled="phase == 0"
                @click="changePhase('prev')"
              >
                {{ $t("bonn.previous") }}
              </button>
              <button
                class="ctrl-btn thinly-shadowed"
                :disabled="phase == 11"
                @click="changePhase('next')"
              >
                {{ $t("bonn.next") }}
              </button>
            </div>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="gameData.points"
          @timeoutLevel="timeoutLevel"
        />
      </div>
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="4" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import bonnEnum from "@/enums/bonn.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    PlayerQtt,
  },
  name: "BonnLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "bonn",
      color: "purple",
      step: null,
      waitingStart: false,
      showHelp: false,
      people: bonnEnum.people,
      biographies: bonnEnum.biographies,
      found: bonnEnum.found,
      phase: 0,
      selectedPerson: null,
      selectedBiography: null,
      tries: 5,
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  mounted() {
    for (let person of this.people) {
      let img = new Image();
      img.src = `@/assets/img/games/bonn/${person}.jpg`;
    }
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }

      let notify = true;
      for (let person of this.people) {
        if (this.gameData[person]) {
          if (this.gameData[person] != this.found[person] && notify) {
            notify = false;
            this.$emit(
              "notify",
              this.$t("bonn.correct"),
              this.$t(`bonn.people.${person}.name`),
              "success"
            );
          }
          this.found[person] = true;

          if (this.selectedPerson == person) {
            this.selectedPerson = null;
          }

          if (this.selectedBiography == person) {
            this.selectedBiography = null;
          }
        } else {
          if (this.tries > this.gameData.tries && notify) {
            this.$emit(
              "notify",
              this.$t("bonn.incorrect"),
              this.$t("bonn.someone"),
              "failure"
            );
          }
        }
      }

      this.tries = this.gameData.tries;
    },
    selectPerson(person) {
      if (!this.canAct || this.found[person] || this.selectedPerson == person) {
        return;
      }
      this.selectedPerson = person;
      if (this.selectedBiography != null) {
        const updateData = {
          person: this.selectedPerson,
          biography: this.selectedBiography,
        };
        this.$emit("updateGame", updateData);
        this.selectedPerson = null;
        this.selectedBiography = null;
      }
    },
    selectBiography(biography) {
      if (
        !this.canAct ||
        this.found[biography] ||
        this.selectedBiography == biography
      ) {
        return;
      }
      this.selectedBiography = biography;
      if (this.selectedPerson != null) {
        const updateData = {
          person: this.selectedPerson,
          biography: this.selectedBiography,
        };
        this.$emit("updateGame", updateData);
        this.selectedPerson = null;
        this.selectedBiography = null;
      }
    },
    changePhase(type) {
      if (!this.canAct) {
        return;
      }
      this.selectedPerson = null;
      this.selectedBiography = null;

      if (type == "prev") {
        this.phase -= 1;
      } else {
        this.phase += 1;
      }
    },
  },
  computed: {
    phasePeople() {
      if (this.phase <= 9) {
        return this.people.slice(0, 3);
      } else if (this.phase == 10) {
        return this.people.slice(3, 6);
      } else {
        return this.people.slice(6, 9);
      }
    },
    phaseBiograpies() {
      if (this.phase <= 9) {
        return this.biographies.slice(0, 3);
      } else if (this.phase == 10) {
        return this.biographies.slice(3, 6);
      } else {
        return this.biographies.slice(6, 9);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bonn-level {
  background: #761e65;
}

.game-panel {
  display: grid;
  place-items: center;
  //game exclusive
  .biography-wrapper {
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: row;
    .img-wrapper {
      width: 30%;
      display: grid;
      place-items: center;
      img {
        width: 90%;
        max-width: 200px;
        border-radius: 10px;
      }
    }
    .text-wrapper {
      width: 70%;
      display: grid;
      place-items: center;
      div {
        width: 100%;
        h1 {
          font-family: "GoetheFFClanBold", sans-serif;
          font-size: 1rem;
        }
        p {
          font-family: "GoetheFFClan", sans-serif;
          font-size: 0.9rem;
        }
      }
    }
  }
  .people-wrapper {
    width: 95%;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    h1 {
      position: absolute;
      top: -20px;
      font-family: "GoetheFFClanBold", sans-serif;
      font-size: 0.9rem;
      width: 100%;
      text-align: center;
    }
    .people {
      width: 48%;
      .person {
        width: 100%;
        height: 55px;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 0.2rem;
        border-radius: 5px;
        .person-img {
          width: 30%;
          display: grid;
          place-items: center;
          img {
            width: 80%;
            max-width: 50px;
            border-radius: 10px;
          }
        }
        .person-name {
          width: 70%;
          display: grid;
          place-items: center;
          p {
            margin: 0;
            width: 100%;
            font-family: "GoetheFFClanBold", sans-serif;
            font-size: 0.65rem;
          }
        }
      }
    }
    .biographies {
      width: 48%;
      .biography {
        width: 100%;
        height: 55px;
        display: grid;
        place-items: center;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 0.2rem;
        border-radius: 5px;
        p {
          margin: 0;
          width: 100%;
          font-family: "GoetheFFClan", sans-serif;
          font-size: 0.55rem;
        }
      }
    }
  }
}

.idle {
  border: 2px solid transparent;
}

.selected {
  border: 2px solid #9aca3b;
  animation: borderFlash 1s linear infinite;
}

.beethoven,
.grimm,
.lagerfeld {
  border: 2px solid #761e65;
}

.bach,
.nietzsche,
.merkel {
  border: 2px solid #4dc1ec;
}

.einstein,
.frank,
.schumacher {
  border: 2px solid #ed6f23;
}

.game-info-display {
  display: grid;
  place-items: center;
  //game exclusive
  .content {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    .tries-wrapper {
      width: 100%;
      height: 50%;
      display: grid;
      place-items: center;
      p {
        font-family: "GoetheFFClan", sans-serif;
        font-weight: 700;
        margin: 0;
        font-size: 0.8rem;
      }
    }
    .buttons-wrapper {
      margin: 0.5rem auto;
      width: 200px;
      display: flex;
      justify-content: space-between;
      .ctrl-btn {
        width: 90px;
        background: #fff;
        color: #000;
        font-family: "GoetheFFClan", sans-serif;
        font-weight: 700;
        font-size: 1rem;
        padding: 0.3rem 0;
        border-radius: 5px;
        cursor: pointer;
      }
      .ctrl-btn:disabled {
        background: #d4ded5;
        color: #000;
      }
    }
  }
}

@media (min-width: 650px) {
  .game-panel {
    .people-wrapper {
      h1 {
        top: -45px;
        font-size: 1rem;
      }
      .biographies {
        .biography {
          p {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .game-panel {
    .biography-wrapper {
      .text-wrapper {
        div {
          h1 {
            font-size: 2.5rem;
          }
          p {
            font-size: 1.5rem;
          }
        }
      }
    }
    .people-wrapper {
      h1 {
        top: -60px;
        font-size: 1.6rem;
      }
      .people {
        .person {
          width: 100%;
          height: 90px;
          .person-img {
            img {
              width: 80%;
              max-width: 70px;
            }
          }
          .person-name {
            p {
              font-size: 1.2rem;
            }
          }
        }
      }
      .biographies {
        .biography {
          height: 90px;
          p {
            font-size: 1.1rem;
          }
        }
      }
    }
  }

  .game-info-display {
    .content {
      .tries-wrapper {
        p {
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
