<template>
  <div class="level berlin-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <h1>{{ $t("berlin.gameTitle") }}</h1>
        <div class="content thinly-shadowed">
          <div class="img-wrapper">
            <img
              :src="
                require(`@/assets/img/games/berlin/${stepData[phase].pic}.jpg`)
              "
            />
          </div>

          <div class="text-wrapper">
            <h2>
              {{ $t(`berlin.${stepData[phase].restaurant}`) }} ({{
                stepData[phase].score
              }}
              <span class="star">★</span>)
            </h2>
            <p>{{ $t(`berlin.${stepData[phase].hours}`) }}</p>
            <div class="comment">
              <p>{{ $t(`berlin.${stepData[phase].comment}`) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="lower-panel">
        <div class="game-info-display">
          <div class="images-wrapper">
            <div
              v-for="n in 3"
              :key="n"
              @click="pickFood(stepData[phase].foodList[n - 1].item)"
            >
              <img
                :src="
                  require(`@/assets/img/games/berlin/${
                    stepData[phase].foodList[n - 1].item
                  }.jpg`)
                "
              />
              <p>{{ stepData[phase].foodList[n - 1].title }}</p>
            </div>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="gameData.points"
          @timeoutLevel="timeoutLevel"
        />
      </div>
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="4" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import berlinEnum from "@/enums/berlin.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    PlayerQtt,
  },
  name: "BerlinLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "berlin",
      color: "mediumgray",
      step: null,
      waitingStart: false,
      showHelp: false,
      stepData: berlinEnum.data,
      correctFoods: berlinEnum.correctFoods,
      phase: 0,
      answers: [],
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }

      if (this.gameData.answers.at(-1) != undefined) {
        if (this.answers.length < this.gameData.answers.length) {
          const title = this.gameData.answers.at(-1)
            ? this.$t("berlin.correct")
            : this.$t("berlin.incorrect");
          const subtitle = `${this.$t("berlin.foodIs")} ${
            this.correctFoods[this.gameData.phase - 1]
          }`;
          const context = this.gameData.answers.at(-1) ? "success" : "failure";
          this.$emit("notify", title, subtitle, context);
        }
        this.answers = this.gameData.answers;
      }
      this.phase = Math.min(this.gameData.phase, 5);
    },
    pickFood(food) {
      if (!this.canAct) {
        return;
      }

      this.$emit("updateGame", {
        food: food,
        phase: this.phase,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.berlin-level {
  background: #b5b5b5;
}

.game-panel {
  display: grid;
  place-items: center;
  position: relative;
  //game exclusive
  h1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.9rem;
    font-family: "TrashHand", sans-serif;
  }
  .content {
    width: 95%;
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-radius: 5px;
    box-sizing: border-box;
    .img-wrapper {
      width: 40%;
      display: grid;
      place-items: center;
      img {
        width: 90%;
        max-width: 190px;
        border-radius: 5px;
      }
    }
    .text-wrapper {
      width: 60%;
      h2 {
        font-size: 1rem;
        font-family: "GoetheFFClan", sans-serif;
        margin: 0;
        .star {
          color: #ffda03;
        }
      }
      p {
        font-size: 0.9rem;
        font-family: "GoetheFFClan", sans-serif;
        margin: 0.5rem 0;
      }
      .comment {
        width: 95%;
        background: #d4ded5;
        box-sizing: border-box;
        padding: 2px 5px;
        border-radius: 5px;
        p {
          font-size: 0.65rem;
          font-family: "GoetheFFClan", sans-serif;
          margin: 0;
        }
      }
    }
  }
}

.game-info-display {
  display: grid;
  place-items: center;
  //game exclusive
  .images-wrapper {
    width: 95%;
    max-width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    div {
      width: 30%;
      cursor: pointer;
      text-align: center;
      img {
        width: 7vw;
        border-radius: 5px;
      }
      p {
        font-size: 0.7rem;
        font-family: "GoetheFFClanBold", sans-serif;
        margin: 0;
      }
    }
  }
}

@media (min-width: 1024px) {
  .game-panel {
    h1 {
      font-size: 1.5rem;
    }
    .content {
      padding: 10px;
      .img-wrapper {
        width: 30%;
      }
      .text-wrapper {
        width: 70%;
        h2 {
          font-size: 1.3rem;
        }
        p {
          font-size: 1.1rem;
          margin: 1rem 0;
        }
        .comment {
          padding: 5px 8px;
          p {
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  .game-info-display {
    .images-wrapper {
      div {
        img {
          width: 9vw;
          max-width: 110px;
        }
        p {
          font-size: 0.8rem;
          margin: 0.5rem 0 0 0;
        }
      }
    }
  }
}
</style>
