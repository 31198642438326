export default {
  text: ["p1", "p2", "p3", "p4", "p5", "p6", "p7"],
  data: [
    { correctAnswer: 1, userAnswer: 0, question: "q1" },
    { correctAnswer: 2, userAnswer: 0, question: "q2" },
    { correctAnswer: 2, userAnswer: 0, question: "q3" },
    { correctAnswer: 2, userAnswer: 0, question: "q4" },
    { correctAnswer: 1, userAnswer: 0, question: "q5" },
    { correctAnswer: 2, userAnswer: 0, question: "q6" },
  ],
};
