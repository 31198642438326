export default {
  intro: [
    {
      content: "intro1",
      composer: "Ludwig van Beethoven",
      song: "Für Elise",
      img: "beethoven-green.jpg",
      file: "eisenach/fur-elise.mp3",
    },
    {
      content: "intro2",
      composer: "Dschinghis Khan",
      song: "Moskau",
      img: "dschinghis-khan-orange.jpg",
      file: "eisenach/moskau.mp3",
    },
    {
      content: "intro3",
      composer: "Wolfgang Amadeus Mozart",
      song: "Türkischer Marsch",
      img: "mozart-orange.jpg",
      file: "eisenach/turkischer-marsch.mp3",
    },
    {
      content: "intro4",
      composer: "Dschinghis Khan",
      song: "Dschinghis Khan",
      img: "dschinghis-khan-green.jpg",
      file: "eisenach/dschinghis-khan.mp3",
    },
  ],
  game: [
    {
      composerLeft: "Johann Sebastian Bach",
      composerRight: "Wolfgang Amadeus Mozart",
      imgLeft: "bach-green.jpg",
      imgRight: "mozart-orange.jpg",
      file: "eisenach/turkischer-marsch.mp3",
      left: false,
      right: true,
    },
    {
      composerLeft: "Dschinghis Khan",
      composerRight: "Ludwig van Beethoven",
      imgLeft: "dschinghis-khan-orange.jpg",
      imgRight: "beethoven-green.jpg",
      file: "eisenach/dschinghis-khan.mp3",
      left: true,
      right: false,
    },
    {
      composerLeft: "Wolfgang Amadeus Mozart",
      composerRight: "Ludwig van Beethoven",
      imgLeft: "mozart-green.jpg",
      imgRight: "beethoven-orange.jpg",
      file: "eisenach/fur-elise.mp3",
      left: false,
      right: true,
    },
    {
      composerLeft: "Falco",
      composerRight: "Dschinghis Khan",
      imgLeft: "falco-orange.jpg",
      imgRight: "dschinghis-khan-green.jpg",
      file: "eisenach/moskau.mp3",
      left: false,
      right: true,
    },
  ],
};
