<template>
  <div class="how-to">
    <h1>{{ $t("koln.howToPlay") }}</h1>
    <p class="dark-text">{{ $t("koln.howP1") }}</p>
    <p class="dark-text">
      {{ $t("koln.howP2") }}
      <span class="dark-highlight">
        {{ $t("koln.howP2Highlight") }}
      </span>
    </p>
    <p class="dark-text">
      {{ $t("koln.howP3") }}
      <span class="dark-highlight">
        {{ $t("koln.howP3Highlight") }}
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: "KolnHowTo",
};
</script>

<style scoped lang="scss">
.how-to {
  color: #fff;
}
</style>
