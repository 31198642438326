<template>
  <div class="level mainz-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <div
          class="invention-wrapper"
          :class="invention.item"
          v-for="invention in mainzData"
          :key="invention.item"
        >
          <img
            class="not-found-img"
            v-if="!invention.found"
            :src="
              require(`@/assets/img/games/mainz/${invention.item}_blur.jpg`)
            "
            @click="pickInvention(invention.item)"
          />
          <img
            class="found-img"
            v-else
            :src="require(`@/assets/img/games/mainz/${invention.item}.jpg`)"
          />
        </div>
      </div>
      <div class="lower-panel">
        <div class="game-info-display">
          <div class="content">
            <div class="tries-wrapper">
              <p>
                {{ $t(`mainz.remainingTries`) }}
                <span class="text-shadow-extra-thin-white">{{
                  remainingTries
                }}</span>
              </p>
            </div>
            <div class="inventions-wrapper">
              <div class="inventions-content">
                <h1>
                  <span class="text-shadow-extra-thin-white german">{{
                    mainzData[order[currentStep]].german
                  }}</span
                  >&nbsp;
                  <span class="translation"
                    >({{
                      $t(`mainz.${mainzData[order[currentStep]].item}`)
                    }})</span
                  >
                </h1>
              </div>
            </div>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="gameData.points"
          @timeoutLevel="timeoutLevel"
        />
      </div>
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="4" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import mainzEnum from "@/enums/mainz.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    PlayerQtt,
  },
  name: "MainzLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "mainz",
      color: "orange",
      step: "intro",
      waitingStart: false,
      showHelp: false,
      order: mainzEnum.order,
      mainzData: mainzEnum.items,
      currentStep: 0,
      remainingTries: 3,
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }

      for (let item of mainzEnum.order) {
        this.mainzData[item].found = this.gameData[item];
      }

      if (
        this.currentStep < Math.min(this.gameData.phase, 9) &&
        !this.isFirstLoad
      ) {
        let itemFound = this.$t(`mainz.${this.order[this.currentStep]}`);
        this.$emit(
          "notify",
          `${this.$t("mainz.found")}: ${itemFound}`,
          null,
          "success"
        );
      }

      if (
        this.remainingTries > Math.max(this.gameData.tries, 0) &&
        !this.isFirstLoad
      ) {
        this.$emit("notify", `${this.$t("mainz.wrong")}`, null, "failure");
      }

      this.currentStep = Math.min(this.gameData.phase, 9);
      this.remainingTries = Math.max(this.gameData.tries, 0);
      this.isFirstLoad = false;
    },
    pickInvention(invention) {
      if (!this.canAct) {
        return;
      }

      let updateData = {};
      updateData["chosen"] = invention;
      updateData["phase"] = this.currentStep;
      this.$emit("updateGame", updateData);
    },
  },
};
</script>

<style scoped lang="scss">
.mainz-level {
  background: #ed6f23;
}

.game-panel {
  position: relative;
  //game exclusive
  .invention-wrapper {
    height: 45px;
    width: 45px;
    position: absolute;
    img {
      height: 100%;
      width: 100%;
    }
    .not-found-img {
      animation: shake 5s;
      animation-iteration-count: infinite;
      cursor: pointer;
    }
  }
}

.car {
  top: 5%;
  right: 7%;
}

.gummy_bear {
  top: 10%;
  right: 43%;
}

.christmas_tree {
  bottom: 10%;
  left: 5%;
}

.bike {
  bottom: 20%;
  left: 50%;
}

.tape {
  top: 30%;
  left: 30%;
}

.newspaper {
  top: 15%;
  left: 10%;
}

.press {
  bottom: 10%;
  right: 15%;
}

.toothpaste {
  bottom: 8%;
  left: 30%;
}

.helicopter {
  top: 40%;
  right: 7%;
}

.thermos {
  top: 33%;
  right: 25%;
}

.game-info-display {
  display: grid;
  place-items: center;
  //game exclusive
  .content {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    .tries-wrapper {
      width: 100%;
      height: 50%;
      display: grid;
      place-items: center;
      p {
        font-family: "GoetheFFClan", sans-serif;
        font-weight: 700;
        margin: 0;
        font-size: 0.8rem;
      }
    }
    .inventions-wrapper {
      width: 100%;
      height: 50%;
      .inventions-content {
        h1 {
          font-family: "GoetheFFClan", sans-serif;
          margin: 0;
          text-align: center;
          font-size: 1.1rem;
          .german {
            font-size: 0.9rem;
          }
          .translation {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .game-panel {
    .invention-wrapper {
      height: 75px;
      width: 75px;
    }
  }

  .game-info-display {
    .content {
      .tries-wrapper {
        p {
          font-size: 1.4rem;
        }
      }
      .inventions-wrapper {
        .inventions-content {
          h1 {
            font-size: 1.4rem;
            .german {
              font-size: 1.4rem;
            }
            .translation {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
</style>
