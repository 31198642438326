<template>
  <div class="level weimar-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <div class="wrapper">
          <h1 v-if="currentStep <= 6">
            {{ $t("weimar.game.titleBiography") }}
          </h1>
          <h1 v-if="currentStep >= 7 && currentStep <= 10">
            {{ $t("weimar.game.titleBlanks") }}
          </h1>
          <h1 v-if="currentStep >= 11 && currentStep <= 12">
            {{ $t("weimar.game.titleTrueOrFalse") }}
          </h1>

          <div v-if="currentStep <= 6" class="text-wrapper">
            <div class="text-holder">
              <p>
                {{ $t(`weimar.game.${gameText[currentStep]}`) }}
              </p>
            </div>
            <div class="img-wrapper">
              <img
                :src="
                  require(`@/assets/img/games/weimar/${gameText[currentStep]}.jpg`)
                "
              />
            </div>
          </div>

          <div
            v-if="currentStep >= 7 && currentStep <= 10"
            class="blanks-wrapper"
          >
            <div>
              <p>
                {{
                  $t(
                    `weimar.game.${
                      weimarData[currentStep - textSlides].question
                    }.text`
                  )
                }}
                <span class="blanks-answer">{{
                  weimarData[currentStep - textSlides].userAnswer > 0
                    ? $t(
                        `weimar.game.${
                          weimarData[currentStep - textSlides].question
                        }.correct`
                      )
                    : "__________"
                }}</span>
              </p>

              <button
                class="thinly-shadowed"
                :class="
                  weimarData[currentStep - textSlides].userAnswer > 0
                    ? weimarData[currentStep - textSlides].correctAnswer == 1
                      ? 'correct'
                      : 'incorrect'
                    : ''
                "
                @click="pickOption(currentStep - textSlides, 1)"
                :disabled="weimarData[currentStep - textSlides].userAnswer > 0"
              >
                {{
                  $t(
                    `weimar.game.${
                      weimarData[currentStep - textSlides].question
                    }.op1`
                  )
                }}
              </button>
              <button
                class="thinly-shadowed"
                :class="
                  weimarData[currentStep - textSlides].userAnswer > 0
                    ? weimarData[currentStep - textSlides].correctAnswer == 2
                      ? 'correct'
                      : 'incorrect'
                    : ''
                "
                @click="pickOption(currentStep - textSlides, 2)"
                :disabled="weimarData[currentStep - textSlides].userAnswer > 0"
              >
                {{
                  $t(
                    `weimar.game.${
                      weimarData[currentStep - textSlides].question
                    }.op2`
                  )
                }}
              </button>
            </div>
          </div>

          <div
            v-if="currentStep >= 11 && currentStep <= 12"
            class="true-false-wrapper"
          >
            <p>
              {{
                $t(
                  `weimar.game.${
                    weimarData[currentStep - textSlides].question
                  }.text`
                )
              }}
            </p>
            <button
              class="thinly-shadowed"
              :class="
                weimarData[currentStep - textSlides].userAnswer > 0
                  ? weimarData[currentStep - textSlides].correctAnswer == 1
                    ? 'correct'
                    : 'incorrect'
                  : ''
              "
              @click="pickOption(currentStep - textSlides, 1)"
              :disabled="weimarData[currentStep - textSlides].userAnswer > 0"
            >
              {{ $t("weimar.false") }}
            </button>
            <button
              class="thinly-shadowed"
              :class="
                weimarData[currentStep - textSlides].userAnswer > 0
                  ? weimarData[currentStep - textSlides].correctAnswer == 2
                    ? 'correct'
                    : 'incorrect'
                  : ''
              "
              @click="pickOption(currentStep - textSlides, 2)"
              :disabled="weimarData[currentStep - textSlides].userAnswer > 0"
            >
              {{ $t("weimar.true") }}
            </button>
            <p
              class="true-false-answer"
              v-if="weimarData[currentStep - textSlides].userAnswer > 0"
            >
              {{
                $t(
                  `weimar.game.${
                    weimarData[currentStep - textSlides].question
                  }.correct`
                )
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="lower-panel text-shadow-extra-thin-black">
        <div class="game-info-display">
          <div>
            <button
              class="thinly-shadowed"
              v-if="currentStep > 0"
              @click="currentStep -= 1"
            >
              {{ $t("weimar.prev") }}
            </button>
            <button
              class="thinly-shadowed"
              v-if="currentStep < 12"
              @click="currentStep += 1"
            >
              {{ $t("weimar.next") }}
            </button>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="pointsDisplay"
          @timeoutLevel="timeoutLevel"
        />
      </div>

      <waiting-players-notification v-if="lockEnd" />
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="1" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import weimarEnum from "@/enums/weimar.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
import WaitingPlayersNotification from "@/components/utils/WaitingPlayersNotification.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    PlayerQtt,
    WaitingPlayersNotification,
  },
  name: "WeimarLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "weimar",
      color: "darkgray",
      step: null,
      waitingStart: false,
      showHelp: false,
      gameText: weimarEnum.text,
      textSlides: weimarEnum.text.length,
      weimarData: weimarEnum.data,
      questionsQtt: weimarEnum.data.length,
      currentStep: 0,
      lockEnd: false,
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }

      if (this.gameData.players[this.me].answers) {
        let shouldLock = true;
        for (let i = 0; i < this.questionsQtt; i++) {
          this.weimarData[i].userAnswer =
            this.gameData.players[this.me].answers[i];
          if (this.weimarData[i].userAnswer == 0) {
            shouldLock = false;
          }
        }
        this.lockEnd = shouldLock;
      }
    },
    pickOption(question, option) {
      if (!this.canAct || this.lockEnd) {
        return;
      }

      const questionItem = this.weimarData[question].question;
      const result =
        this.weimarData[question].correctAnswer == option
          ? this.$t("weimar.correct")
          : this.$t("weimar.incorrect");
      const context =
        this.weimarData[question].correctAnswer == option
          ? "success"
          : "failure";
      const title = `${this.$t("weimar.exercise")} ${question + 1} - ${result}`;
      this.$emit(
        "notify",
        title,
        this.$t(`weimar.game.${questionItem}.correct`),
        context
      );

      const updateData = {
        option: option,
        question: question,
      };
      this.$emit("updateGame", updateData);
    },
  },
  computed: {
    pointsDisplay() {
      return this.gameData.players[this.me]?.points
        ? this.gameData.players[this.me]?.points
        : 0;
    },
  },
};
</script>

<style scoped lang="scss">
.weimar-level {
  background: #6a7a83;
}

.game-panel {
  display: grid;
  place-items: center;
  .wrapper {
    width: 95%;
    height: 95%;
    position: relative;
    display: grid;
    place-items: center;
    h1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 0.9rem;
      font-family: "TrashHand", sans-serif;
    }
    .text-wrapper {
      margin-top: 2.5rem;
      display: flex;
      flex-direction: row;
      width: 100%;
      .text-holder {
        width: 50%;
        display: grid;
        place-items: center;
        p {
          padding: 0 0.5rem;
          font-size: 0.65rem;
          box-sizing: border-box;
          text-align: justify;
          margin: 0;
          font-family: "GoetheFFClan", sans-serif;
        }
      }
      .img-wrapper {
        height: 150px;
        width: 50%;
        display: grid;
        place-items: center;
        img {
          border-radius: 10px;
          height: 150px;
        }
      }
    }
    .blanks-wrapper,
    .true-false-wrapper {
      p {
        font-size: 0.8rem;
        margin: 0.5rem 0;
        font-family: "GoetheFFClan", sans-serif;
        span {
          font-family: "GoetheFFClanBold", sans-serif;
        }
      }
      .true-false-answer {
        font-family: "GoetheFFClanBold", sans-serif;
      }
      button {
        font-family: "GoetheFFClanBold", sans-serif;
        margin-left: 0.5rem;
        padding: 0.2rem 0.7rem;
        background: #fff;
        border-radius: 5px;
        cursor: pointer;
      }
      button:disabled {
        cursor: default;
      }
      .correct {
        background: rgba(154, 202, 59, 0.5);
      }
      .incorrect {
        background: rgba(229, 0, 25, 0.5);
      }
    }
  }
}

.game-info-display {
  display: grid;
  place-items: center;
  button {
    margin: 0 0.5rem;
    font-family: "GoetheFFClanBold", sans-serif;
    padding: 0.2rem 0.7rem;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
  }
}

@media (min-width: 1024px) {
  .game-panel {
    .wrapper {
      h1 {
        font-size: 1.5rem;
      }
      .text-wrapper {
        margin-top: 4rem;
        .text-holder {
          p {
            font-size: 1.1rem;
          }
        }
        .img-wrapper {
          height: 350px;
          img {
            height: 350px;
          }
        }
      }
      .blanks-wrapper,
      .true-false-wrapper {
        margin-top: 4rem;
        p {
          font-size: 1.1rem;
          margin: 3rem 0 1rem 0;
        }
        button {
          margin-left: 1rem;
          padding: 0.5rem 1rem;
          font-size: 1.2rem;
        }
      }
    }
  }

  .game-info-display {
    button {
      margin: 0 1rem;
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
    }
  }
}
</style>
