<template>
  <div class="stats text-shadow-thin-black">
    <div class="clock">
      <p class="stats-val">{{ formattedSecondsLeft }}</p>
    </div>
    <div class="points">
      <p class="stats-val">{{ points }} {{ $t("utils.pts") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameClock",
  props: ["seconds", "points"],
  data() {
    return {
      levelSeconds: this.seconds,
    };
  },
  watch: {
    seconds(val) {
      this.levelSeconds = val;
    },
  },
  mounted() {
    this.tryCountdown();
  },
  methods: {
    tryCountdown() {
      this.countDownGame();
      setTimeout(() => {
        this.tryCountdown();
      }, 1000);
    },
    countDownGame() {
      if (this.levelSeconds) {
        this.levelSeconds -= 1;
        if (this.levelSeconds == 0) {
          this.$emit("timeoutLevel");
        }
      }
    },
  },
  computed: {
    formattedSecondsLeft() {
      var sec_num = parseInt(this.levelSeconds, 10);
      var minutes = ("0" + (Math.floor(sec_num / 60) % 60)).slice(-2);
      var seconds = ("0" + (sec_num % 60)).slice(-2);
      return `${minutes} : ${seconds}`;
    },
  },
};
</script>

<style scoped lang="scss">
.stats {
  height: 100%;
  width: 15%;
  border-left: 3px solid #000;
  color: #fff;
  font-family: "TrashHand", sans-serif;
  display: flex;
  flex-direction: column;
  .clock,
  .points {
    width: 100%;
    height: 50%;
    background: #f36f21;
    display: grid;
    place-items: center;
  }
  .clock,
  .points {
    background: #f36f21;
  }
  .points {
    background: #0a4879;
  }
  .stats-val {
    margin: 0;
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .stats {
    .stats-val {
      margin: 0;
      font-size: 1.8rem;
    }
  }
}
</style>
