<template>
  <div class="game-view">
    <transition name="slide" mode="out-in">
      <component
        :is="levelNames[level]"
        :lobbySeconds="lobbySeconds"
        v-if="level == 'lobby'"
      />
      <component
        :is="levelNames[level]"
        :visitedStations="visitedStations"
        :canAct="canAct"
        v-else-if="level == 'map'"
        @frankfurtCodeCorrect="frankfurtCodeCorrect"
      />
      <component
        :is="levelNames[level]"
        :me="me"
        :time="haltSeconds"
        @exitGame="exitGame"
        v-else-if="level == 'end'"
      />
      <!--games-->
      <component
        :is="levelNames[level]"
        :gameData="gameData[level]"
        :canAct="canAct"
        :updateId="updateId"
        :introSeconds="introSeconds"
        :seconds="levelSeconds"
        :me="me.str_nickname"
        @fetchLevelTimes="fetchLevelTimes"
        @forceFetch="forceFetch"
        @updateGame="updateGame"
        @notify="notifyAction"
        @timeoutIntro="timeoutIntro"
        @timeoutLevel="timeoutLevel"
        v-else
      />
    </transition>

    <group-display :players="players" :myId="`${me.str_nickname}${me.id}`" />
    <utils-display
      :end="endTime"
      :newMessages="newMessages"
      :tutorialStep="tutorialStep"
      @nextTutorial="handleTutorial"
      @msgs="showMessagesModal"
      @passport="showPassport = !showPassport"
      @exitGame="exitGame"
    />

    <city-name
      v-if="!['loading', 'lobby', 'map', 'end'].includes(level)"
      :name="level"
    />

    <transition name="bounce">
      <end-game-notification
        v-if="showEndNotification"
        :level="endedLevel"
        :gameData="endGameData"
        @close="showEndNotification = false"
      />
    </transition>

    <transition name="slide-down" mode="out-in">
      <notification
        v-if="showNotification"
        :title="notificationTitle"
        :subtitle="notificationSubtitle"
        :context="notificationContext"
        @close="showNotification = false"
      />
    </transition>

    <transition name="bounce">
      <messages
        :myId="userId"
        :messages="messages"
        :canAct="canAct"
        :showTutorial="tutorialStep === 'closeChat'"
        @nextTutorial="handleTutorial"
        v-if="showMessages || tutorialStep === 'closeChat'"
        @close="showMessages = false"
        @sendMessage="sendMessage"
      />
    </transition>
    <transition name="bounce">
      <passport
        :me="me"
        :group="group"
        :visitedStations="visitedStations"
        :points="totalPoints"
        :km="totalKm"
        :showTutorial="tutorialStep === 'closePassport'"
        @nextTutorial="handleTutorial"
        v-if="showPassport || tutorialStep === 'closePassport'"
        @close="showPassport = false"
      />
    </transition>

    <logout-btn @logout="showLogout = true" />

    <transition name="bounce">
      <logout
        v-show="showLogout"
        @close="showLogout = false"
        @logout="exitGame"
      />
    </transition>

    <transition name="bounce">
      <div class="voice-error-bg" v-if="showVoiceError">
        <div class="voice-error shadowed">
          <p class="title">{{ $t("utils.voiceError") }}</p>
          <p class="subtitle">{{ $t("utils.voiceErrorSubtitle") }}</p>
          <button class="thinly-shadowed" @click="showVoiceError = false">
            OK
          </button>
        </div>
      </div>
    </transition>

    <hourglass v-if="!canAct" />
    <goethe-logo />
    <voice-call
      :room="me.str_call_token"
      :userMuted="userMuted"
      @voice-error="voiceError"
    />
    <music-btn
      :showTutorial="tutorialStep === 'music'"
      @nextTutorial="handleTutorial"
    />
    <mute-btn
      :userMuted="userMuted"
      @controlVoice="userMuted = !userMuted"
      :showTutorial="tutorialStep === 'voice'"
      @nextTutorial="handleTutorial"
    />
    <transition name="bounce">
      <div
        class="tutorial-background"
        v-if="tutorialStep && tutorialStep != 'done'"
      >
        <tutorial-messages :step="tutorialStep" />
      </div>
    </transition>
    <vertical-locker />
  </div>
</template>
<script>
import {
  apiStateFetch,
  apiDBCheckToken,
  apiDBUpdateGroup,
} from "@/utils/goethe-apis.js";
import EventBus from "@/utils/event-bus";
import GroupDisplay from "@/components/displays/GroupDisplay.vue";
import UtilsDisplay from "@/components/displays/UtilsDisplay.vue";
import Messages from "@/components/displays/Messages.vue";
import Passport from "@/components/displays/Passport.vue";
import Logout from "@/components/displays/Logout.vue";
import Notification from "@/components/utils/Notification.vue";
import EndGameNotification from "@/components/utils/EndGameNotification.vue";
import Hourglass from "@/components/utils/Hourglass.vue";
import GoetheLogo from "@/components/utils/GoetheLogo.vue";
import VoiceCall from "@/components/utils/VoiceCall.vue";
import MuteBtn from "@/components/utils/MuteBtn.vue";
import MusicBtn from "@/components/utils/MusicBtn.vue";
import LogoutBtn from "@/components/utils/LogoutBtn.vue";
import VerticalLocker from "@/components/utils/VerticalLocker.vue";
import CityName from "@/components/utils/CityName.vue";
import TutorialMessages from "@/components/utils/TutorialMessages.vue";

import LoadingLevel from "@/components/levels/LoadingLevel.vue";
import EndLevel from "@/components/levels/EndLevel.vue";
import LobbyLevel from "@/components/levels/LobbyLevel.vue";
import MapLevel from "@/components/levels/MapLevel.vue";
import RostockLevel from "@/components/levels/RostockLevel.vue";
import HamburgLevel from "@/components/levels/HamburgLevel.vue";
import DresdenLevel from "@/components/levels/DresdenLevel.vue";
import MainzLevel from "@/components/levels/MainzLevel.vue";
import EisenachLevel from "@/components/levels/EisenachLevel.vue";
import KolnLevel from "@/components/levels/KolnLevel.vue";
import MunchenLevel from "@/components/levels/MunchenLevel.vue";
import WeimarLevel from "@/components/levels/WeimarLevel.vue";
import BerlinLevel from "@/components/levels/BerlinLevel.vue";
import FrankfurtLevel from "@/components/levels/FrankfurtLevel.vue";
import MunsterLevel from "@/components/levels/MunsterLevel.vue";
import BremenLevel from "@/components/levels/BremenLevel.vue";
import BonnLevel from "@/components/levels/BonnLevel.vue";
import NurnbergLevel from "@/components/levels/NurnbergLevel.vue";
import StuttgartLevel from "@/components/levels/StuttgartLevel.vue";

import levelNames from "@/enums/level-names.enum.js";

import config from "../config.json";

export default {
  name: "GameView",
  components: {
    GroupDisplay,
    UtilsDisplay,
    Messages,
    Passport,
    Logout,
    Notification,
    EndGameNotification,
    Hourglass,
    GoetheLogo,
    VoiceCall,
    MuteBtn,
    MusicBtn,
    LogoutBtn,
    VerticalLocker,
    CityName,
    TutorialMessages,
    LoadingLevel,
    EndLevel,
    LobbyLevel,
    MapLevel,
    RostockLevel,
    HamburgLevel,
    DresdenLevel,
    MainzLevel,
    EisenachLevel,
    KolnLevel,
    MunchenLevel,
    WeimarLevel,
    BerlinLevel,
    FrankfurtLevel,
    MunsterLevel,
    BremenLevel,
    BonnLevel,
    NurnbergLevel,
    StuttgartLevel,
  },
  data() {
    return {
      showNotification: false,
      showEndNotification: false,
      notificationTitle: null,
      notificationSubtitle: null,
      notificationContext: null,
      updateId: 0,
      notificationId: 0,
      canAct: false,
      level: "loading",
      levelBeforeFetch: null,
      gameState: {},
      me: {},
      userId: null,
      group: {},
      groupName: null,
      players: {},
      messages: [],
      showMessages: false,
      showPassport: false,
      showLogout: false,
      showVoiceError: false,
      endTime: null,
      levelNames: levelNames,
      lobbySeconds: 0,
      introSeconds: null,
      levelSeconds: null,
      haltSeconds: null,
      visitedStations: [],
      totalKm: 0,
      totalPoints: 0,
      gameData: {},
      endGameData: {},
      endedLevel: null,
      newMessages: 0,
      oldMessagesQtt: 0,
      userMuted: true,
      notificationSoundObj: null,
      tutorialStep: null,
      clientSessionId: null,
    };
  },
  watch: {
    notificationId() {
      this.showNotification = true;
      if (this.notificationContext == "success") {
        if (this.notificationSoundObj) {
          this.notificationSoundObj.pause();
        }
        this.notificationSoundObj = new Audio(
          require(`../assets/audio/correct.mp3`)
        );
        this.notificationSoundObj.play();
      } else if (this.notificationContext == "failure") {
        if (this.notificationSoundObj) {
          this.notificationSoundObj.pause();
        }
        this.notificationSoundObj = new Audio(
          require(`../assets/audio/incorrect.mp3`)
        );
        this.notificationSoundObj.play();
      }
    },
  },
  created() {
    this.updateId = this.randomIntFromInterval();
    this.me = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("accessToken");

    if (!this.me || !token) {
      this.exitGame();
    }

    apiDBCheckToken({ id: this.me.id }, token)
      .then((data) => {
        if (data.data.message === "character_not_created") {
          this.$router.push("/character");
        } else {
          this.setupGame();
        }
      })
      .catch(() => {
        this.exitGame();
      });
    this.group = JSON.parse(localStorage.getItem("group"));
    this.clientSessionId = localStorage.getItem("clientSessionId");
  },
  mounted() {
    this.tutorialStep = localStorage.getItem("tutorialStep");
    const that = this;
    EventBus.$on("goToStation", function (data) {
      that.fetchState("leaveMap", null, data);
    });
  },
  destroyed() {
    EventBus.$off("goToStation");
  },
  computed: {
    userCode() {
      return `${this.me.str_nickname}${this.me.id}`;
    },
  },
  methods: {
    handleTutorial(step) {
      localStorage.setItem("tutorialStep", step);
      this.tutorialStep = step;
    },
    exitGame() {
      clearTimeout(this.timer);
      localStorage.removeItem("user");
      localStorage.removeItem("group");
      localStorage.removeItem("accessToken");
      this.$router.push("/");
      //do not remove client session id
    },
    showMessagesModal() {
      this.showMessages = !this.showMessages;
      if (this.showMessages) {
        this.newMessages = 0;
      }
    },
    notifyAction(title = null, subtitle = null, context = null) {
      this.notificationId = this.randomIntFromInterval();
      this.notificationTitle = title;
      this.notificationSubtitle = subtitle;
      this.notificationContext = context;
    },
    randomIntFromInterval(min = 1, max = 10000) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    setupGame() {
      this.groupName = `group${this.group.int_session_group_number}`;
      this.userId = `${this.me.str_nickname}${this.me.id}`;
      this.fetchState("joinGame");
    },
    fetchState(reqType, msg = null, levelData = null, levelTimeout = null) {
      var fetchData = {
        token: config.STATE_TOKEN,
        group: this.groupName,
        request_type: reqType,
        user_id: this.me.id.toString(),
        session_id: this.group.int_session_id,
        client_session_id: this.clientSessionId,
        nickname: this.me.str_nickname,
      };

      if (reqType == "joinGame") {
        fetchData["character"] = this.me.int_character;
        fetchData["color"] = this.me.str_color;
        fetchData["session_start_date"] = this.me.dt_start_date;
        fetchData["session_halt_date"] = this.me.dt_end_date;
      }

      if (reqType == "message") {
        fetchData["message"] = msg;
        clearTimeout(this.timer);
        this.canAct = false;
      }

      if (reqType == "leaveMap") {
        fetchData["level"] = levelData.level;
        fetchData["km"] = levelData.km;
        clearTimeout(this.timer);
        this.canAct = false;
      }

      if (reqType == "fetchLevelTimes") {
        fetchData["level"] = this.level;
        clearTimeout(this.timer);
        this.canAct = false;
      }

      if (reqType == "updateLevel") {
        fetchData["level"] = this.level;
        fetchData = { ...fetchData, ...levelData };
        clearTimeout(this.timer);
        this.canAct = false;
      }

      if (reqType == "timeoutLevel") {
        fetchData["level"] = levelTimeout;
        clearTimeout(this.timer);
        this.canAct = false;
      }

      if (reqType == "timeoutLevelIntro") {
        fetchData["level"] = levelTimeout;
        clearTimeout(this.timer);
        this.canAct = false;
      }

      this.levelBeforeFetch = this.level;
      let notGameLevelBeforeFetch = ["loading", "lobby", "map", "end"].includes(
        this.levelBeforeFetch
      );

      apiStateFetch(fetchData)
        .then((res) => {
          const data = res.data;
          if (config.CURRENT_ENV === "dev") {
            console.log(data);
          }

          if (data.disconnect) {
            clearTimeout(this.timer);
            localStorage.removeItem("user");
            localStorage.removeItem("group");
            localStorage.removeItem("accessToken");
            this.$router.push("/");
          }
          //set players
          this.players = this.setPlayers(data.state.players, this.userId);
          //set messages
          this.messages = data.state.messages;
          if (this.messages.length > this.oldMessagesQtt) {
            if (!this.showMessages) {
              this.newMessages += 1;
            }
          }
          this.oldMessagesQtt = this.messages.length;
          //set visited stations
          this.visitedStations = data.state.visited_levels;
          //set points and km
          this.totalPoints = data.state.total_points;
          this.totalKm = data.state.total_km;

          //set times
          if (data.lobby_seconds) {
            this.lobbySeconds = data.lobby_seconds;
          }
          if (data.game_seconds) {
            this.endTime = data.game_seconds;
          }
          if (data.intro_seconds || data.intro_seconds == 0) {
            this.introSeconds = data.intro_seconds;
          }
          if (data.level_seconds || data.level_seconds == 0) {
            this.levelSeconds = data.level_seconds;
          }
          if (data.halt_seconds || data.halt_seconds == 0) {
            this.haltSeconds = data.halt_seconds;
          }

          //set game data
          this.gameData = data.state.levels;

          if (
            !notGameLevelBeforeFetch &&
            this.levelBeforeFetch != data.state.current_level
          ) {
            this.endGameData = this.gameData[this.levelBeforeFetch];
            this.endedLevel = this.levelBeforeFetch;
            this.showEndNotification = true;
            this.writeEndGame(
              data.state.total_points,
              data.state.total_km,
              data.state.visited_levels,
              this.endedLevel
            );
          }

          //set level
          setTimeout(() => {
            this.level = data.state.current_level;
          }, 200);
          this.updateId = this.randomIntFromInterval();
          if (!this.canAct) {
            this.canAct = true;
          }

          this.timer = setTimeout(() => {
            this.fetchState("fetch");
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
          this.canAct = true;
        });
        /*.finally(() => {
          this.timer = setTimeout(() => {
            this.fetchState("fetch");
          }, 10000);
        });*/
    },
    setPlayers(players, myId) {
      var buddies = [];
      for (var player of Object.entries(players)) {
        if (player[0] != myId) {
          buddies.push(player[1]);
        }
      }
      return buddies;
    },
    sendMessage(msg) {
      this.fetchState("message", msg);
    },
    fetchLevelTimes() {
      this.fetchState("fetchLevelTimes");
    },
    forceFetch() {
      this.fetchState("fetch");
    },
    updateGame(data) {
      this.fetchState("updateLevel", null, data);
    },
    timeoutLevel(level) {
      this.fetchState("timeoutLevel", null, null, level);
    },
    timeoutIntro(level) {
      this.fetchState("timeoutLevelIntro", null, null, level);
    },
    frankfurtCodeCorrect() {
      this.fetchState("frankfurtCodeCorrect");
    },
    voiceError() {
      this.userMuted = true;
      this.showVoiceError = true;
    },
    async writeEndGame(points, km, levels, ended_level) {
      const token = localStorage.getItem("accessToken");
      const updateData = {
        group_id: this.group.id,
        int_points: points,
        int_km: km,
        str_levels: JSON.stringify(levels),
        str_ended_level: ended_level,
      };
      await apiDBUpdateGroup(updateData, token)
        .then((res) => {
          localStorage.setItem("group", JSON.stringify(res.data.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
.game-view {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: url("../assets/img/logo_white_vertical.svg") center no-repeat,
    #9aca3b;
  background-size: 100px auto;
  position: relative;
  .level {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    place-items: center;
    .game {
      height: 85vh;
      width: 65vw;
      background: #fff;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .game-panel {
        height: 75%;
        width: 100%;
      }
      .lower-panel {
        height: 25%;
        width: 100%;
        display: flex;
        flex-direction: row;
        border-top: 3px solid #000;
        .game-info-display {
          height: 100%;
          width: 85%;
          background: #4dc1ec;
        }
      }
    }
  }
  .tutorial-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
}

.vertical-lock {
  display: grid;
}

.voice-error-bg {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  .voice-error {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    .title {
      font-family: "GoetheFFClanBold", sans-serif;
    }
    .subtitle {
      font-family: "GoetheFFClan", sans-serif;
    }
    button {
      background: #fff;
      border-radius: 5px;
      font-family: "GoetheFFClanBold", sans-serif;
      padding: 5px 10px;
      cursor: pointer;
    }
  }
}

@media screen and (orientation: landscape) {
  .vertical-lock {
    display: none;
  }
}

@media (min-width: 1024px) {
  .game-view {
    .level {
      .game {
        width: 70vw;
        .game-panel {
          height: 80%;
        }
        .lower-panel {
          height: 20%;
        }
      }
    }
  }
}
</style>
