<template>
  <div class="endgame-bg">
    <div class="endgame-modal shadowed">
      <h1>{{ $t("utils.endGame") }}</h1>
      <p class="content-paragraph">{{ writeEndGame() }}</p>
      <p class="points-paragraph" v-if="level != 'frankfurt'">
        {{ points }} {{ $t("rostock.points") }}
      </p>
      <button class="close-btn shadowed" @click="close">OK</button>
    </div>
  </div>
</template>

<script>
import rostockEnum from "@/enums/rostock.enum.js";
import munchenEnum from "@/enums/munchen.enum.js";
export default {
  name: "EndGameNotification",
  props: ["level", "gameData"],
  methods: {
    writeEndGame() {
      if (this.level == "rostock") {
        return `${this.$t("rostock.found")} ${this.rostockWordsFound} ${this.$t(
          "rostock.words"
        )} ${this.gameData.seconds_ended || 0} ${this.$t("rostock.seconds")}`;
      } else if (this.level == "hamburg") {
        return `${this.$t("hamburg.groupCorrect")} ${
          this.hamburgCorrectAnswers
        } ${this.$t("hamburg.groupRight")} ${
          this.gameData.seconds_ended || 0
        } ${this.$t("hamburg.seconds")}`;
      } else if (this.level == "mainz") {
        return `${this.$t("mainz.found")} ${
          this.mainzInventionsFound
        } ${this.$t("mainz.inventions")} ${
          this.gameData.seconds_ended || 0
        } ${this.$t("mainz.seconds")}`;
      } else if (this.level == "dresden") {
        return `${this.$t("dresden.identified")} ${
          this.dresdenHallsIdentified
        } ${this.$t("dresden.halls")} ${
          this.gameData.seconds_ended || 0
        } ${this.$t("dresden.seconds")}`;
      } else if (this.level == "eisenach") {
        return `${this.$t("eisenach.groupCorrect")} ${
          this.eisenachCorrectAnswers
        } ${this.$t("eisenach.groupRight")} ${
          this.gameData.seconds_ended || 0
        } ${this.$t("eisenach.seconds")}`;
      } else if (this.level == "koln") {
        if (this.kolnPerfume) {
          let perfumeFeedback = this.$t(`koln.endGame.${this.kolnPerfume}`);
          return `${this.$t("koln.endGame.pt1", { feedback: perfumeFeedback})} ${this.gameData.seconds_ended || 0} ${this.$t(
            "koln.endGame.seconds"
          )}`;
        } else {
          return this.$t("koln.endGame.nothing");
        }
      } else if (this.level == "munchen") {
        return `${this.$t("munchen.found")} ${this.munchenTeamsFound} ${this.$t(
          "munchen.teams"
        )} ${this.gameData.seconds_ended || 0} ${this.$t("munchen.seconds")}`;
      } else if (this.level == "weimar") {
        return `${this.$t("weimar.groupCorrect")} ${
          this.weimarCorrectAnswers
        } ${this.$t("weimar.groupRight")} ${
          this.gameData.seconds_ended || 0
        } ${this.$t("weimar.seconds")}`;
      } else if (this.level == "berlin") {
        return `${this.$t("berlin.identified")} ${
          this.berlinCorrectItems
        } ${this.$t("berlin.foods")} ${
          this.gameData.seconds_ended || 0
        } ${this.$t("berlin.seconds")}`;
      } else if (this.level == "frankfurt") {
        if (this.gameData.timeout) {
          return this.$t("frankfurt.timeout");
        } else {
          return this.$t("frankfurt.keepCode");
        }
      } else if (this.level == "munster") {
        return `${this.$t("munster.captured")} ${
          this.munsterCorrectStars
        } ${this.$t("munster.stars")} ${
          this.gameData.seconds_ended || 0
        } ${this.$t("munster.seconds")}`;
      } else if (this.level == "bremen") {
        return `${this.$t("bremen.answered", {qtt: this.bremenCorrectItems })} ${
          this.gameData.seconds_ended || 0
        } ${this.$t("bremen.seconds")}`;
      } else if (this.level == "bonn") {
        return `${this.$t("bonn.identified")} ${
          this.bonnCorrectPeople
        } ${this.$t("bonn.celebrities")} ${
          this.gameData.seconds_ended || 0
        } ${this.$t("bonn.seconds")}`;
      } else if (this.level == "nurnberg") {
        return `${this.$t("nurnberg.groupCorrect")} ${
          this.nurnbergCorrect
        } ${this.$t("nurnberg.brands")} ${
          this.gameData.seconds_ended || 0
        } ${this.$t("nurnberg.seconds")}`;
      } else if (this.level == "stuttgart") {
        return `${this.$t("stuttgart.groupCorrect")} ${
          this.stuttgartCorrectAnswers
        } ${this.$t("stuttgart.groupRight")} ${
          this.gameData.seconds_ended || 0
        } ${this.$t("stuttgart.seconds")}`;
      }
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    points() {
      return this.gameData.points;
    },
    rostockWordsFound() {
      let words = 0;
      for (let word of rostockEnum.words) {
        if (this.gameData[word]) {
          words += 1;
        }
      }
      return words;
    },
    mainzInventionsFound() {
      return Math.max(this.gameData.phase, 0);
    },
    hamburgCorrectAnswers() {
      let answers = 0;
      Object.values(this.gameData.players).forEach((val) => {
        if (val.answers) {
          val.answers.forEach((v) => v == true && answers++);
        }
      });
      return answers;
    },
    weimarCorrectAnswers() {
      let answers = 0;
      const correct = [1, 2, 2, 2, 1, 2];
      Object.values(this.gameData.players).forEach((val) => {
        if (val.answers) {
          for (let i = 0; i < val.answers.length; i++) {
            if (val.answers[i] == correct[i]) {
              answers++;
            }
          }
        }
      });
      return answers;
    },
    eisenachCorrectAnswers() {
      let answers = 0;
      Object.values(this.gameData.players).forEach((val) => {
        if (val.answers) {
          val.answers.forEach((v) => v == true && answers++);
        }
      });
      return answers;
    },
    dresdenHallsIdentified() {
      return Math.max(this.gameData.phase, 0);
    },
    kolnPerfume() {
      const citric = this.gameData.citric || 0;
      const floral = this.gameData.floral || 0;
      let diff = Math.abs(citric - floral);

      if (citric == 0 && floral == 0) {
        return null;
      }

      if (diff == 0) {
        return "perfect";
      }

      if (citric < floral) {
        diff = diff * -1;
      }
      console.log(diff);

      if (-10 <= diff && diff < -7) {
        return "tooMuchFloral";
      } else if (-7 <= diff && diff <= -4) {
        return "aLittleFloral";
      } else if (-3 <= diff && diff <= 3) {
        return "perfect";
      } else if (4 <= diff && diff <= 7) {
        return "aLittleCitric";
      } else {
        return "tooMuchCitric";
      }
    },
    munchenTeamsFound() {
      let teams = 0;
      for (let team of Object.keys(munchenEnum.teams)) {
        if (this.gameData[team]) {
          teams += 1;
        }
      }
      return teams;
    },
    weimarCorrectItems() {
      let correct = 0;
      const questions = ["q1", "q2", "q3", "q4", "q5", "q6"];
      for (let question of questions) {
        if (this.gameData[question].correct) {
          correct += 1;
        }
      }
      return correct;
    },
    bremenCorrectItems() {
      let correct = 0;
      const questions = ["q1", "q2", "q3", "q4", "q5"];
      for (let question of questions) {
        if (this.gameData[question].correct) {
          correct += 1;
        }
      }
      return correct;
    },
    berlinCorrectItems() {
      return this.gameData.answers.filter(Boolean).length;
    },
    munsterCorrectStars() {
      let answers = 0;
      Object.values(this.gameData.players).forEach((val) => {
        if (val.answers) {
          val.answers.forEach((v) => v == true && answers++);
        }
      });
      return answers;
    },
    bonnCorrectPeople() {
      let correct = 0;
      const people = [
        "beethoven",
        "bach",
        "einstein",
        "grimm",
        "nietzsche",
        "frank",
        "lagerfeld",
        "merkel",
        "schumacher",
      ];
      for (let person of people) {
        if (this.gameData[person]) {
          correct += 1;
        }
      }
      return correct;
    },
    nurnbergCorrect() {
      return this.gameData.picks.filter(Boolean).length;
    },
    stuttgartCorrectAnswers() {
      return this.gameData.success || 0;
    },
  },
};
</script>

<style scoped lang="scss">
.endgame-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  .endgame-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 80%;
    max-width: 450px;
    max-height: 450px;
    background: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    font-family: "GoetheFFClan", sans-serif;
    h1 {
      font-size: 1.7rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }
    .content-paragraph {
      font-size: 0.8rem;
      text-align: center;
      margin: 0;
    }
    .points-paragraph {
      margin-top: 1.5rem;
      font-size: 0.8rem;
      text-align: center;
    }
    .close-btn {
      background: #fff;
      border-radius: 50px;
      font-size: 1.2rem;
      font-weight: 700;
      width: 100px;
      padding: 5px 0;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
      transition: 0.3s;
    }
    .close-btn:hover {
      background: #9aca3b;
      cursor: pointer;
    }
  }
}

@media (min-width: 1024px) {
  .endgame-bg {
    .endgame-modal {
      width: 600px;
      height: 400px;
      h1 {
        font-size: 2rem;
        margin: 2.5rem 0;
      }
      .content-paragraph {
        font-size: 1.2rem;
      }
      .points-paragraph {
        margin: 2.5rem 0;
        font-size: 1.2rem;
      }
    }
  }
}
</style>
