<template>
  <div class="voice-call">
    <vue-webrtc
      :ref="'webrtc'"
      :socketURL="'https://weston-vue-webrtc-lobby.azurewebsites.net'"
      :roomId="room"
      :enableAudio="true"
      :enableVideo="false"
      :autoplay="true"
    ></vue-webrtc>
  </div>
</template>

<script>
export default {
  name: "VoiceCall",
  props: ["room", "userMuted"],
  watch: {
    userMuted(val) {
      if (val) {
        this.$refs.webrtc.leave();
      } else {
        const that = this;
        this.$refs.webrtc
          .join()
          .then(() => {
            console.log("voice connected");
          })
          .catch(() => {
            that.$emit("voice-error");
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.voice-call {
  display: none;
}
</style>
