<template>
  <div class="how-to">
    <h1 class="text-shadow-thin-white">{{ $t("stuttgart.howToPlay") }}</h1>
    <p>{{ $t("stuttgart.howP1") }}</p>
    <p>{{ $t("stuttgart.howP2") }}</p>
    <p>
      {{ $t("stuttgart.howP3") }}
      <span class="highlight text-shadow-extra-thin-white">
        {{ $t("stuttgart.howP3Highlight") }}
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: "StuttgartHowTo",
};
</script>
