export default {
  berlin: {
    bonn: 600,
    bremen: 400,
    dresden: 200,
    eisenach: 350,
    frankfurt: 400,
    hamburg: 300,
    koln: 550,
    mainz: 550,
    munchen: 550,
    munster: 450,
    nurnberg: 400,
    rostock: 200,
    stuttgart: 500,
    weimar: 200,
  },
  bonn: {
    berlin: 600,
    bremen: 300,
    dresden: 550,
    eisenach: 300,
    frankfurt: 150,
    hamburg: 450,
    koln: 50,
    mainz: 150,
    munchen: 550,
    munster: 150,
    nurnberg: 400,
    rostock: 600,
    stuttgart: 350,
    weimar: 350,
  },
  bremen: {
    berlin: 400,
    bonn: 300,
    dresden: 450,
    eisenach: 350,
    frankfurt: 450,
    hamburg: 100,
    koln: 300,
    mainz: 450,
    munchen: 750,
    munster: 150,
    nurnberg: 550,
    rostock: 300,
    stuttgart: 600,
    weimar: 350,
  },
  dresden: {
    berlin: 200,
    bonn: 550,
    bremen: 450,
    eisenach: 250,
    frankfurt: 450,
    hamburg: 450,
    koln: 500,
    mainz: 500,
    munchen: 450,
    munster: 550,
    nurnberg: 300,
    rostock: 400,
    stuttgart: 500,
    weimar: 200,
  },
  eisenach: {
    berlin: 350,
    bonn: 300,
    bremen: 350,
    dresden: 250,
    frankfurt: 200,
    hamburg: 350,
    koln: 300,
    mainz: 200,
    munchen: 450,
    munster: 300,
    nurnberg: 250,
    rostock: 550,
    stuttgart: 300,
    weimar: 100,
  },
  frankfurt: {
    berlin: 400,
    bonn: 150,
    bremen: 450,
    dresden: 450,
    eisenach: 200,
    hamburg: 500,
    koln: 150,
    mainz: 50,
    munchen: 400,
    munster: 250,
    nurnberg: 200,
    rostock: 650,
    stuttgart: 200,
    weimar: 300,
  },
  hamburg: {
    berlin: 300,
    bonn: 450,
    bremen: 100,
    dresden: 450,
    eisenach: 350,
    frankfurt: 500,
    koln: 400,
    mainz: 500,
    munchen: 800,
    munster: 300,
    nurnberg: 600,
    rostock: 150,
    stuttgart: 650,
    weimar: 400,
  },
  koln: {
    berlin: 550,
    bonn: 50,
    bremen: 300,
    dresden: 550,
    eisenach: 300,
    frankfurt: 150,
    hamburg: 400,
    mainz: 150,
    munchen: 550,
    munster: 150,
    nurnberg: 400,
    rostock: 600,
    stuttgart: 350,
    weimar: 350,
  },
  mainz: {
    berlin: 550,
    bonn: 150,
    bremen: 450,
    dresden: 500,
    eisenach: 200,
    frankfurt: 50,
    hamburg: 500,
    koln: 150,
    munchen: 450,
    munster: 300,
    nurnberg: 250,
    rostock: 700,
    stuttgart: 200,
    weimar: 300,
  },
  munchen: {
    berlin: 550,
    bonn: 550,
    bremen: 750,
    dresden: 450,
    eisenach: 450,
    frankfurt: 400,
    hamburg: 800,
    koln: 550,
    mainz: 450,
    munster: 650,
    nurnberg: 300,
    rostock: 750,
    stuttgart: 250,
    weimar: 400,
  },
  munster: {
    berlin: 450,
    bonn: 150,
    bremen: 150,
    dresden: 550,
    eisenach: 300,
    frankfurt: 250,
    hamburg: 300,
    koln: 150,
    mainz: 300,
    munchen: 650,
    nurnberg: 500,
    rostock: 450,
    stuttgart: 450,
    weimar: 400,
  },
  nurnberg: {
    berlin: 400,
    bonn: 400,
    bremen: 550,
    dresden: 300,
    eisenach: 250,
    frankfurt: 200,
    hamburg: 600,
    koln: 400,
    mainz: 250,
    munchen: 300,
    munster: 500,
    rostock: 650,
    stuttgart: 200,
    weimar: 250,
  },
  rostock: {
    berlin: 200,
    bonn: 600,
    bremen: 300,
    dresden: 400,
    eisenach: 550,
    frankfurt: 650,
    hamburg: 150,
    koln: 600,
    mainz: 700,
    munchen: 750,
    munster: 450,
    nurnberg: 650,
    stuttgart: 800,
    weimar: 450,
  },
  stuttgart: {
    berlin: 500,
    bonn: 350,
    bremen: 600,
    dresden: 500,
    eisenach: 300,
    frankfurt: 200,
    hamburg: 650,
    koln: 350,
    mainz: 200,
    munchen: 250,
    munster: 450,
    nurnberg: 200,
    rostock: 800,
    weimar: 350,
  },
  weimar: {
    berlin: 200,
    bonn: 350,
    bremen: 350,
    dresden: 200,
    eisenach: 100,
    frankfurt: 300,
    hamburg: 400,
    koln: 350,
    mainz: 300,
    munchen: 400,
    munster: 400,
    nurnberg: 250,
    rostock: 450,
    stuttgart: 350,
  },
};
