export default {
  phases: [
    [
      {
        german: "die Zitrone",
        item: "lemon",
        val: "citric",
      },
      {
        german: "der Lavendel",
        item: "lavender",
        val: "floral",
      },
      {
        german: "die Orange",
        item: "orange",
        val: "citric",
      },
      {
        german: "der Rosmarin",
        item: "rosemary",
        val: "floral",
      },
    ],
    [
      {
        german: "die Gurke",
        item: "cucumber",
        val: "neutral",
      },
      {
        german: "die Zitrone",
        item: "lemon",
        val: "citric",
      },
      {
        german: "der Lavendel",
        item: "lavender",
        val: "floral",
      },
      {
        german: "die Orange",
        item: "orange",
        val: "citric",
      },
    ],
    [
      {
        german: "der Lavendel",
        item: "lavender",
        val: "floral",
      },
      {
        german: "die Orange",
        item: "orange",
        val: "citric",
      },
      {
        german: "die Passionsfrucht ",
        item: "passionfruit",
        val: "citric",
      },
      {
        german: "der Rosmarin",
        item: "rosemary",
        val: "floral",
      },
    ],
    [
      {
        german: "der Rosmarin",
        item: "rosemary",
        val: "floral",
      },
      {
        german: "die Zitrone",
        item: "lemon",
        val: "citric",
      },
      {
        german: "die Orange",
        item: "orange",
        val: "citric",
      },
      {
        german: "der Apfel ",
        item: "apple",
        val: "neutral",
      },
    ],
    [
      {
        german: "die Passionsfrucht ",
        item: "passionfruit",
        val: "citric",
      },
      {
        german: "der Apfel ",
        item: "apple",
        val: "neutral",
      },
      {
        german: "die Rose",
        item: "rose",
        val: "floral",
      },
      {
        german: "die Gurke",
        item: "cucumber",
        val: "neutral",
      },
    ],
  ],
};
