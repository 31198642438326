<template>
  <div class="level stuttgart-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <div v-if="phase == 0" class="directions-phase">
          <h1>{{ $t("stuttgart.directionsTitle") }}</h1>
          <div class="directions-wrapper">
            <img
              src="@/assets/img/games/stuttgart/front.svg"
              class="direction front"
              @click="listenDirection('front')"
            />
            <img
              src="@/assets/img/games/stuttgart/left.svg"
              class="direction left"
              @click="listenDirection('left')"
            />
            <img
              src="@/assets/img/games/stuttgart/right.svg"
              class="direction right"
              @click="listenDirection('right')"
            />
            <img
              src="@/assets/img/games/stuttgart/back.svg"
              class="direction back"
              @click="listenDirection('back')"
            />
          </div>
          <p class="translation" v-if="directionClicked">
            {{ germanDirections[directionClicked] }} ({{
              $t(`stuttgart.${directionClicked}`)
            }})
          </p>
          <button class="next thinly-shadowed" @click="beginGamePhase">
            {{ $t("stuttgart.next") }}
          </button>
        </div>

        <div v-else class="game-phase">
          <div class="light" :class="light" v-if="light != ''" />
          <div class="sign-wrapper" v-if="showSigns">
            <div class="direction left" @click="pickDirection('left', phase)">
              <img src="@/assets/img/games/stuttgart/curve-left.svg" />
            </div>
            <div class="direction front" @click="pickDirection('front', phase)">
              <img src="@/assets/img/games/stuttgart/front.svg" />
            </div>
            <div class="direction right" @click="pickDirection('right', phase)">
              <img src="@/assets/img/games/stuttgart/curve-right.svg" />
            </div>
          </div>
          <div class="car" :class="moving ? 'moving' : ''" />
        </div>
      </div>
      <div class="lower-panel">
        <div class="game-info-display">
          <h2 v-if="phase == 0">{{ $t("stuttgart.directionsSubtitle") }}</h2>
          <div class="content-wrapper" v-else>
            <div class="speedometer" v-if="phase > 0">
              <img
                class="gauge"
                src="@/assets/img/games/stuttgart/speedometer.svg"
              />
              <img
                class="needle"
                :class="needlePosition"
                src="@/assets/img/games/stuttgart/needle.svg"
              />
            </div>
            <div class="seconds">
              <p v-if="showSigns">{{ currentSeconds }}s</p>
            </div>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="pointsDisplay"
          @timeoutLevel="timeoutLevel"
        />
      </div>

      <waiting-players-notification v-if="lockEnd" />
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="1" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import WaitingPlayersNotification from "@/components/utils/WaitingPlayersNotification.vue";
import stuttgartEnum from "@/enums/stuttgart.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    WaitingPlayersNotification,
    PlayerQtt,
  },
  name: "HamburgLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "stuttgart",
      color: "lightblue",
      step: "intro",
      waitingStart: false,
      showHelp: false,
      lockEnd: false,
      phase: 0,
      gamePhase: 1,
      light: "red-light",
      showSigns: false,
      moving: false,
      yellowTimeout: null,
      greenTimeout: null,
      startTimeout: null,
      runTimeout: null,
      startGameRequested: false,
      timeoutSeconds: [8, 7, 6, 5, 4],
      currentSeconds: 8,
      correct: ["front", "left", "front", "right", "right"],
      audioObj: null,
      directionClicked: null,
      germanDirections: stuttgartEnum,
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }

      this.gamePhase = Math.min(this.gameData.players[this.me].phase) || 1;

      if (this.gamePhase == 6) {
        this.lockEnd = true;
      } else {
        if (
          this.startGameRequested &&
          this.gameData.players[this.me].phase &&
          this.phase < this.gamePhase
        ) {
          this.light = "red-light";
          this.prepare();
          this.phase = this.gamePhase;
          this.currentSeconds = this.timeoutSeconds[this.phase - 1];
        }
      }
    },
    beginGamePhase() {
      if (this.audioObj) {
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        this.audioObj = null;
      }
      this.phase = this.gamePhase;
      this.currentSeconds = this.timeoutSeconds[this.phase - 1];
      this.prepare();
    },
    prepare() {
      if (this.lockEnd) {
        return;
      }
      this.yellowTimeout = setTimeout(() => {
        this.light = "yellow-light";
      }, 1000);

      this.greenTimeout = setTimeout(() => {
        this.light = "green-light";
      }, 2000);

      this.startTimeout = setTimeout(() => {
        this.light = "";
        this.moving = true;
        this.showSigns = true;
        this.run();
        this.audioObj = new Audio(
          require(`../../assets/audio/stuttgart/${
            this.correct[this.phase - 1]
          }.mp3`)
        );
        this.audioObj.play();
        this.startGameRequested = true;
      }, 3000);
    },
    run() {
      this.runTimeout = setTimeout(() => {
        //timeout
        this.currentSeconds -= 1;
        if (this.currentSeconds == 0) {
          this.light = "red-light";
          this.moving = false;
          this.showSigns = false;
          const updateData = { timeout: true };
          this.$emit("notify", this.$t("stuttgart.timeout"), null, "failure");
          this.$emit("updateGame", updateData);
        } else {
          this.run();
        }
      }, 1000);
    },
    pickDirection(direction, phase) {
      if (!this.canAct || this.lockEnd) {
        return;
      }
      clearTimeout(this.runTimeout);
      this.light = "";
      this.moving = false;
      this.showSigns = false;
      const updateData = {
        direction: direction,
        phase: phase,
        nickname: this.me,
      };
      this.$emit("updateGame", updateData);

      let title = "";
      let context = "";
      if (direction == this.correct[phase - 1]) {
        title = this.$t("stuttgart.correct");
        context = "success";
      } else {
        title = this.$t("stuttgart.incorrect");
        context = "failure";
      }

      const subtitle = `${this.$t("stuttgart.correctIs")} ${this.$t(
        `stuttgart.${this.correct[phase - 1]}`
      )}`;

      this.$emit("notify", title, subtitle, context);
    },
    listenDirection(direction) {
      this.directionClicked = direction;
      if (this.audioObj) {
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        this.audioObj = null;
      }
      this.audioObj = new Audio(
        require(`../../assets/audio/stuttgart/${direction}.mp3`)
      );
      this.audioObj.play();
    },
  },
  beforeDestroy() {
    clearTimeout(this.yellowTimeout);
    clearTimeout(this.greenTimeout);
    clearTimeout(this.startTimeout);
    clearTimeout(this.runTimeout);
    if (this.audioObj) {
      this.audioObj.pause();
      this.audioObj.currentTime = 0;
      this.audioObj = null;
    }
  },
  computed: {
    pointsDisplay() {
      return this.gameData.players[this.me]?.points
        ? this.gameData.players[this.me]?.points
        : 0;
    },
    needlePosition() {
      if (this.phase > 1) {
        return `lvl-${this.phase}`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.stuttgart-level {
  background: #4dc1ec;
}

.game-panel {
  display: grid;
  place-items: center;
  //game exclusive
  .directions-phase {
    width: 100%;
    height: 100%;
    background: #0a4879;
    position: relative;
    h1 {
      font-family: "GoetheFFClanBold", sans-serif;
      font-size: 0.8rem;
      text-align: center;
      margin: 1rem 0;
      color: #fff;
    }
    .directions-wrapper {
      height: 120px;
      width: 120px;
      position: relative;
      margin: 0 auto;
      .direction {
        position: absolute;
        height: 45px;
        width: 45px;
        cursor: pointer;
      }
      .front {
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .left {
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
      .right {
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
      }
      .back {
        bottom: 0;
        right: 50%;
        transform: translate(50%, 0);
      }
    }
    .translation {
      text-align: center;
      font-family: "GoetheFFClanBold", sans-serif;
      color: #fff;
      font-size: 0.8rem;
      margin: 0.5rem 0;
    }
    .next {
      position: absolute;
      bottom: 5px;
      right: 5px;
      background: #fff;
      border-radius: 5px;
      padding: 5px;
      cursor: pointer;
      font-family: "GoetheFFClanBold", sans-serif;
    }
  }
  .game-phase {
    width: 100%;
    height: 100%;
    background: url("../../assets/img/games/stuttgart/scene.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .light {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      width: 30px;
      height: 50px;
    }
    .green-light {
      background: url("../../assets/img/games/stuttgart/green-light.svg");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .yellow-light {
      background: url("../../assets/img/games/stuttgart/yellow-light.svg");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .red-light {
      background: url("../../assets/img/games/stuttgart/red-light.svg");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .car {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100px;
      height: 67px;
      background: url("../../assets/img/games/stuttgart/car.svg");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .moving {
      animation: drive 5s;
      animation-iteration-count: infinite;
    }
    .sign-wrapper {
      width: 130px;
      display: flex;
      justify-content: space-around;
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translate(-50%, 0);
      .direction {
        width: 30%;
        cursor: pointer;
        img {
          width: 100%;
          height: 50px;
        }
      }
    }
  }
}

.game-info-display {
  display: grid;
  place-items: center;
  //game exclusive
  h2 {
    font-family: "GoetheFFClanBold", sans-serif;
    margin: 0;
    font-size: 0.8rem;
  }
  .content-wrapper {
    height: 100%;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .speedometer {
      width: 150px;
      position: relative;
      .gauge {
        position: absolute;
        width: 90px;
        height: 70px;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .needle {
        position: absolute;
        width: 20px;
        height: 35px;
        bottom: 12px;
        left: 43%;
        transform: translate(-50%, 0);
        transform: rotate(-80deg);
        transform-origin: bottom;
        transition: 0.3s;
      }
      .lvl-2 {
        transform: rotate(-45deg);
      }
      .lvl-3 {
        transform: rotate(0deg);
      }
      .lvl-4 {
        transform: rotate(45deg);
      }
      .lvl-5 {
        transform: rotate(80deg);
      }
    }
    .seconds {
      width: 150px;
      display: grid;
      place-items: center;
      p {
        text-align: center;
        margin: 0;
        font-size: 1.5rem;
        font-family: "GoetheFFClanBold", sans-serif;
        background: #fff;
        border: 2px solid #000;
        border-radius: 50%;
        padding: 4px 8px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .game-panel {
    display: grid;
    place-items: center;
    //game exclusive
    .directions-phase {
      h1 {
        font-size: 2rem;
        margin: 2rem 0;
      }
      .directions-wrapper {
        height: 250px;
        width: 250px;
        .direction {
          height: 80px;
          width: 80px;
        }
      }
      .translation {
        font-size: 1.3rem;
        margin: 2rem 0;
      }
      .next {
        bottom: 30px;
        right: 30px;
        padding: 10px;
        font-size: 1.5rem;
      }
    }

    .game-phase {
      .light {
        width: 60px;
        height: 100px;
      }
      .car {
        bottom: 70px;
        left: 50%;
        width: 250px;
        height: 168px;
      }
      .sign-wrapper {
        width: 300px;
        top: 50px;
        .direction {
          img {
            height: 90px;
          }
        }
      }
    }
  }

  .game-info-display {
    h2 {
      font-size: 1.5rem;
    }
    .content-wrapper {
      .speedometer {
        width: 300px;
        .gauge {
          width: 180px;
          height: 140px;
        }
        .needle {
          width: 40px;
          height: 70px;
          bottom: 26px;
          left: 43%;
        }
      }
      .seconds {
        width: 300px;
        p {
          font-size: 2.5rem;
          background: #fff;
          padding: 10px 14px;
        }
      }
    }
  }
}
</style>
