<template>
  <div class="messages-bg" :class="{ tutorial: showTutorial }">
    <div class="messages-modal">
      <span class="close" @click="close">x</span>
      <div class="message-area" ref="chatArea">
        <div
          v-for="(message, index) in messages"
          :key="index"
          class="message"
          :class="message.user == myId ? 'my-message' : ''"
        >
          <p class="msg-name">{{ message.nickname }}</p>
          <p class="msg-content">{{ message.message }}</p>
        </div>
      </div>
      <form @submit.prevent="sendMessage">
        <input
          type="text"
          :placeholder="$t(`utils.typeMessage`)"
          v-model="currentMsg"
          class="msg-input"
        />
        <input
          type="submit"
          :disabled="!currentMsg.length"
          value="OK"
          class="send-msg"
        />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Messages",
  props: ["myId", "messages", "canAct", "showTutorial"],
  data() {
    return {
      currentMsg: "",
    };
  },
  methods: {
    close() {
      if (this.showTutorial) {
        this.$emit("nextTutorial", "passport");
      }
      this.$emit("close");
    },
    sendMessage() {
      if (!this.canAct) {
        return;
      }
      this.$emit("sendMessage", this.currentMsg);
      this.currentMsg = "";
    },
  },
  updated() {
    let messageDisplay = this.$refs.chatArea;
    messageDisplay.scrollTop = messageDisplay.scrollHeight;
  },
};
</script>

<style scoped lang="scss">
.messages-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  .try-voice-call {
    position: absolute;
    top: 20px;
    left: 20px;
    background: #fff;
    height: 40px;
    width: 170px;
    border-radius: 10px;
    border: 2px solid #000;
    box-shadow: 0.3rem 0.2rem #000;
    display: grid;
    place-items: center;
    cursor: pointer;
    animation: shake 5s;
    animation-iteration-count: infinite;
    p {
      font-size: 0.7rem;
      padding: 0 0.2rem;
      font-family: "GoetheFFClan", sans-serif;
      font-weight: 700;
      margin: 0;
    }
  }
  .messages-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 70%;
    max-width: 400px;
    max-height: 450px;
    background: #fff;
    border-radius: 15px;
    border: 2px solid #000;
    box-shadow: -0.4rem 0.3rem #000;
    font-family: "GoetheFFClan", sans-serif;
    .close {
      background: #fff;
      position: absolute;
      top: 0px;
      right: -60px;
      font-family: "TrashHand", sans-serif;
      font-weight: 700;
      font-size: 2rem;
      padding: 5px 17px;
      border-radius: 50%;
      cursor: pointer;
    }
    .message-area {
      height: 85%;
      width: 100%;
      box-sizing: border-box;
      padding: 0.5rem;
      overflow-y: auto;
      .message {
        margin: 0.5rem 0;
        padding: 0.2rem 0.5rem;
        box-sizing: border-box;
        background: #f0f7f0;
        border-radius: 5px;
      }
      .my-message {
        text-align: right;
        background: #d4ded5;
      }
      .msg-name {
        margin: 0;
        font-size: 0.6rem;
        font-style: italic;
        font-weight: 600;
      }
      .msg-content {
        margin: 0;
        font-size: 0.8rem;
        width: 100%;
        overflow-wrap: break-word;
      }
    }
    form {
      height: 15%;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      input {
        border-radius: 10px;
        border: 2px solid #000;
        padding: 0 2px;
        font-family: "GoetheFFClan", sans-serif;
        font-weight: 700;
      }
      .msg-input {
        width: 90%;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .send-msg {
        width: 10%;
        background: #98c23d;
        color: #000;
        border-top-right-radius: 0;
        border-bottom-right-radius: 11px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        cursor: pointer;
      }
    }
  }
}
.tutorial {
  z-index: 10;
}

@media (min-width: 1024px) {
  .messages-bg {
    .messages-modal {
      .message-area {
        height: 88%;
      }
      form {
        height: 12%;
        input {
          padding: 0 5px;
          font-size: 1rem;
        }
      }
    }
    .try-voice-call {
      top: 50px;
      left: 50px;
      width: 230px;
      border: 3px solid #000;
      box-shadow: 0.4rem 0.3rem #000;
      p {
        font-size: 1rem;
      }
    }
  }
}
</style>
