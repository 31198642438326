import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import VueI18n from "vue-i18n";
import ptLocale from "./locales/pt.js";
import esLocale from "./locales/es.js";
import deLocale from "./locales/de.js";
import WebRTC from "vue-webrtc";
import config from "./config.json";

Vue.use(VueI18n);
Vue.use(WebRTC);

const messages = {
  pt: ptLocale,
  es: esLocale,
  de: deLocale,
};

function getLocale() {
  try {
    return navigator.language.slice(0, 2);
  } catch {
    return config.I18N_LOCALE;
  }
}

const i18n = new VueI18n({
  locale: getLocale(),
  fallbackLocale: config.I18N_LOCALE_FALLBACK_LOCALE,
  messages,
  silentTranslationWarn: true,
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
