<template>
  <div class="character-creation-step-screen chartype-screen">
    <h1 class="text-shadow-thin-black">{{ $t(`character.pickChar`) }}</h1>
    <div class="content-wrapper">
      <div
        class="char-option shadowed"
        :class="char == 1 ? 'taken' : ''"
        @click="char = 1"
      >
        <char-1 :color="'#98C23D'" />
      </div>
      <div
        class="char-option shadowed"
        :class="char == 2 ? 'taken' : ''"
        @click="char = 2"
      >
        <char-2 :color="'#ED6F23'" />
      </div>
      <div
        class="char-option shadowed"
        :class="char == 3 ? 'taken' : ''"
        @click="char = 3"
      >
        <char-3 :color="'#A82B90'" />
      </div>
      <div
        class="char-option shadowed"
        :class="char == 4 ? 'taken' : ''"
        @click="char = 4"
      >
        <char-4 :color="'#C7BD88'" />
      </div>
      <div
        class="char-option shadowed"
        :class="char == 5 ? 'taken' : ''"
        @click="char = 5"
      >
        <char-5 :color="'#7E5F51'" />
      </div>
      <div
        class="char-option shadowed"
        :class="char == 6 ? 'taken' : ''"
        @click="char = 6"
      >
        <char-6 :color="'#B5B5B5'" />
      </div>
      <transition name="fade">
        <button
          class="char-create-btn shadowed"
          v-if="char"
          @click="submitType"
        >
          {{ $t(`character.go`) }}
        </button>
      </transition>
    </div>
  </div>
</template>
<script>
import Char1 from "@/components/characters/Char1.vue";
import Char2 from "@/components/characters/Char2.vue";
import Char3 from "@/components/characters/Char3.vue";
import Char4 from "@/components/characters/Char4.vue";
import Char5 from "@/components/characters/Char5.vue";
import Char6 from "@/components/characters/Char6.vue";
export default {
  name: "CharType",
  components: { Char1, Char2, Char3, Char4, Char5, Char6 },
  data() {
    return {
      char: null,
    };
  },
  methods: {
    submitType() {
      this.$emit("submitType", this.char);
    },
  },
};
</script>

<style scoped lang="scss">
.chartype-screen {
  background: #4dc1ec;
  .content-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    .char-option {
      background: #fff;
      border-radius: 50%;
      height: 60px;
      width: 60px;
      display: grid;
      place-items: center;
      margin: 0 0.3rem;
      padding: 0.2rem;
      transition: 0.3s;
      cursor: pointer;
    }
    .char-option:hover {
      background: #9aca3b;
    }
    button {
      bottom: -80px;
    }
  }
}

.taken {
  background: #9aca3b !important;
}

@media (min-width: 1024px) {
  .chartype-screen {
    .content-wrapper {
      .char-option {
        height: 90px;
        width: 90px;
        margin: 0 1rem;
        padding: 0.2rem;
      }
    }
  }
}
</style>
