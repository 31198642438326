<template>
  <div class="group-display">
    <div
      class="user-avatar-wrapper shadowed"
      v-for="player in players"
      :key="player.nickname"
    >
      <component
        class="user-avatar"
        :class="{ blurred: player.away }"
        :is="`char-${player.character}`"
        :color="player.color"
      />
      <p class="nickname">{{ player.nickname }}</p>
    </div>
  </div>
</template>

<script>
import Char1 from "@/components/characters/Char1.vue";
import Char2 from "@/components/characters/Char2.vue";
import Char3 from "@/components/characters/Char3.vue";
import Char4 from "@/components/characters/Char4.vue";
import Char5 from "@/components/characters/Char5.vue";
import Char6 from "@/components/characters/Char6.vue";
export default {
  name: "GroupDisplay",
  components: {
    Char1,
    Char2,
    Char3,
    Char4,
    Char5,
    Char6,
  },
  props: ["players"],
};
</script>

<style scoped lang="scss">
.group-display {
  position: absolute;
  left: 15px;
  .user-avatar-wrapper {
    background: #fff;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    margin: 1.5rem 0;
    position: relative;
    display: grid;
    place-items: center;
    .user-avatar {
      height: 70%;
    }
    .nickname {
      position: absolute;
      bottom: -25px;
      margin: 0;
      text-align: center;
      font-family: "TrashHand", sans-serif;
      font-size: 1rem;
    }
  }
}

.blurred {
  filter: blur(4px);
}

@media (min-width: 1024px) {
  .group-display {
    left: 25px;
    .user-avatar-wrapper {
      height: 80px;
      width: 80px;
      margin: 4rem 0;
      .user-avatar {
        height: 65%;
      }
      .nickname {
        bottom: -45px;
        font-size: 2rem;
      }
    }
  }
}
</style>
