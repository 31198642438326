export default {
  signs: [
    ["aries", "taurus"],
    ["gemini", "cancer"],
    ["leo", "virgo"],
    ["libra", "scorpio"],
    ["sagittarius", "capricorn"],
    ["aquarius", "pisces"],
  ],
  germanSigns: {
    aries: "der Widder",
    taurus: "der Stier",
    gemini: "die Zwillinge",
    cancer: "der Krebs",
    leo: "der Löwe",
    virgo: "die Jungfrau",
    libra: "die Waage",
    scorpio: "der Skorpion",
    sagittarius: "der Schütze",
    capricorn: "der Steinbock",
    aquarius: "der Wassermann",
    pisces: "die Fische",
  },
  phases: [
    {
      stars: ["gemini", "aquarius", "cancer", "scorpio"],
      sign: "scorpio",
    },
    {
      stars: ["libra", "sagittarius", "virgo", "aries"],
      sign: "sagittarius",
    },
    {
      stars: ["capricorn", "pisces", "taurus", "leo"],
      sign: "taurus",
    },
    {
      stars: ["aquarius", "taurus", "cancer", "scorpio"],
      sign: "aquarius",
    },
    {
      stars: ["gemini", "leo", "virgo", "libra"],
      sign: "libra",
    },
    {},
  ],
};
