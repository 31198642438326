import axios from "axios";
import config from "../config.json";

const headers = {
  "Content-Type": "application/json",
};

export const apiStateFetch = (data) => {
  return axios.post(`${config.STATE_API_URL}/state`, data, {
    headers: headers,
  });
};

export const apiDBLogin = (data) => {
  return axios.post(`${config.DB_API_URL}/user-login`, data, {
    headers: headers,
  });
};

export const apiDBUpdateGroup = (data, token) => {
  return axios.put(`${config.DB_API_URL}/group`, data, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
  });
};

export const apiDBUpdateUser = (data, token) => {
  return axios.put(`${config.DB_API_URL}/user-update`, data, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
  });
};

export const apiDBCheckToken = (data, token) => {
  return axios.post(
    `${config.DB_API_URL}/user-check-access-token/`,
    data,
    {
      headers: { ...headers, Authorization: `Bearer ${token}` },
    }
  );
};

export const apiDBGetRanking = (session_id, token) => {
  return axios.get(`${config.DB_API_URL}/ranking`, {
    params: { id: session_id },
    headers: { ...headers, Authorization: `Bearer ${token}` },
  });
};

export const apiDBTryTreasure = (data, token) => {
  return axios.post(`${config.DB_API_URL}/treasure`, data, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
  });
};

export const apiDBAdmLogin = (data) => {
  return axios.post(`${config.DB_API_URL}/adm-login`, data, {
    headers: headers,
  });
};

export const apiDBAdmCreateSession = (data, token) => {
  return axios.post(`${config.DB_API_URL}/sessions`, data, {
    headers: { ...headers, Authorization: `Bearer ${token}` },
  });
};

export const apiDBAdmGetSessions = (finished, token) => {
  return axios.get(`${config.DB_API_URL}/sessions`, {
    params: { finished: finished },
    headers: { ...headers, Authorization: `Bearer ${token}` },
  });
};

export const apiDBAdmGetSessionDetails = (id, token) => {
  return axios.get(`${config.DB_API_URL}/session-details`, {
    params: { session_id: id },
    headers: { ...headers, Authorization: `Bearer ${token}` },
  });
};

export const apiDBAdmDeleteSession = (id, token) => {
  return axios.delete(`${config.DB_API_URL}/session-details`, {
    params: { session_id: id },
    headers: { ...headers, Authorization: `Bearer ${token}` },
  });
};

export const apiDBAdmGetSessionGroups = (id, token) => {
  return axios.get(`${config.DB_API_URL}/session-groups`, {
    params: { session_id: id },
    headers: { ...headers, Authorization: `Bearer ${token}` },
  });
};
