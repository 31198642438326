<template>
  <div class="how-to">
    <h1 class="text-shadow-thin-white">{{ $t("bremen.howToPlay") }}</h1>
    <p>
      {{ $t("bremen.howP1") }}
      <span class="highlight text-shadow-extra-thin-white">
        {{ $t("bremen.howP1Highlight") }}
      </span>
    </p>
    <p>
      <span class="highlight text-shadow-extra-thin-white">
        {{ $t("bremen.howP2") }}
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: "BremenHowTo",
};
</script>
