<template>
  <div class="level end-level">
    <div class="header">
      <h1 class="text-shadow-thin-black">{{ $t(`end.congrats`) }}</h1>
      <h2 class="text-shadow-thin-black">{{ $t(`end.tripEnded`) }}</h2>
    </div>

    <div class="table-wrapper" v-if="ranking.length">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>{{ $t(`end.group`) }}</th>
            <th>{{ $t(`end.points`) }}</th>
            <th>Km</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(group, index) in ranking"
            :key="group.id"
            :class="group.id == myGroup.id ? 'my-group-row' : ''"
          >
            <td class="trophy-cell">
              <img src="@/assets/img/utils/trophy-gold.svg" v-if="index == 0" />
              <img
                src="@/assets/img/utils/trophy-silver.svg"
                v-if="index == 1"
              />
              <img
                src="@/assets/img/utils/trophy-bronze.svg"
                v-if="index == 2"
              />
            </td>
            <td>{{ $t("utils.group") }} {{ group.int_session_group_number }}</td>
            <td>{{ group.int_points }}</td>
            <td>{{ group.int_km }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <span class="rate-us">
      <a href="https://forms.gle/YJRdBgxJFH5NwsbdA" target="_blank">
        <th>{{ $t(`end.clickHere`) }}</th>
      </a>
      {{ $t(`end.toEvaluate`) }}
    </span>
    <button class="end-btn" @click="endAutobahn">
      <th>{{ $t(`end.exit`) }}</th>
    </button>
  </div>
</template>

<script>
import { apiDBGetRanking } from "@/utils/goethe-apis.js";
export default {
  name: "EndLevel",
  props: ["me", "time"],
  data() {
    return {
      ranking: [],
      myGroup: {},
      code: "",
      levelSeconds: this.time,
      countdown: null,
    };
  },
  watch: {
    time(val) {
      this.levelSeconds = val;
    },
  },
  created() {
    this.myGroup = JSON.parse(localStorage.getItem("group"));

    const token = localStorage.getItem("accessToken");
    const session_id = this.myGroup.int_session_id;
    apiDBGetRanking(session_id, token)
      .then((res) => {
        if (res.status == 200) {
          this.ranking = res.data.data.groups;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
    this.tryCountdown();
  },
  methods: {
    endAutobahn() {
      clearTimeout(this.countdown);
      this.$emit("exitGame");
    },
    tryCountdown() {
      this.countDownGame();
      this.countdown = setTimeout(() => {
        this.tryCountdown();
      }, 1000);
    },
    countDownGame() {
      if (this.levelSeconds) {
        if (this.levelSeconds == 1) {
          clearTimeout(this.countdown);
          this.$emit("exitGame");
        } else {
          this.levelSeconds -= 1;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.end-level {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #0a4879;
  display: grid;
  place-items: center;
  position: relative;
  .header {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    h1 {
      text-align: center;
      font-family: "TrashHand", sans-serif;
      color: #d4ded5;
      font-size: 1.5rem;
      margin: 0;
    }
    h2 {
      margin: 0.2rem 0 0 0;
      text-align: center;
      font-family: "TrashHand", sans-serif;
      color: #d4ded5;
      font-size: 1rem;
    }
  }
  .table-wrapper {
    width: 65%;
    max-width: 600px;
    height: 55%;
    border: 2px solid #000;
    box-shadow: -0.4rem 0.3rem #000;
    border-radius: 10px;
    overflow-y: scroll;
    table {
      width: 100%;
      height: 100%;
      text-align: center;
      table-layout: fixed;
      background: #d4ded5;
      border-collapse: collapse;
      th {
        padding: 0.5rem 0;
        font-family: "TrashHand", sans-serif;
        font-size: 1.1rem;
      }
      td {
        padding: 0.3rem 0;
        font-family: "GoetheFFClan", sans-serif;
        font-size: 0.9rem;
      }
      .my-group-row {
        background: rgba(154, 202, 59, 0.5);
      }
      .trophy-cell {
        display: grid;
        place-items: center;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .rate-us {
    position: absolute;
    bottom: 40px;
    font-size: 1rem;
    color: #fff;
    font-family: "GoetheFFClanBold", sans-serif;
    a {
      color: #98c23d;
    }
  }
  .end-btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1rem;
    font-weight: 700;
    background: #4dc1ec;
    border-radius: 10px;
    border: 2px solid #000;
    box-shadow: -0.2rem 0.1rem #000;
    padding: 2px 12px;
    font-family: "TrashHand", sans-serif;
    cursor: pointer;
  }
}

@media (min-width: 1024px) {
  .end-level {
    .header {
      top: 30px;
      h1 {
        font-size: 2rem;
      }
      h2 {
        margin: 1rem 0 0 0;
        font-size: 1.3rem;
      }
    }
    .table-wrapper {
      width: 70%;
      height: 50%;
      table {
        width: 100%;
        height: 100%;
        th {
          padding: 0.7rem 0;
          font-size: 1.3rem;
        }
        td {
          padding: 0.5rem 0;
          font-size: 1rem;
        }
        .trophy-cell {
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .rate-us {
      bottom: 120px;
      font-size: 1.2rem;
    }
    .end-btn {
      bottom: 40px;
      font-size: 2rem;
      padding: 5px 15px;
      font-family: "TrashHand", sans-serif;
    }
  }
}
</style>
