export default {
  code: [
    { item: "zero", number: "0", found: false },
    { item: "one", number: "1", found: false },
    { item: "two", number: "2", found: false },
    { item: "three", number: "3", found: false },
    { item: "four", number: "4", found: false },
    { item: "five", number: "5", found: false },
    { item: "six", number: "6", found: false },
    { item: "seven", number: "7", found: false },
    { item: "eight", number: "8", found: false },
    { item: "nine", number: "9", found: false },
  ],
};
