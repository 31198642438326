<template>
  <div class="character-creation-step-screen charcolor-screen">
    <h1 class="text-shadow-thin-black">{{ $t(`character.pickColor`) }}</h1>
    <div class="content-wrapper">
      <div class="char-background shadowed">
        <component :is="`Char-${char}`" :color="color" :size="'big'" />
      </div>
      <div class="color-palette">
        <div class="color orange" @click="chooseColor('#ED6F23')" />
        <div class="color green" @click="chooseColor('#98C23D')" />
        <div class="color light-blue" @click="chooseColor('#4DC1EC')" />
        <div class="color purple" @click="chooseColor('#761E65')" />
        <div class="color gray" @click="chooseColor('#B5B5B5')" />
        <div class="color golden" @click="chooseColor('#C7BD88')" />
        <div class="color brown" @click="chooseColor('#7E5F51')" />
      </div>
      <transition name="fade">
        <button
          class="char-create-btn shadowed"
          v-if="colorChosen"
          @click="submitColor"
        >
          {{ $t(`character.go`) }}
        </button>
      </transition>
    </div>
  </div>
</template>
<script>
import Char1 from "@/components/characters/Char1.vue";
import Char2 from "@/components/characters/Char2.vue";
import Char3 from "@/components/characters/Char3.vue";
import Char4 from "@/components/characters/Char4.vue";
import Char5 from "@/components/characters/Char5.vue";
import Char6 from "@/components/characters/Char6.vue";
export default {
  name: "CharColor",
  components: { Char1, Char2, Char3, Char4, Char5, Char6 },
  props: ["char"],
  data() {
    return {
      color: "#9aca3b",
      colorChosen: false,
    };
  },
  methods: {
    chooseColor(color) {
      this.color = color;
      this.colorChosen = true;
    },
    submitColor() {
      this.$emit("submitColor", this.color);
    },
  },
};
</script>

<style scoped lang="scss">
.charcolor-screen {
  background: #d4ded5;
  .content-wrapper {
    position: relative;
    .char-background {
      background: #fff;
      border-radius: 50%;
      height: 90px;
      width: 90px;
      display: grid;
      place-items: center;
      margin: 0 auto;
    }
    .color-palette {
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      .color {
        height: 30px;
        width: 30px;
        margin: 0 0.5rem;
        border-radius: 50px;
        border: 2px solid #000;
        cursor: pointer;
      }
    }
    button {
      bottom: -60px;
    }
  }
}

.orange {
  background: #ed6f23;
}

.green {
  background: #98c23d;
}

.light-blue {
  background: #4dc1ec;
}

.purple {
  background: #761e65;
}

.gray {
  background: #b5b5b5;
}

.golden {
  background: #c7bd88;
}

.brown {
  background: #7e5f51;
}

.taken {
  background: #9aca3b !important;
}

@media (min-width: 1024px) {
  .charcolor-screen {
    .content-wrapper {
      .char-background {
        height: 200px;
        width: 200px;
      }
      .color-palette {
        margin-top: 3rem;
        .color {
          height: 50px;
          width: 50px;
          margin: 0 0.7rem;
        }
      }
      button {
        bottom: -80px;
      }
    }
  }
}
</style>
