<template>
  <div
    class="music-mute-btn thinly-shadowed"
    :class="{ tutorial: showTutorial }"
    @click="controlMusic"
  >
    <img
      class="mic-img"
      src="@/assets/img/utils/no-music.svg"
      v-if="!playing"
    />
    <img class="mic-img" src="@/assets/img/utils/music.svg" v-else />
  </div>
</template>

<script>
export default {
  name: "MusicBtn",
  props: ["showTutorial"],
  data() {
    return {
      audioObj: null,
      playing: false,
    };
  },
  methods: {
    controlMusic() {
      if (this.showTutorial) {
        this.$emit("nextTutorial", "time");
      }
      if (this.playing) {
        this.audioObj.pause();
        this.playing = false;
      } else {
        if (!this.audioObj) {
          this.audioObj = new Audio(
            require(`../../assets/audio/game_songs.mp3`)
          );
          const that = this;
          this.audioObj.onended = function () {
            that.audioObj = null;
            that.playing = false;
            that.controlMusic();
          };
        }
        this.audioObj.play();
        this.playing = true;
      }
    },
  },
  beforeDestroy() {
    if (this.audioObj) {
      this.audioObj.pause();
      this.audioObj = null;
    }
  },
};
</script>

<style scoped lang="scss">
.music-mute-btn {
  position: absolute;
  bottom: 5px;
  right: 77px;
  background: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  .mic-img {
    height: 70%;
    width: 70%;
  }
}
.tutorial {
  z-index: 10;
  animation: shake 5s;
  animation-iteration-count: infinite;
}

@media (min-width: 1024px) {
  .music-mute-btn {
    bottom: 20px;
    right: 130px;
    height: 45px;
    width: 45px;
    p {
      font-size: 1.7rem;
    }
  }
}
</style>
