<template>
  <div class="how-to">
    <h1 class="text-shadow-thin-white">{{ $t("bonn.howToPlay") }}</h1>
    <p class="dark-text">{{ $t("bonn.howP1") }}</p>
    <p class="dark-text">
      <span class="dark-highlight">
        {{ $t("bonn.howP2") }}
      </span>
    </p>
    <p class="dark-text">
      <span class="dark-highlight">
        {{ $t("bonn.howP3") }}
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: "BonnHowTo",
};
</script>
