<template>
  <div class="level rostock-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <table>
          <tr>
            <td ref="1-1" @click="clickLetter('1-1', 'a')">A</td>
            <td ref="1-2" @click="clickLetter('1-2', 'k')">K</td>
            <td ref="1-3" @click="clickLetter('1-3', 'g')">G</td>
            <td ref="1-4" @click="clickLetter('1-4', 'r')">R</td>
            <td ref="1-5" @click="clickLetter('1-5', 's')">S</td>
            <td ref="1-6" @click="clickLetter('1-6', 'c')">C</td>
            <td ref="1-7" @click="clickLetter('1-7', 'h')">H</td>
            <td ref="1-8" @click="clickLetter('1-8', 'i')">I</td>
            <td ref="1-9" @click="clickLetter('1-9', 'f')">F</td>
            <td ref="1-10" @click="clickLetter('1-10', 'f')">F</td>
            <td ref="1-11" @click="clickLetter('1-11', 'k')">K</td>
            <td ref="1-12" @click="clickLetter('1-12', 'p')">P</td>
            <td ref="1-13" @click="clickLetter('1-13', 'h')">H</td>
            <td ref="1-14" @click="clickLetter('1-14', 'g')">G</td>
            <td ref="1-15" @click="clickLetter('1-15', 'd')">D</td>
            <td ref="1-16" @click="clickLetter('1-16', 'x')">X</td>
            <td ref="1-17" @click="clickLetter('1-17', 'z')">Z</td>
            <td ref="1-18" @click="clickLetter('1-18', 'o')">O</td>
          </tr>

          <tr>
            <td ref="2-1" @click="clickLetter('2-1', 'g')">G</td>
            <td ref="2-2" @click="clickLetter('2-2', 'p')">P</td>
            <td ref="2-3" @click="clickLetter('2-3', 'l')">L</td>
            <td ref="2-4" @click="clickLetter('2-4', 'k')">K</td>
            <td ref="2-5" @click="clickLetter('2-5', 'i')">I</td>
            <td ref="2-6" @click="clickLetter('2-6', 'o')">O</td>
            <td ref="2-7" @click="clickLetter('2-7', 'v')">V</td>
            <td ref="2-8" @click="clickLetter('2-8', 'h')">H</td>
            <td ref="2-9" @click="clickLetter('2-9', 'r')">R</td>
            <td ref="2-10" @click="clickLetter('2-10', 'o')">O</td>
            <td ref="2-11" @click="clickLetter('2-11', 's')">S</td>
            <td ref="2-12" @click="clickLetter('2-12', 't')">T</td>
            <td ref="2-13" @click="clickLetter('2-13', 'o')">O</td>
            <td ref="2-14" @click="clickLetter('2-14', 'c')">C</td>
            <td ref="2-15" @click="clickLetter('2-15', 'k')">K</td>
            <td ref="2-16" @click="clickLetter('2-16', 'l')">L</td>
            <td ref="2-17" @click="clickLetter('2-17', 'v')">V</td>
            <td ref="2-18" @click="clickLetter('2-18', 'm')">M</td>
          </tr>

          <tr>
            <td ref="3-1" @click="clickLetter('3-1', 'l')">L</td>
            <td ref="3-2" @click="clickLetter('3-2', 'n')">N</td>
            <td ref="3-3" @click="clickLetter('3-3', 'a')">A</td>
            <td ref="3-4" @click="clickLetter('3-4', 't')">T</td>
            <td ref="3-5" @click="clickLetter('3-5', 'i')">I</td>
            <td ref="3-6" @click="clickLetter('3-6', 'b')">B</td>
            <td ref="3-7" @click="clickLetter('3-7', 'j')">J</td>
            <td ref="3-8" @click="clickLetter('3-8', 'v')">V</td>
            <td ref="3-9" @click="clickLetter('3-9', 'z')">Z</td>
            <td ref="3-10" @click="clickLetter('3-10', 'f')">F</td>
            <td ref="3-11" @click="clickLetter('3-11', 'a')">A</td>
            <td ref="3-12" @click="clickLetter('3-12', 'e')">E</td>
            <td ref="3-13" @click="clickLetter('3-13', 'r')">R</td>
            <td ref="3-14" @click="clickLetter('3-14', 's')">S</td>
            <td ref="3-15" @click="clickLetter('3-15', 'w')">W</td>
            <td ref="3-16" @click="clickLetter('3-16', 'k')">K</td>
            <td ref="3-17" @click="clickLetter('3-17', 'n')">N</td>
            <td ref="3-18" @click="clickLetter('3-18', 'a')">A</td>
          </tr>

          <tr>
            <td ref="4-1" @click="clickLetter('4-1', 'b')">B</td>
            <td ref="4-2" @click="clickLetter('4-2', 'x')">X</td>
            <td ref="4-3" @click="clickLetter('4-3', 'n')">N</td>
            <td ref="4-4" @click="clickLetter('4-4', 'f')">F</td>
            <td ref="4-5" @click="clickLetter('4-5', 'a')">A</td>
            <td ref="4-6" @click="clickLetter('4-6', 'o')">O</td>
            <td ref="4-7" @click="clickLetter('4-7', 'c')">C</td>
            <td ref="4-8" @click="clickLetter('4-8', 't')">T</td>
            <td ref="4-9" @click="clickLetter('4-9', 'e')">E</td>
            <td ref="4-10" @click="clickLetter('4-10', 'w')">W</td>
            <td ref="4-11" @click="clickLetter('4-11', 'q')">Q</td>
            <td ref="4-12" @click="clickLetter('4-12', 'o')">O</td>
            <td ref="4-13" @click="clickLetter('4-13', 'i')">I</td>
            <td ref="4-14" @click="clickLetter('4-14', 's')">S</td>
            <td ref="4-15" @click="clickLetter('4-15', 'b')">B</td>
            <td ref="4-16" @click="clickLetter('4-16', 'm')">M</td>
            <td ref="4-17" @click="clickLetter('4-17', 'l')">L</td>
            <td ref="4-18" @click="clickLetter('4-18', 't')">T</td>
          </tr>

          <tr>
            <td ref="5-1" @click="clickLetter('5-1', 'x')">X</td>
            <td ref="5-2" @click="clickLetter('5-2', 'v')">V</td>
            <td ref="5-3" @click="clickLetter('5-3', 'k')">K</td>
            <td ref="5-4" @click="clickLetter('5-4', 'd')">D</td>
            <td ref="5-5" @click="clickLetter('5-5', 'w')">W</td>
            <td ref="5-6" @click="clickLetter('5-6', 'o')">O</td>
            <td ref="5-7" @click="clickLetter('5-7', 'x')">X</td>
            <td ref="5-8" @click="clickLetter('5-8', 'h')">H</td>
            <td ref="5-9" @click="clickLetter('5-9', 'a')">A</td>
            <td ref="5-10" @click="clickLetter('5-10', 'u')">U</td>
            <td ref="5-11" @click="clickLetter('5-11', 'p')">P</td>
            <td ref="5-12" @click="clickLetter('5-12', 't')">T</td>
            <td ref="5-13" @click="clickLetter('5-13', 'd')">D</td>
            <td ref="5-14" @click="clickLetter('5-14', 'e')">E</td>
            <td ref="5-15" @click="clickLetter('5-15', 'c')">C</td>
            <td ref="5-16" @click="clickLetter('5-16', 'k')">K</td>
            <td ref="5-17" @click="clickLetter('5-17', 'x')">X</td>
            <td ref="5-18" @click="clickLetter('5-18', 'r')">R</td>
          </tr>

          <tr>
            <td ref="6-1" @click="clickLetter('6-1', 'a')">A</td>
            <td ref="6-2" @click="clickLetter('6-2', 'v')">V</td>
            <td ref="6-3" @click="clickLetter('6-3', 'e')">E</td>
            <td ref="6-4" @click="clickLetter('6-4', 'k')">K</td>
            <td ref="6-5" @click="clickLetter('6-5', 'p')">P</td>
            <td ref="6-6" @click="clickLetter('6-6', 't')">T</td>
            <td ref="6-7" @click="clickLetter('6-7', 'k')">K</td>
            <td ref="6-8" @click="clickLetter('6-8', 'j')">J</td>
            <td ref="6-9" @click="clickLetter('6-9', 's')">S</td>
            <td ref="6-10" @click="clickLetter('6-10', 'a')">A</td>
            <td ref="6-11" @click="clickLetter('6-11', 'e')">E</td>
            <td ref="6-12" @click="clickLetter('6-12', 'f')">F</td>
            <td ref="6-13" @click="clickLetter('6-13', 'b')">B</td>
            <td ref="6-14" @click="clickLetter('6-14', 't')">T</td>
            <td ref="6-15" @click="clickLetter('6-15', 'z')">Z</td>
            <td ref="6-16" @click="clickLetter('6-16', 'x')">X</td>
            <td ref="6-17" @click="clickLetter('6-17', 'k')">K</td>
            <td ref="6-18" @click="clickLetter('6-18', 'o')">O</td>
          </tr>

          <tr>
            <td ref="7-1" @click="clickLetter('7-1', 's')">S</td>
            <td ref="7-2" @click="clickLetter('7-2', 'b')">B</td>
            <td ref="7-3" @click="clickLetter('7-3', 'r')">R</td>
            <td ref="7-4" @click="clickLetter('7-4', 'g')">G</td>
            <td ref="7-5" @click="clickLetter('7-5', 'k')">K</td>
            <td ref="7-6" @click="clickLetter('7-6', 'i')">I</td>
            <td ref="7-7" @click="clickLetter('7-7', 's')">S</td>
            <td ref="7-8" @click="clickLetter('7-8', 'j')">J</td>
            <td ref="7-9" @click="clickLetter('7-9', 'f')">F</td>
            <td ref="7-10" @click="clickLetter('7-10', 'm')">M</td>
            <td ref="7-11" @click="clickLetter('7-11', 'e')">E</td>
            <td ref="7-12" @click="clickLetter('7-12', 'e')">E</td>
            <td ref="7-13" @click="clickLetter('7-13', 'r')">R</td>
            <td ref="7-14" @click="clickLetter('7-14', 'l')">L</td>
            <td ref="7-15" @click="clickLetter('7-15', 'r')">R</td>
            <td ref="7-16" @click="clickLetter('7-16', 'l')">L</td>
            <td ref="7-17" @click="clickLetter('7-17', 'c')">C</td>
            <td ref="7-18" @click="clickLetter('7-18', 's')">S</td>
          </tr>

          <tr>
            <td ref="8-1" @click="clickLetter('8-1', 'c')">C</td>
            <td ref="8-2" @click="clickLetter('8-2', 's')">S</td>
            <td ref="8-3" @click="clickLetter('8-3', 'f')">F</td>
            <td ref="8-4" @click="clickLetter('8-4', 'w')">W</td>
            <td ref="8-5" @click="clickLetter('8-5', 'e')">E</td>
            <td ref="8-6" @click="clickLetter('8-6', 'l')">L</td>
            <td ref="8-7" @click="clickLetter('8-7', 'l')">L</td>
            <td ref="8-8" @click="clickLetter('8-8', 'e')">E</td>
            <td ref="8-9" @click="clickLetter('8-9', 'g')">G</td>
            <td ref="8-10" @click="clickLetter('8-10', 't')">T</td>
            <td ref="8-11" @click="clickLetter('8-11', 'h')">H</td>
            <td ref="8-12" @click="clickLetter('8-12', 'w')">W</td>
            <td ref="8-13" @click="clickLetter('8-13', 'r')">R</td>
            <td ref="8-14" @click="clickLetter('8-14', 'i')">I</td>
            <td ref="8-15" @click="clickLetter('8-15', 'u')">U</td>
            <td ref="8-16" @click="clickLetter('8-16', 'p')">P</td>
            <td ref="8-17" @click="clickLetter('8-17', 'j')">J</td>
            <td ref="8-18" @click="clickLetter('8-18', 'e')">E</td>
          </tr>
        </table>
      </div>
      <div class="lower-panel text-shadow-extra-thin-black">
        <div class="game-info-display">
          <div class="info-col">
            <p ref="meer">MEER</p>
            <p ref="matrose">MATROSE</p>
          </div>
          <div class="info-col">
            <p ref="schiff">SCHIFF</p>
            <p ref="anker">ANKER</p>
          </div>
          <div class="info-col">
            <p ref="boot">BOOT</p>
            <p ref="hauptdeck">HAUPTDECK</p>
          </div>
          <div class="info-col">
            <p ref="rostock">ROSTOCK</p>
            <p ref="welle">WELLE</p>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="gameData.points"
          @timeoutLevel="timeoutLevel"
        />
      </div>
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="4" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import rostockEnum from "@/enums/rostock.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: { GameIntro, Help, HelpBtn, GameClock, PlayerQtt },
  name: "RostockLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
    step() {
      setTimeout(() => {
        this.updateGame();
      }, 100);
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "rostock",
      color: "green",
      step: null,
      waitingStart: false,
      showHelp: false,
      current: "",
      foundWords: [],
      currentPositions: [],
      usedPositions: [],
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }
      this.usedPositions = [];
      for (let word of rostockEnum.words) {
        if (this.gameData[word]) {
          if (!this.foundWords.includes(word)) {
            this.foundWords.push(word);
            let translation = this.$t(`rostock.${word}`);
            if (!this.isFirstLoad) {
              this.$emit(
                "notify",
                `${this.$t("rostock.wordFound")} ${
                  rostockEnum.substantives[word]
                }`,
                `${this.$t("rostock.translation")} ${translation}`,
                "success"
              );
            }
          }
          this.usedPositions = this.usedPositions.concat(
            rostockEnum.wordPositions[word]
          );
        }
      }
      for (let position of this.usedPositions) {
        if (this.$refs[position]) {
          this.$refs[position].classList.value = "taken text-shadow-thin-black";
        }
      }
      for (let word of this.foundWords) {
        if (this.$refs[word]) {
          this.$refs[word].classList.value = "found";
        }
      }

      if (this.currentPositions.length) {
        const currentSelected = this.currentPositions.at(-1);
        if (this.usedPositions.includes(currentSelected)) {
          this.$refs[currentSelected].classList.value =
            "taken text-shadow-thin-black";
          this.current = "";
          this.currentPositions = [];
        }
      }
      this.isFirstLoad = false;
    },
    clickLetter(position, letter) {
      if (this.usedPositions.includes(position) || !this.canAct) {
        return;
      }

      if (!this.current.length) {
        this.$refs[position].classList.value =
          "selected text-shadow-thin-black";
        this.current += letter;
        this.currentPositions.push(position);
        return;
      }

      const lastPos = this.currentPositions.at(-1);
      if (position == lastPos) {
        this.$refs[position].classList.value = "";
        this.current = this.current.slice(0, -1);
        this.currentPositions.pop();
        if (this.currentPositions.length) {
          this.$refs[this.currentPositions.at(-1)].classList.value =
            "selected text-shadow-thin-black";
        }
        return;
      }

      const lastRow = lastPos.split("-")[0];
      const lastCol = lastPos.split("-")[1];
      const newRow = position.split("-")[0];
      const newCol = position.split("-")[1];
      if (
        (newRow == lastRow || newRow + 1 == lastRow || newRow - 1 == lastRow) &&
        (newCol == lastCol || newCol + 1 == lastCol || newCol - 1 == lastCol)
      ) {
        if (rostockEnum.words.includes(this.current + letter)) {
          this.$refs[this.currentPositions.at(-1)].classList.value =
            "taken text-shadow-thin-black";
          this.$refs[position].classList.value = "taken text-shadow-thin-black";
          const foundWord = this.current + letter;
          this.current = "";
          this.currentPositions.push(position);
          this.$refs[foundWord].classList.value = "found";
          let updateData = {};
          updateData[foundWord] = true;
          this.$emit("updateGame", updateData);
        } else if (
          rostockEnum.words.some((a) => a.includes(this.current + letter))
        ) {
          this.$refs[this.currentPositions.at(-1)].classList.value =
            "taken text-shadow-thin-black";
          this.$refs[position].classList.value =
            "selected text-shadow-thin-black";
          this.current += letter;
          this.currentPositions.push(position);
        }
        return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.rostock-level {
  background: #9aca3b;
  height: 100%;
  width: 100%;
  position: relative;
}

.game-panel {
  display: grid;
  place-items: center;
  table {
    width: 90%;
    font-family: "TrashHand", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    tr {
      width: 100%;
      td {
        width: 5.55%;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.game-info-display {
  display: flex;
  flex-direction: row;
  .info-col {
    width: 25%;
    height: 100%;
    text-align: center;
    display: grid;
    place-items: center;
    p {
      margin: 0;
      color: #ffffff;
      font-size: 0.65rem;
      font-weight: 700;
      letter-spacing: 0.15rem;
      font-family: "GoetheFFClan", sans-serif;
    }
  }
}

.taken {
  color: #f36f21;
}

.selected {
  animation: opacityFlash 0.7s linear infinite;
  color: #98c23d;
}

.found {
  text-decoration: line-through;
  color: #f36f21 !important;
}

@media (min-width: 1024px) {
  .game-panel {
    table {
      font-size: 1.7rem;
      td {
        padding: 7px 0;
      }
    }
  }

  .game-info-display {
    .info-col {
      p {
        font-size: 1.2rem;
        letter-spacing: 0.15rem;
      }
    }
  }
}
</style>
