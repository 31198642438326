<template>
  <div
    class="audio-btn"
    :class="playing ? 'playing-btn' : ''"
    @click="controlSound(file)"
  >
    <div class="progress" :style="`width: ${progress}%`" />
    <img src="@/assets/img/utils/play.svg" v-if="!playing" />
    <img src="@/assets/img/utils/stop.svg" v-else />
  </div>
</template>

<script>
export default {
  name: "AudioPlayer",
  props: ["file"],
  data() {
    return {
      progress: 0,
      playing: false,
      audioObj: null,
    };
  },
  methods: {
    controlSound(song) {
      if (this.playing) {
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        this.audioObj = null;
        this.progress = 0;
        this.playing = false;
        return;
      }

      this.audioObj = new Audio(require(`../../assets/audio/${song}`));
      this.audioObj.play();
      this.playing = true;

      const that = this;
      this.audioObj.ontimeupdate = function () {
        that.progress = Math.min((this.currentTime / this.duration) * 100, 100);
      };
      this.audioObj.onended = function () {
        that.progress = 0;
        that.playing = false;
      };
    },
    stopSound() {
      if (this.audioObj != null) {
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        this.audioObj = null;
        this.progress = 0;
        this.playing = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.audio-btn {
  height: 30px;
  min-width: 30px;
  background: #4dc1ec;
  border-radius: 15px;
  border: 2px solid #000;
  box-shadow: -0.2rem 0.2rem #000;
  display: grid;
  place-items: center;
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  .progress {
    position: absolute;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background: #ed6f23;
  }
  img {
    height: 14px;
    transition: 0.3s;
    filter: brightness(0) invert(1);
  }
}

.audio-btn:hover {
  background: #33a5ce;
  img {
    filter: none;
    z-index: 2;
  }
}

.playing-btn {
  background: #e9a378;
}

.playing-btn:hover {
  background: #e9a378;
  img {
    filter: none;
    z-index: 2;
  }
}

@media (min-width: 1024px) {
  .audio-btn {
    height: 30px;
    width: 100px;
    img {
      height: 20px;
      transition: 0.3s;
      filter: brightness(0) invert(1);
    }
  }
}
</style>
