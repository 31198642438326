<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="char-svg"
    :class="`char-size-${size}`"
    viewBox="0 0 89.442 83.371"
  >
    <g
      id="Group_613"
      data-name="Group 613"
      transform="translate(-236.853 -458.929)"
    >
      <path
        id="Path_525"
        data-name="Path 525"
        d="M123.157,83c-10.817-7.552-24.337-10.1-37.6-3.123C74.537,85.672,65.9,97.218,62.7,109.084c-3.03,11.256-4.405,22.517-.337,33.624a14.484,14.484,0,0,0,5.152,7.185,8.528,8.528,0,0,0,5.189,2.826,11.184,11.184,0,0,0,6.858,1.548c4.976-.336,7.914-4.725,9.868-9.69,3.038,4.853,7.255,8.768,13.121,10.2,7.092,1.728,11.278-3.394,13.593-9.456,3.084,3.486,6.966,6.036,11.8,5.808,8.842-.417,14.248-9.811,16.21-17.452C148.845,115.421,138.84,93.95,123.157,83Z"
        transform="translate(178.919 385.143)"
        :fill="color"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="4"
      />
      <g id="Group_603" data-name="Group 603">
        <path
          id="Path_539"
          data-name="Path 539"
          d="M79.011,102.677a3.222,3.222,0,0,0-3.191,3.191v.426a3.191,3.191,0,1,0,6.382,0v-.426A3.221,3.221,0,0,0,79.011,102.677Z"
          transform="translate(187.175 388.372)"
          fill="#1c1c1c"
          stroke="#071a2f"
          stroke-width="1"
        />
        <path
          id="Path_540"
          data-name="Path 540"
          d="M103.988,101.344c-4.115,0-4.115,6.382,0,6.382S108.1,101.344,103.988,101.344Z"
          transform="translate(194.109 388.003)"
          fill="#1c1c1c"
          stroke="#071a2f"
          stroke-width="1"
        />
        <path
          id="Path_541"
          data-name="Path 541"
          d="M107.815,113.742a7.187,7.187,0,0,0-3.717,1.822c-2.188,1.8-5.126-.631-7.6-.97-4.766-.655-7.166,4.447-11.5.493-.036-.033-.074-.054-.11-.083a1.694,1.694,0,0,0-1.159-.836,5.056,5.056,0,0,0-5.992,2.582,1.915,1.915,0,1,0,3.306,1.933c.069-.137.142-.269.222-.4.057-.083.084-.124.1-.151a3.1,3.1,0,0,1,.231-.236c-.214.206.036.011.148-.069l.078-.024c.036-.011.05-.018.073-.026s.068,0,.126,0c.087,0,.174,0,.26.009l.008.009c4.478,4.091,6.718,2.882,11.78.965,3.153-1.193,4.872,1.693,7.784,1.713,3.195.023,4.036-2.547,6.977-3.038C111.264,117.029,110.23,113.338,107.815,113.742Z"
          transform="translate(187.637 391.422)"
          fill="#1c1c1c"
          stroke="#071a2f"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Char1",
  props: ["color", "size"],
};
</script>

<style lang="scss">
.char-svg {
  height: 35px;
  width: 35px;
  margin: 0 auto;
}

.char-size-medium {
  height: 60px;
  width: 60px;
}

.char-size-big {
  height: 70px;
  width: 70px;
}

.char-size-large {
  height: 90px;
  width: 90px;
}

@media (min-width: 1024px) {
  .char-svg {
    height: 60px;
    width: 60px;
  }

  .char-size-medium {
    height: 130px;
    width: 130px;
  }

  .char-size-big {
    height: 150px;
    width: 150px;
  }

  .char-size-large {
    height: 180px;
    width: 180px;
  }
}
</style>
