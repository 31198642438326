<template>
  <div class="level loading-level"></div>
</template>

<script>
export default {
  name: "LoadingLevel",
};
</script>

<style scoped lang="scss">
.loading-level {
  background: transparent;
}
</style>
