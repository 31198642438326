<template>
  <div class="level map-level">
    <img src="@/assets/img/map/sea.svg" alt="Sea" class="sea" />
    <img
      v-for="tree in trees"
      :key="tree.left"
      src="@/assets/img/map/tree.svg"
      class="tree"
      :style="`left: ${tree.left}; top: ${tree.top}`"
    />
    <div class="map-wrapper">
      <img src="@/assets/img/map/map.svg" alt="Map" class="map" />
      <svg class="flight-line">
        <line
          :x1="x1"
          :y1="y1"
          :x2="x2"
          :y2="y2"
          stroke-dasharray="10,10"
          d="M5 40 l215 0"
          stroke-width="3"
          style="stroke: rgb(229, 0, 25)"
        />
      </svg>
      <pin
        v-for="(city, index) in cities"
        :key="index"
        :city="city"
        :km="stationDistances"
        :canAct="canAct"
      />
    </div>
    <transition name="bounce">
      <div class="km-display" v-if="x1 > 0">
        {{ $t("utils.distance") }}: {{ stationDistances }} Km
      </div>
    </transition>

    <transition name="bounce">
      <div class="ask-frankfurt" v-if="frankfurtCodePhase > 0">
        <div class="frankfurt-code-dialog">
          <div v-if="frankfurtCodePhase == 1">
            <p class="title">Hey!</p>
            <p class="question">
              {{ $t("utils.rememberFrankfurt") }}
            </p>
            <div class="btn-wrapper">
              <button @click="frankfurtCodePhase = 2">
                {{ $t("utils.yes") }}
              </button>
              <button @click="frankfurtCodePhase = 0">
                {{ $t("utils.no") }}
              </button>
            </div>
          </div>

          <div v-if="frankfurtCodePhase == 2">
            <p class="question">{{ $t("utils.frankfurtCode") }}</p>
            <div class="input-wrapper">
              <input
                type="text"
                :placeholder="$t('utils.inputCode')"
                v-model="code"
              />
              <button @click="tryCode">OK</button>
            </div>
          </div>

          <div v-if="frankfurtCodePhase == 3">
            <p class="title" v-if="correctCode">
              {{ $t("utils.correctTitle") }}
            </p>
            <p class="title" v-else>
              {{ $t("utils.wrongTitle") }}
            </p>
            <p class="question" v-if="correctCode">
              {{ $t("utils.frankfurtCorrect") }}
              <a
                href="https://youtube.com/playlist?list=PL4B5xOXAjplyH5sLsLKTqKe5uYS1wCIaT"
                target="_blank"
                >{{
                  freshGift ? $t("utils.giftFresh") : $t("utils.giftNotFresh")
                }}</a
              >
            </p>
            <p class="question" v-else>
              {{ $t("utils.frankfurtWrong") }}
            </p>
            <div class="btn-wrapper">
              <button @click="frankfurtCodePhase = 0">OK</button>
            </div>
          </div>
        </div>

        <component
          :is="`char-${me.int_character}`"
          :color="me.str_color"
          :size="'medium'"
          class="player-character"
        />
      </div>
    </transition>

    <transition name="slide-down">
      <platform-help :color="'green'" v-if="showHelp" />
    </transition>

    <help-btn :helpShown="showHelp" @showHelp="showHelp = !showHelp" />

    <p class="copyright">Copyright © 2023 Goethe-Institut</p>
  </div>
</template>

<script>
import { apiDBTryTreasure } from "@/utils/goethe-apis.js";
import Char1 from "@/components/characters/Char1.vue";
import Char2 from "@/components/characters/Char2.vue";
import Char3 from "@/components/characters/Char3.vue";
import Char4 from "@/components/characters/Char4.vue";
import Char5 from "@/components/characters/Char5.vue";
import Char6 from "@/components/characters/Char6.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import PlatformHelp from "@/components/utils/PlatformHelp.vue";
import Pin from "@/components/utils/Pin.vue";
import EventBus from "@/utils/event-bus";
import distances from "@/enums/distances.enum.js";

export default {
  name: "AutobahnMap",
  props: ["visitedStations", "canAct"],
  components: {
    Pin,
    Char1,
    Char2,
    Char3,
    Char4,
    Char5,
    Char6,
    HelpBtn,
    PlatformHelp,
  },
  data() {
    return {
      trees: [
        {
          left: "15%",
          top: "30%",
        },
        {
          left: "10%",
          top: "50%",
        },
        {
          left: "20%",
          top: "75%",
        },
        {
          left: "80%",
          top: "35%",
        },
        {
          left: "75%",
          top: "60%",
        },
      ],
      cities: [
        {
          name: "rostock",
          done: false,
        },
        {
          name: "koln",
          done: false,
        },
        {
          name: "dresden",
          done: false,
        },
        {
          name: "stuttgart",
          done: false,
        },
        {
          name: "mainz",
          done: false,
        },
        {
          name: "eisenach",
          done: false,
        },
        {
          name: "hamburg",
          done: false,
        },
        {
          name: "munchen",
          done: false,
        },
        {
          name: "weimar",
          done: false,
        },
        {
          name: "berlin",
          done: false,
        },
        {
          name: "munster",
          done: false,
        },
        {
          name: "bremen",
          done: false,
        },
        {
          name: "bonn",
          done: false,
        },
        {
          name: "frankfurt",
          done: false,
        },
        {
          name: "nurnberg",
          done: false,
        },
      ],
      me: {},
      group: {},
      currentSelectedPin: null,
      currentSelectedCity: null,
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 0,
      distances: distances,
      code: "",
      correctCode: false,
      frankfurtCodePhase: 0,
      freshGift: false,
      levelSeconds: this.time,
      showHelp: false,
    };
  },
  watch: {
    gamesDone(val) {
      if (val) {
        setTimeout(() => {
          this.frankfurtCodePhase = 1;
        }, 5000);
      }
    },
  },
  created() {
    this.me = JSON.parse(localStorage.getItem("user"));
    this.group = JSON.parse(localStorage.getItem("group"));
    this.updateStations();
  },
  mounted() {
    const that = this;
    EventBus.$on("pinSelected", function (city) {
      that.currentSelectedCity = city;
      that.currentSelectedPin = `id-pin-${city}`;
      that.drawFlightPath();
    });
  },
  methods: {
    tryCode() {
      if (!this.code.length) {
        return;
      }
      const data = {
        group_id: this.group.id,
        code: this.code,
      };
      const token = localStorage.getItem("accessToken");
      apiDBTryTreasure(data, token)
        .then((res) => {
          if (res.status == 200) {
            this.correctCode = res.data.code;
            this.frankfurtCodePhase += 1;
            if (res.data.code && res.data.fresh) {
              this.freshGift = true;
              this.$emit("frankfurtCodeCorrect");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateStations() {
      for (let [index, city] of this.cities.entries()) {
        if (this.visitedStations.includes(city.name)) {
          this.cities[index].done = true;
        }
      }
    },
    drawFlightPath() {
      if (this.lastStation && this.currentSelectedPin) {
        const lastPin = document.getElementById(`id-pin-${this.lastStation}`);
        const currentPin = document.getElementById(this.currentSelectedPin);
        this.x1 = lastPin.offsetLeft + lastPin.offsetWidth / 2;
        this.y1 = lastPin.offsetTop + lastPin.offsetHeight / 2;
        this.x2 = currentPin.offsetLeft + currentPin.offsetWidth / 2;
        this.y2 = currentPin.offsetTop + currentPin.offsetHeight / 2;
      }
    },
  },
  computed: {
    lastStation() {
      if (this.visitedStations.length) {
        return this.visitedStations.at(-1);
      }
      return null;
    },
    stationDistances() {
      if (this.lastStation && this.currentSelectedCity) {
        return this.distances[this.currentSelectedCity][this.lastStation];
      }
      return 0;
    },
    gamesDone() {
      for (let city of this.cities) {
        if (!city.done) {
          return false;
        }
      }
      return true;
    },
  },
  beforeDestroy() {
    this.showHelp = false;
  },
};
</script>

<style scoped lang="scss">
.map-level {
  background: #98c23d;
  position: relative;
  overflow: hidden;
  .sea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .tree {
    position: absolute;
    height: 60px;
  }
  .map-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 85%;
    width: auto;
    transform: translate(-50%, -50%);
    .map {
      height: 100%;
    }
  }
  .copyright {
    position: absolute;
    font-family: "GoetheFFClan", sans-serif;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 0.6rem;
    color: rgb(68, 67, 67);
    margin: 0;
  }
  .flight-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .km-display {
    position: absolute;
    top: 20px;
    left: 20%;
    padding: 5px 10px;
    background: #fff;
    border-radius: 10px;
    border: 2px solid #000;
    box-shadow: -0.3rem 0.2rem #000;
    font-family: "GoetheFFClan", sans-serif;
    font-size: 0.9rem;
  }
  .ask-frankfurt {
    position: absolute;
    bottom: 20px;
    left: 20px;
    height: 50%;
    width: 60%;
    max-width: 350px;
    .frankfurt-code-dialog {
      position: absolute;
      top: 0;
      right: 0;
      height: 80%;
      width: 80%;
      background: #fff;
      border-radius: 10px;
      border: 2px solid #000;
      box-shadow: -0.4rem 0.3rem #000;
      .title {
        font-family: "TrashHand", sans-serif;
        font-size: 1.1rem;
        margin: 0.8rem 0.5rem;
      }
      .question {
        font-family: "GoetheFFClan", sans-serif;
        font-size: 0.75rem;
        margin: 0.8rem 0.5rem;
        a {
          color: #ed6f23;
          font-family: "GoetheFFClanBold", sans-serif;
        }
      }
      .input-wrapper {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        input {
          border-radius: 5px;
          border: 2px solid #000;
          box-shadow: -0.2rem 0.1rem #000;
          padding: 10px;
          font-family: "GoetheFFClan", sans-serif;
        }
        button {
          background: #fff;
          border-radius: 5px;
          border: 2px solid #000;
          box-shadow: -0.2rem 0.1rem #000;
          padding: 10px 12px;
          font-family: "GoetheFFClan", sans-serif;
          cursor: pointer;
        }
      }
      .btn-wrapper {
        position: absolute;
        bottom: 10px;
        right: 10px;
        button {
          margin-left: 1rem;
          background: #fff;
          border-radius: 10px;
          border: 2px solid #000;
          box-shadow: -0.2rem 0.1rem #000;
          padding: 2px 12px;
          font-family: "GoetheFFClan", sans-serif;
          cursor: pointer;
        }
      }
    }
    .player-character {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

@media (min-width: 1024px) {
  .map-level {
    .km-display {
      font-size: 1.1rem;
    }
    .ask-frankfurt {
      height: 35%;
      width: 60%;
      max-width: 500px;
      bottom: 20px;
      left: 50px;
      .frankfurt-code-dialog {
        height: 60%;
        width: 80%;
        .title {
          font-size: 1.4rem;
        }
        .question {
          font-size: 1rem;
        }
        .input-wrapper {
          input {
            padding: 12px;
          }
          button {
            padding: 12px 15px;
          }
        }
        .btn-wrapper {
          button {
            padding: 3px 15px;
            font-size: 1rem;
          }
        }
      }
    }
    .tree {
      height: 100px;
    }
  }
}
</style>
