<template>
  <div class="level koln-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <div class="ingredients-wrapper">
          <div
            class="ingredient"
            v-for="index in 4"
            :key="index"
            @click="pickIngredient(stepData[phase][index - 1])"
          >
            <img
              :src="
                require(`@/assets/img/games/koln/${
                  stepData[phase][index - 1].item
                }.svg`)
              "
            />
            <p class="german-name">
              {{ stepData[phase][index - 1].german }}
            </p>
            <p class="name-translation">
              {{ $t(`koln.${stepData[phase][index - 1].item}`) }}
            </p>
            <p class="type" :class="stepData[phase][index - 1].val">
              {{ $t(`koln.${stepData[phase][index - 1].val}`) }}
            </p>
          </div>
        </div>
        <p class="phase-counter">{{ phase + 1 }} / 5</p>
      </div>
      <div class="lower-panel text-shadow-extra-thin-black">
        <div class="game-info-display">
          <div class="flask">
            <flask
              class="flask-svg"
              :color="flaskColor"
              :darkerColor="flaskDarkerColor"
            />
          </div>
          <div class="smell-o-meter-wrapper">
            <div class="smell-o-meter">
              <div class="smell-o-meter-inner">
                <img
                  v-if="smell != null"
                  src="@/assets/img/games/koln/arrow.svg"
                  class="arrow"
                  :style="`left: ${50 + smell}%`"
                />
              </div>
            </div>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="gameData.points"
          @timeoutLevel="timeoutLevel"
        />
      </div>

      <waiting-players-notification v-if="lockEnd" />
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="4" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import WaitingPlayersNotification from "@/components/utils/WaitingPlayersNotification.vue";
import Flask from "@/components/utils/Flask.vue";
import kolnEnum from "@/enums/koln.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    WaitingPlayersNotification,
    Flask,
    PlayerQtt,
  },
  name: "KolnLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "koln",
      color: "purple",
      step: null,
      waitingStart: false,
      showHelp: false,
      stepData: kolnEnum.phases,
      phase: 0,
      ingredientsAdded: 0,
      lockEnd: false,
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }

      const ingredientsAdded =
        this.gameData.citric + this.gameData.floral + this.gameData.neutral;
      if (ingredientsAdded > this.ingredientsAdded) {
        const titleText = this.$t(
          `koln.notifications.${this.gameData.latest_ingredient.type}`
        );
        const subtitleText = this.$t(
          `koln.${this.gameData.latest_ingredient.name}`
        );
        this.$emit("notify", titleText, subtitleText, "success");
      }
      this.ingredientsAdded = ingredientsAdded;

      if (
        !this.gameData.players[this.me].phase ||
        this.gameData.players[this.me].phase <= 4
      ) {
        this.phase = this.gameData.players[this.me].phase || 0;
      } else {
        this.lockEnd = true;
      }
    },
    pickIngredient(ingredient) {
      if (!this.canAct || this.lockEnd) {
        return;
      }
      const updateData = {
        ingredient_name: ingredient.item,
        ingredient_type: ingredient.val,
      };
      this.$emit("updateGame", updateData);
    },
  },
  computed: {
    smell() {
      const citric = this.gameData.citric || 0;
      const floral = this.gameData.floral || 0;
      const diff = Math.abs(citric - floral);

      if (citric == 0 && floral == 0) {
        return null;
      }

      if (diff == 0) {
        return 0;
      }

      if (citric > floral) {
        return diff * 2.5;
      } else {
        return diff * -2.5;
      }
    },
    flaskColor() {
      if (this.smell == null) {
        return "white";
      } else if (-50 <= this.smell && this.smell < -35) {
        return "full-floral";
      } else if (-35 <= this.smell && this.smell <= -16) {
        return "floral";
      } else if (-15 <= this.smell && this.smell <= 15) {
        return "middle";
      } else if (16 <= this.smell && this.smell <= 35) {
        return "citric";
      } else if (36 <= this.smell && this.smell <= 50) {
        return "full-citric";
      } else {
        return "white";
      }
    },
    flaskDarkerColor() {
      if (this.smell == null) {
        return "white";
      } else if (-50 <= this.smell && this.smell < -35) {
        return "full-floral-darker";
      } else if (-35 <= this.smell && this.smell <= -16) {
        return "floral-darker";
      } else if (-15 <= this.smell && this.smell <= 15) {
        return "middle-darker";
      } else if (16 <= this.smell && this.smell <= 35) {
        return "citric-darker";
      } else if (36 <= this.smell && this.smell <= 50) {
        return "full-citric-darker";
      } else {
        return "white";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.koln-level {
  background: #761e65;
}

.game-panel {
  display: grid;
  place-items: center;
  position: relative;
  //game exclusive
  .ingredients-wrapper {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .ingredient {
      width: 23%;
      text-align: center;
      font-family: "GoetheFFClan";
      cursor: pointer;
      img {
        height: 60px;
      }
      .german-name {
        font-weight: 700;
        font-size: 0.8rem;
        margin: 0;
      }
      .name-translation {
        font-size: 0.7rem;
        margin: 0;
      }
      .type {
        font-size: 0.5rem;
        margin: 0;
      }
      .floral {
        color: #761e65;
      }
      .citric {
        color: #ed6f23;
      }
    }
  }
  .phase-counter {
    position: absolute;
    bottom: 0;
    right: 2.5%;
    font-family: "TrashHand";
    font-size: 1.3rem;
    font-weight: 600;
  }
}

.game-info-display {
  display: flex;
  flex-direction: row;
  //game exclusive
  .flask {
    height: 100%;
    width: 25%;
    position: relative;
    transition: 0.3s;
    .flask-svg {
      position: absolute;
      height: 140%;
      right: 0;
      bottom: 0;
    }
  }
  .smell-o-meter-wrapper {
    height: 100%;
    width: 75%;
    .smell-o-meter {
      margin: 0.8rem auto 0 auto;
      height: 15px;
      width: 80%;
      border-radius: 50px;
      border: 2px solid #4dc1ec;
      background: #fff;
      display: grid;
      place-items: center;
      .smell-o-meter-inner {
        background: linear-gradient(
          90deg,
          #761e65 0%,
          #4dc1ec 50%,
          #f36f21 100%
        );
        height: 6px;
        width: 95%;
        border-radius: 50px;
        position: relative;
        .arrow {
          position: absolute;
          top: 2px;
          left: 50%;
          transform: translate(-50%, 0);
          height: 20px;
          transition: 0.3s;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .game-panel {
    .ingredients-wrapper {
      .ingredient {
        img {
          height: 100px;
        }
        .german-name,
        .name-translation {
          margin: 0.5rem 0 0 0;
          font-size: 1.1rem;
        }
        .type {
          font-size: 0.9rem;
          margin: 0.5rem 0 0 0;
        }
      }
    }
  }

  .lower-panel {
    .game-info-display {
      .flask {
        .flask-svg {
          right: -20px;
        }
      }
      .smell-o-meter-wrapper {
        .smell-o-meter {
          margin: 2.5rem auto 0 auto;
          height: 25px;
          .smell-o-meter-inner {
            height: 10px;
            .arrow {
              top: 5px;
              height: 25px;
            }
          }
        }
        .text-feedback {
          margin: 1rem 0 0 0;
          font-size: 1.3rem;
        }
      }
    }
  }
}
</style>
