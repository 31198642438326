<template>
  <div class="how-to">
    <h1 class="text-shadow-thin-white">{{ $t("rostock.howToPlay") }}</h1>
    <p>{{ $t("rostock.howP1") }}</p>
    <p>
      <span class="highlight text-shadow-extra-thin-white">
        {{ $t("rostock.howP2Highlight") }}
      </span>
      {{ $t("rostock.howP2") }}
    </p>
    <p>{{ $t("rostock.howP3") }}</p>
  </div>
</template>
<script>
export default {
  name: "RostockHowTo",
};
</script>
<style lang="scss">
.how-to {
  width: 100%;
  height: 100%;
  h1 {
    font-family: "TrashHand", sans-serif;
    text-align: center;
    font-size: 1.2rem;
    margin: 0 0 1rem 0;
  }
  p {
    font-family: "GoetheFFClan", sans-serif;
    font-size: 0.75rem;
  }
  .dark-text {
    color: #fff;
  }
  .highlight {
    font-weight: 700;
    color: #0a4879;
    letter-spacing: 1px;
  }
  .dark-highlight {
    font-weight: 700;
    color: #ffda03;
    font-family: "GoetheFFClanBold", sans-serif;
  }
}

@media (min-width: 1024px) {
  .how-to {
    h1 {
      font-size: 2.4rem;
      margin: 3rem 0;
    }
    p {
      font-size: 1.4rem;
      margin: 2rem 0;
    }
  }
}
</style>
