<template>
  <section class="admin-login-session">
    <div>
      <h1>Login</h1>
      <form @submit.prevent="login">
        <input
          type="text"
          v-model="username"
          placeholder="Username"
          class="text-input"
          :disabled="loading"
        />
        <br /><br />
        <input
          type="password"
          v-model="password"
          placeholder="Password"
          class="text-input"
          :disabled="loading"
        />
        <br /><br />
        <input
          type="submit"
          value="Login"
          class="btn"
          :disabled="!username || !password || loading"
        />
      </form>
      <p v-if="loading" class="loading-message">Loading...</p>
      <p v-if="showMessage" class="error-message">Invalid credentials!</p>
    </div>
  </section>
</template>

<script>
import { apiDBAdmLogin } from "@/utils/goethe-apis.js";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      showMessage: false,
      loading: false,
    };
  },
  methods: {
    login() {
      this.loading = true;
      const data = {
        str_username: this.username,
        str_password: this.password,
      };
      apiDBAdmLogin(data)
        .then((res) => {
          if (res.data.status == 200) {
            localStorage.setItem("admAccessToken", res.data.access_token);
            this.$router.push("/create-session");
          } else if (res.data.status == 401) {
            this.showMessage = true;
            this.username = "";
            this.password = "";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-login-session {
  background: #d4ded5;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  text-align: center;
  font-family: "GoetheFFClan", sans-serif;
  .text-input,
  .btn {
    border-radius: 5px;
    border: 1px solid #6a7a83;
    padding: 8px;
    text-align: center;
    width: 250px;
    box-sizing: border-box;
    transition: 0.3s;
  }
  .text-input {
    background: #fff;
  }
  .text-input:hover {
    background: #dbdbdb;
  }
  .btn {
    background: #4dc1ec;
    cursor: pointer;
    font-weight: 700;
    color: #fff;
  }
  .btn:hover {
    background: #0a4879;
  }
  .btn:disabled {
    background: #6a7a83;
    cursor: default;
  }
  .loading-message {
    color: #4dc1ec;
    font-weight: 700;
  }
  .error-message {
    color: #e50019;
    font-weight: 700;
  }
}
</style>
