<template>
  <div class="utils-display">
    <div
      class="util-wrapper shadowed"
      :class="{ tutorial: tutorialStep === 'time' }"
      @click="handleClock"
    >
      <img src="@/assets/img/utils/clock.svg" class="util-img" />
      <transition name="bounce">
        <div class="clock" v-if="showClock">
          <p>{{ formattedSecondsLeft }}</p>
        </div>
      </transition>
      <transition name="bounce">
        <div class="warning" v-if="showWarning">
          <p>{{ $t("utils.remainingTime") }}</p>
        </div>
      </transition>
    </div>

    <div
      class="util-wrapper shadowed"
      :class="{ tutorial: tutorialStep === 'chat' }"
      @click="openMsgs"
    >
      <img src="@/assets/img/utils/messages.svg" class="util-img" />
      <span class="new-messages" v-if="newMessages > 0">
        {{ newMessagesDisplay }}
      </span>
    </div>

    <div
      class="util-wrapper shadowed"
      :class="{ tutorial: tutorialStep === 'passport' }"
      @click="openPassport"
    >
      <img src="@/assets/img/utils/passport.svg" class="util-img" />
    </div>
  </div>
</template>

<script>
export default {
  name: "UtilsDisplay",
  props: ["newMessages", "end", "tutorialStep"],
  data() {
    return {
      showClock: false,
      secondsLeft: null,
      timer: null,
      showWarning: false,
    };
  },
  watch: {
    end(val) {
      if (val > 0) {
        this.secondsLeft = val;
        this.countDownGame();
      }
    },
    secondsLeft(val) {
      if (val == 300) {
        this.showWarning = true;
        this.showClock = false;
      }
    },
  },
  methods: {
    handleClock() {
      this.showWarning
        ? (this.showWarning = false)
        : (this.showClock = !this.showClock);
      if (this.tutorialStep === "time") {
        this.$emit("nextTutorial", "chat");
      }
    },
    countDownGame() {
      if (this.secondsLeft && this.secondsLeft > 0) {
        setTimeout(() => {
          this.secondsLeft -= 1;
          if (this.secondsLeft > 0) {
            this.countDownGame();
          }
        }, 1000);
      }
    },
    openMsgs() {
      if (this.tutorialStep === "chat") {
        this.$emit("nextTutorial", "closeChat");
      }
      this.$emit("msgs");
    },
    openPassport() {
      if (this.tutorialStep === "passport") {
        this.$emit("nextTutorial", "closePassport");
      }
      this.$emit("passport");
    },
  },
  computed: {
    formattedSecondsLeft() {
      if (this.secondsLeft == 0) {
        return "00:00:00";
      }
      var sec_num = parseInt(this.secondsLeft, 10);
      var hours = ("0" + Math.floor(sec_num / 3600)).slice(-2);
      var minutes = ("0" + (Math.floor(sec_num / 60) % 60)).slice(-2);
      var seconds = ("0" + (sec_num % 60)).slice(-2);
      return `${hours}:${minutes}:${seconds}`;
    },
    newMessagesDisplay() {
      return Math.min(this.newMessages, 9);
    },
  },
};
</script>

<style scoped lang="scss">
.utils-display {
  position: absolute;
  right: 20px;
  .tutorial {
    z-index: 10;
    animation: shake 5s;
    animation-iteration-count: infinite;
  }
  .util-wrapper {
    background: #fff;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    margin: 1.5rem 0;
    display: grid;
    position: relative;
    place-items: center;
    cursor: pointer;
    .util-img {
      height: 70%;
      width: 70%;
    }
    .clock {
      position: absolute;
      top: -20px;
      left: -60px;
      width: 65px;
      padding: 5px;
      border-radius: 10px;
      border: 2px solid #000;
      background: #fff;
      p {
        text-align: center;
        font-weight: 600;
        font-family: "GoetheFFClan", sans-serif;
        font-size: 0.7rem;
      }
    }
    .warning {
      position: absolute;
      top: -20px;
      left: -110px;
      width: 120px;
      padding: 5px;
      border-radius: 10px;
      border: 2px solid #000;
      background: #fff;
      p {
        text-align: center;
        font-weight: 600;
        font-family: "GoetheFFClan", sans-serif;
        font-size: 0.7rem;
      }
    }
    .new-messages {
      position: absolute;
      top: -6px;
      right: -6px;
      background: #e50019;
      height: 16px;
      width: 16px;
      color: #fff;
      font-family: "GoetheFFClan", sans-serif;
      font-size: 0.8rem;
      font-weight: 700;
      text-align: center;
      border-radius: 50%;
    }
  }
}

@media (min-width: 1024px) {
  .utils-display {
    right: 25px;
    .util-wrapper {
      height: 80px;
      width: 80px;
      margin: 4rem 0;
      .util-img {
        height: 65%;
        width: 65%;
      }
      .clock {
        position: absolute;
        top: -30px;
        left: -80px;
        width: 90px;
        padding: 5px;
        p {
          font-size: 1rem;
        }
      }
      .warning {
        top: -30px;
        left: -150px;
        width: 160px;
        padding: 5px;
        p {
          font-size: 1rem;
        }
      }
      .new-messages {
        height: 21px;
        width: 21px;
        font-size: 1rem;
      }
    }
  }
}
</style>
