<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="char-svg"
    :class="`char-size-${size}`"
    viewBox="0 0 79.915 88.231"
  >
    <g
      id="Group_608"
      data-name="Group 608"
      transform="translate(-1111.079 -459.855)"
    >
      <path
        id="Path_533"
        data-name="Path 533"
        d="M774.884,93.545c-3.616-4.863-8.967-7.945-15.071-9.776.163-2.437.213-4.881.216-7.343a4.5,4.5,0,0,0-4.468-4.468c-3.064.071-6.306.767-8.372,3.223a9.7,9.7,0,0,0-2.175,6.37c-1.317-.042-2.636-.061-3.951-.04a56.156,56.156,0,0,0-.767-6.274c-.569-3.089-4.159-3.752-6.562-2.67-4.445,2-6.2,5.916-6.562,10.193-1.852.3-3.621.632-5.272.989-23.106,5.023-14.176,31.2-15.057,47.99a4.517,4.517,0,0,0,2.6,4.039,4.778,4.778,0,0,0,1.313,1.522,14.249,14.249,0,0,0,5.891,2.55,98.9,98.9,0,0,0,.946,14.013c.623,4.057,6.77,2.327,6.154-1.7a82.208,82.208,0,0,1-.733-12.153,18.931,18.931,0,0,0,5.364-1.575c.915,3.729,2.04,7.409,2.83,11.172.846,4.026,7,2.318,6.155-1.7-.962-4.58-2.442-9.033-3.4-13.615a3.4,3.4,0,0,0-.119-.375c4.226-6.534-2.36-13.959.786-19.862,2.573-4.824,12.359-5.827,16.7-2.762,6.792,4.8-1.276,14.563,1.666,21.293a7.766,7.766,0,0,0,4.091,3.559c-.683,3.637-1.329,7.281-2.045,10.912-.794,4.02,5.356,5.733,6.154,1.7.716-3.619,1.358-7.25,2.04-10.874a32.575,32.575,0,0,0,6.168.341,48.6,48.6,0,0,0,.955,10.533c.916,4.011,7.07,2.309,6.155-1.7a45.721,45.721,0,0,1-.738-10.323C786.859,130.242,780.479,101.062,774.884,93.545Z"
        transform="translate(407.104 389.899)"
        :fill="color"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="4"
      />
      <g id="Group_607" data-name="Group 607">
        <path
          id="Path_534"
          data-name="Path 534"
          d="M740.128,92.489a5.722,5.722,0,0,1-8.356.425c-1.811-1.672-4.526,1.03-2.707,2.707,3.847,3.551,10.287,3.809,13.771-.425C744.39,93.305,741.7,90.58,740.128,92.489Z"
          transform="translate(413.353 395.396)"
          fill="#1c1c1c"
          stroke="#000"
          stroke-width="1"
        />
        <path
          id="Path_535"
          data-name="Path 535"
          d="M725.537,86.071c-3.293,0-3.293,5.106,0,5.106S728.83,86.071,725.537,86.071Z"
          transform="translate(411.861 393.801)"
          fill="#1c1c1c"
          stroke="#000"
          stroke-width="1"
        />
        <path
          id="Path_536"
          data-name="Path 536"
          d="M744.537,85.738c-3.293,0-3.293,5.106,0,5.106S747.83,85.738,744.537,85.738Z"
          transform="translate(417.114 393.709)"
          fill="#1c1c1c"
          stroke="#000"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Char6",
  props: ["color", "size"],
};
</script>
