<template>
  <div class="home">
    <home-desktop
      v-if="deviceType == 'desktop'"
      @login="login"
      :message="message"
    />
    <home-mobile v-else @login="login" :message="message" />
  </div>
</template>

<script>
import { apiDBLogin } from "@/utils/goethe-apis.js";
import HomeDesktop from "@/components/home/HomeDesktop.vue";
import HomeMobile from "@/components/home/HomeMobile.vue";
export default {
  name: "Home",
  data() {
    return {
      windowWidth: 0,
      message: null,
    };
  },
  components: {
    HomeDesktop,
    HomeMobile,
  },
  created() {
    this.windowWidth = window.screen.width;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.screen.width;
    },
    handleTutorial() {
      const tutorialStep = localStorage.getItem("tutorialStep");
      if (!tutorialStep) {
        //voice -> music -> time -> chat -> closeChat -> passport -> done
        localStorage.setItem("tutorialStep", "voice");
      }
    },
    randomIntFromInterval(min = 1, max = 100000000) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    login(token) {
      const data = {
        str_autobahn_token: token,
      };
      apiDBLogin(data)
        .then((res) => {
          if (res.status == 200) {
            if (res.data.status == "not-started") {
              this.message = this.$t(`home.notStarted`);
              return;
            }
            if (res.data.status == "ended") {
              this.message = this.$t(`home.alreadyFinished`);
              return;
            }
            const data = res.data;
            localStorage.setItem("accessToken", data.access_token);
            localStorage.setItem("user", JSON.stringify(data.user));
            localStorage.setItem("group", JSON.stringify(data.group));
            if (!localStorage.getItem("clientSessionId")) {
              localStorage.setItem(
                "clientSessionId",
                this.randomIntFromInterval()
              );
            }
            this.handleTutorial();
            if (data.user.bl_character_created) {
              this.$router.push("/game");
            } else {
              this.$router.push("/character");
            }
          } else {
            this.message = "Token inválido!";
          }
        })
        .catch((error) => {
          this.message = "Ocorreu um problema! Tente novamente mais tarde.";
          console.log(error);
        });
    },
  },
  computed: {
    deviceType() {
      return this.windowWidth <= 810 ? "mobile" : "desktop";
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  overflow-x: hidden;
}
</style>
