<template>
  <div class="level munchen-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <div class="cards-wrapper">
          <div
            v-for="(card, index) in munchenData"
            :key="index"
            class="card-space"
          >
            <div
              class="card"
              @click="flipSingleCard(card.card)"
              :ref="card.card"
            >
              <div class="card-inner">
                <div class="card-front">
                  <img
                    :src="
                      require(`@/assets/img/games/munchen/${card.team}.jpg`)
                    "
                  />
                </div>
                <div class="card-back"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lower-panel">
        <div class="game-info-display">
          <div class="content">
            <div class="team-wrapper">
              <p class="team" v-if="currentFlippedTeam">
                {{ teamNames[currentFlippedTeam] }}
              </p>
              <p class="placeholder" v-else>{{ $t(`munchen.pickCard`) }}</p>
            </div>
            <button
              class="hint-btn thinly-shadowed"
              v-if="hints > 0"
              @click="showHint"
            >
              {{ $t(`munchen.hint`) }}
            </button>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="gameData.points"
          @timeoutLevel="timeoutLevel"
        />
      </div>
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="4" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import munchenEnum from "@/enums/munchen.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    PlayerQtt,
  },
  name: "MunchenLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "munchen",
      color: "green",
      step: null,
      waitingStart: false,
      showHelp: false,
      munchenData: munchenEnum.cards,
      teamNames: munchenEnum.teams,
      currentFlippedCard: null,
      currentFlippedTeam: null,
      currentFlippedIndex: null,
      cardPickCooldown: true,
      remainingTries: 5000,
      hints: 1,
      foundTeams: [],
      initialCardShow: false,
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.remainingTries > this.gameData.tries && !this.isFirstLoad) {
        this.$emit("notify", this.$t("munchen.wrongTry"), null, "failure");
      }
      this.remainingTries = this.gameData.tries;
      this.hints = this.gameData.players[this.me].hint ? 0 : 1;

      if (!this.waitingStart && !this.initialCardShow) {
        this.step = "game";
        this.waitingStart = false;
        this.initialCardShow = true;
        setTimeout(() => {
          this.cardPickCooldown = false;
          this.flipAll(3000);
        }, 1000);
        return;
      }

      for (let team of Object.keys(this.teamNames)) {
        if (this.gameData[team]) {
          const that = this;
          this.munchenData = this.munchenData.map(function (card) {
            if (card.team == team) {
              card.found = true;
              card.flipped = true;
              that.$refs[card.card][0].classList.value = "card flipped";
              if (that.currentFlippedTeam == team) {
                that.currentFlippedIndex = null;
                that.currentFlippedTeam = null;
                that.currentFlippedIndex = null;
              }
            }
            return card;
          });
          if (!this.foundTeams.includes(team)) {
            this.foundTeams.push(team);
            if (!this.isFirstLoad) {
              this.$emit(
                "notify",
                this.$t("munchen.foundNotification"),
                this.teamNames[team],
                "success"
              );
            }
          }
        }
      }
      this.isFirstLoad = false;
    },
    flipSingleCard(clickedCard) {
      if (!this.canAct || this.cardPickCooldown) {
        return;
      }

      const index = this.munchenData.findIndex(function (card) {
        return card.card == clickedCard;
      });

      if (this.munchenData[index].flipped || this.munchenData[index].found) {
        return;
      }

      this.munchenData[index].flipped = true;
      this.$refs[clickedCard][0].classList.value = "card flipped";
      const team = this.munchenData[index].team;

      if (!this.currentFlippedCard || !this.currentFlippedTeam) {
        this.currentFlippedCard = clickedCard;
        this.currentFlippedTeam = team;
        this.currentFlippedIndex = index;
        return;
      }

      this.cardPickCooldown = true;
      setTimeout(() => {
        this.checkCards(clickedCard, team, index);
      }, 1000);
    },
    checkCards(card, team, index) {
      this.cardPickCooldown = false;
      let updateData = {};
      if (this.currentFlippedTeam == team) {
        updateData[team] = true;
        this.$emit("updateGame", updateData);
      } else {
        updateData["incorrect"] = true;
        this.$emit("updateGame", updateData);
        this.$refs[card][0].classList.value = "card";
        this.$refs[this.currentFlippedCard][0].classList.value = "card";
        this.munchenData[index].flipped = false;
        this.munchenData[this.currentFlippedIndex].flipped = false;
      }

      this.currentFlippedCard = null;
      this.currentFlippedTeam = null;
      this.currentFlippedIndex = null;
    },
    flipAll(time) {
      this.cardPickCooldown = true;
      for (let card of this.munchenData) {
        this.$refs[card.card][0].classList.value = "card flipped";
      }

      setTimeout(() => {
        this.unflipAll();
      }, time);
    },
    unflipAll() {
      for (const [index, card] of this.munchenData.entries()) {
        if (this.munchenData[index].found == false) {
          this.$refs[card.card][0].classList.value = "card";
        }
      }
      this.cardPickCooldown = false;
    },
    showHint() {
      if (this.cardPickCooldown || !this.canAct) {
        return;
      }
      this.flipAll(2000);
      this.hints -= 1;
      let updateData = {};
      updateData["hint"] = true;
      this.$emit("updateGame", updateData);
    },
  },
};
</script>

<style scoped lang="scss">
.munchen-level {
  background: #98c23d;
}

.game-panel {
  display: grid;
  place-items: center;
  //game exclusive
  .cards-wrapper {
    height: 95%;
    width: 95%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .card-space {
      width: 25%;
      height: 33.3%;
      display: grid;
      place-items: center;
      .card {
        width: 55px;
        height: 55px;
        perspective: 1000px;
        background-color: transparent;
        .card-inner {
          position: relative;
          width: 100%;
          height: 100%;
          text-align: center;
          transition: transform 0.3s;
          transform-style: preserve-3d;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        }
        .card-front,
        .card-back {
          position: absolute;
          width: 100%;
          height: 100%;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          box-sizing: border-box;
          border: 2px solid #fff;
          border-radius: 5px;
          display: grid;
          place-items: center;
        }
        .card-front {
          background: #e9e9e9;
          transform: rotateY(180deg);
          img {
            width: 85%;
            height: auto;
          }
        }
        .card-back {
          background: #98c23d;
          cursor: pointer;
        }
      }
    }
  }
}

.flipped .card-inner {
  transform: rotateY(180deg);
}

.game-info-display {
  display: grid;
  place-items: center;
  //game exclusive
  .content {
    width: 90%;
    height: 90%;
    display: grid;
    place-items: center;
    position: relative;
    .team-wrapper {
      text-align: center;
      p {
        margin: 0;
        font-size: 1rem;
        font-family: "GoetheFFClan", sans-serif;
      }
      .team {
        font-family: "GoetheFFClanBold", sans-serif;
      }
    }
    .hint-btn {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      background: #ed6f23;
      font-family: "GoetheFFClan", sans-serif;
      font-weight: 700;
      border-radius: 5px;
      padding: 5px 10px;
      color: #fff;
      cursor: pointer;
    }
  }
}

@media (min-width: 1024px) {
  .game-panel {
    .cards-wrapper {
      .card-space {
        .card {
          width: 120px;
          height: 120px;
        }
      }
    }
  }

  .game-info-display {
    .content {
      .tries-wrapper {
        p {
          font-size: 1.5rem;
        }
      }
      .team-wrapper {
        p {
          font-size: 2rem;
        }
      }
      .hint-btn {
        font-size: 1.5rem;
        padding: 5px 14px;
      }
    }
  }
}
</style>
