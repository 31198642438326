<template>
  <div class="tutorial-messages thinly-shadowed">
    <p>{{ $t(`tutorial.${step}`) }}</p>
  </div>
</template>
<script>
export default {
  name: "TutorialMessages",
  props: ["step"],
};
</script>
<style scoped lang="scss">
.tutorial-messages {
  position: absolute;
  top: 20px;
  left: 20px;
  background: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  max-width: 130px;
  z-index: 11;
  p {
    font-family: "GoetheFFClan";
    font-size: 0.8rem;
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .tutorial-messages {
    top: 30px;
    left: 30px;
    padding: 10px 15px;
    max-width: 250px;
    p {
      font-size: 1.2rem;
    }
  }
}
</style>
