<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="char-svg"
    :class="`char-size-${size}`"
    viewBox="0 0 54.718 108.28"
  >
    <g
      id="Group_611"
      data-name="Group 611"
      transform="translate(-596.48 -446.863)"
    >
      <path
        id="Path_512"
        data-name="Path 512"
        d="M302.509,87.887c1.693-8.393,8.728-12.746,8.194-21.928-.381-6.574-3.844-13.9-10.3-16.418-10.982-4.288-24.492,2.813-30.967,11.243a43.84,43.84,0,0,0-8.875,24.568c-.273,2.4-.429,4.789-.511,7.106-.523,14.616,3.673,33.623,16.432,40.83a58.2,58.2,0,0,1,2.272,16.391c.031,3.745,5.843,3.748,5.811,0a68.307,68.307,0,0,0-1.456-13.943,26.76,26.76,0,0,0,7.386.415,18.774,18.774,0,0,0,1.951-.263c1.375,3.443,2.851,6.824,2.579,10.691-.265,3.741,5.549,3.716,5.811,0,.327-4.627-1.49-8.533-3.021-12.591a20.117,20.117,0,0,0,10.116-15.093,25.577,25.577,0,0,0,.206-2.841,8.459,8.459,0,0,1-.186-2.019C308.206,104.816,300.554,97.586,302.509,87.887Z"
        transform="translate(338.475 400.654)"
        :fill="color"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="4"
      />
      <g
        id="Group_605"
        data-name="Group 605"
        transform="translate(613.19 473.627)"
      >
        <path
          id="Path_513"
          data-name="Path 513"
          d="M274.142,66.307a3.324,3.324,0,0,0-2.2-.507,1.81,1.81,0,0,0-2.125.745c-.034.045-.057.1-.09.143a1.722,1.722,0,0,0-.861,1.976c.006.019.016.036.022.056a2.751,2.751,0,0,0,1.717,3.175,2.882,2.882,0,0,0,2.788-.715,5.217,5.217,0,0,0,1.547-2.9A1.787,1.787,0,0,0,274.142,66.307Z"
          transform="translate(-268.806 -65.687)"
          fill="#1c1c1c"
          stroke="#000"
          stroke-width="1"
        />
        <path
          id="Path_514"
          data-name="Path 514"
          d="M289.475,66.956a2.87,2.87,0,0,0-2.242-.421,2.114,2.114,0,0,0-.533.225,1.613,1.613,0,0,0-1.444.682,1.933,1.933,0,0,0-.728,1.405c.045-.582-.108.1-.18.292a3.987,3.987,0,0,0-.207,1.259,1.731,1.731,0,0,0,1.28,1.681,3.92,3.92,0,0,0,2.945-.093,5.848,5.848,0,0,0,1.735-2.645A1.778,1.778,0,0,0,289.475,66.956Z"
          transform="translate(-266.317 -65.561)"
          fill="#1c1c1c"
          stroke="#000"
          stroke-width="1"
        />
        <path
          id="Path_517"
          data-name="Path 517"
          d="M283.172,74.854a6.467,6.467,0,0,1-8.382,0c-1.659-1.51-4.132.948-2.465,2.465a10.027,10.027,0,0,0,13.313,0C287.3,75.8,284.831,73.344,283.172,74.854Z"
          transform="translate(-268.325 -64.276)"
          fill="#1c1c1c"
          stroke="#000"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Char3",
  props: ["color", "size"],
};
</script>
