<template>
  <div class="level hamburg-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <div class="tv-wrapper" v-if="currentStep < 8">
          <img src="@/assets/img/games/hamburg/tv.png" class="tv" />
          <iframe
            :src="stepData[currentStep].url"
            frameborder="0"
            class="video"
          ></iframe>
        </div>
        <div class="berlin-wall-wrapper" v-else>
          <p class="title">
            {{ $t("hamburg.berlinWallTitle") }}
          </p>
          <div class="options-wrapper">
            <button
              class="option"
              @click="pickBerlinWall(n)"
              v-for="n in 8"
              :key="n"
            >
              {{ n }}
            </button>
          </div>
        </div>
      </div>
      <div class="lower-panel text-shadow-extra-thin-black">
        <div class="game-info-display">
          <div class="option-holder" v-if="currentStep < 8">
            <div class="german" @click="chooseLang('german')">
              <div class="image-holder">
                <img src="@/assets/img/games/hamburg/german.svg" />
              </div>
              <div class="text-holder text-shadow-thin-white">
                <p>{{ $t("hamburg.german") }}</p>
              </div>
            </div>

            <div class="border" />

            <div class="english" @click="chooseLang('english')">
              <div class="text-holder text-shadow-thin-white">
                <p>{{ $t("hamburg.english") }}</p>
              </div>
              <div class="image-holder">
                <img src="@/assets/img/games/hamburg/english.svg" />
              </div>
            </div>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="pointsDisplay"
          @timeoutLevel="timeoutLevel"
        />
      </div>

      <waiting-players-notification v-if="lockEnd" />
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="1" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import WaitingPlayersNotification from "@/components/utils/WaitingPlayersNotification.vue";
import hamburgEnum from "@/enums/hamburg.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    WaitingPlayersNotification,
    PlayerQtt,
  },
  name: "HamburgLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "hamburg",
      color: "darkgray",
      step: null,
      waitingStart: false,
      showHelp: false,
      stepData: hamburgEnum,
      answers: [],
      currentStep: 0,
      lockEnd: false,
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }
      if (
        !this.gameData.players[this.me] ||
        !this.gameData.players[this.me].answers
      ) {
        return;
      }
      if (this.gameData.players[this.me].answers.length == 9) {
        this.lockEnd = true;
      }
      this.answers = this.gameData.players[this.me].answers;
      this.currentStep = this.gameData.players[this.me].answers.length;
    },
    chooseLang(lang) {
      if (!this.canAct || this.lockEnd) {
        return;
      }
      let titleText = `${this.currentStep + 1}. `;
      let context = "success";
      const correct = this.stepData[this.currentStep].correct == lang;
      if (correct) {
        titleText += this.$t("hamburg.right");
      } else {
        titleText += this.$t("hamburg.wrong");
        context = "failure";
      }
      let correctOption = this.stepData[this.currentStep].correct;
      let correctText = this.$t(`hamburg.${correctOption}`);
      const updateData = { correct: correct };
      this.$emit("updateGame", updateData);
      this.$emit(
        "notify",
        titleText,
        `${this.$t("hamburg.songLang")} ${correctText}`,
        context
      );
    },
    pickBerlinWall(song) {
      if (!this.canAct || this.lockEnd) {
        return;
      }
      let correct = song == 8;
      let titleText = this.$t("hamburg.wrong");
      let context = "failure";
      let subtitleText = this.$t("hamburg.wallNotifySubtitle");
      if (correct) {
        titleText = this.$t("hamburg.right");
        context = "success";
      }
      this.$emit("notify", titleText, subtitleText, context);
      const updateData = { correct: correct };
      this.$emit("updateGame", updateData);
    },
  },
  computed: {
    pointsDisplay() {
      return this.gameData.players[this.me]?.points
        ? this.gameData.players[this.me]?.points
        : 0;
    },
  },
};
</script>

<style scoped lang="scss">
.hamburg-level {
  background: #6a7a83;
}

.game-panel {
  display: grid;
  place-items: center;
  //game exclusive
  .tv-wrapper {
    height: 55vh;
    max-height: 500px;
    width: 65vh;
    max-width: 590px;
    position: relative;
    .video {
      position: absolute;
      top: 12%;
      left: 5%;
      height: 100%;
      width: 74%;
    }
    .tv {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .berlin-wall-wrapper {
    width: 100%;
    .title {
      font-family: "GoetheFFClan", sans-serif;
      font-size: 1rem;
      padding: 0 10px;
      text-align: center;
    }
    .options-wrapper {
      width: 90%;
      max-width: 450px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .option {
        width: 10%;
        background: #4dc1ec;
        border: 2px solid #0a4879;
        color: #0a4879;
        border-radius: 5px;
        font-size: 1rem;
        transition: 0.3s;
        cursor: pointer;
      }
      .option:hover {
        background: #0a4879;
        border: 2px solid #4dc1ec;
        color: #4dc1ec;
      }
    }
  }
}

.game-info-display {
  display: grid;
  place-items: center;
  //game exclusive
  .option-holder {
    width: 80%;
    max-width: 500px;
    height: 80%;
    max-height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .english,
    .german {
      width: 45%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .image-holder {
        width: 50%;
        display: grid;
        place-items: center;
        img {
          height: 30px;
          width: auto;
        }
      }
      .text-holder {
        width: 50%;
        display: grid;
        place-items: center;
        p {
          font-family: "GoetheFFClan", sans-serif;
          font-size: 1.1rem;
          margin: 0;
        }
      }
    }
    .english:hover,
    .english:active,
    .german:hover,
    .german:active {
      animation: shake 5s;
      animation-iteration-count: infinite;
      cursor: pointer;
    }
    .border {
      width: 2px;
      background: #707070;
    }
  }
}

@media (min-width: 1024px) {
  .game-panel {
    .berlin-wall-wrapper {
      .title {
        font-size: 1.3rem;
      }
      .options-wrapper {
        .option {
          font-size: 1.5rem;
        }
      }
    }
    .phase-counter {
      font-size: 1.8rem;
      bottom: 30px;
    }
  }

  .lower-panel {
    .game-info-display {
      .option-holder {
        .english,
        .german {
          .image-holder {
            img {
              height: 60px;
            }
          }
          .text-holder {
            p {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}
</style>
