<template>
  <div
    class="mute-btn thinly-shadowed"
    :class="{ tutorial: showTutorial }"
    @click="showHelp"
  >
    <img
      class="mic-img"
      src="@/assets/img/utils/mic_muted.svg"
      v-if="userMuted"
    />
    <img class="mic-img" src="@/assets/img/utils/mic_open.svg" v-else />
  </div>
</template>

<script>
export default {
  name: "MuteBtn",
  props: ["userMuted", "showTutorial"],
  methods: {
    showHelp() {
      if (this.showTutorial) {
        this.$emit("nextTutorial", "music");
      }
      this.$emit("controlVoice");
    },
  },
};
</script>

<style scoped lang="scss">
.mute-btn {
  position: absolute;
  bottom: 5px;
  right: 40px;
  background: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  .mic-img {
    height: 70%;
    width: 70%;
  }
}
.tutorial {
  z-index: 10;
  animation: shake 5s;
  animation-iteration-count: infinite;
}

@media (min-width: 1024px) {
  .mute-btn {
    bottom: 20px;
    right: 65px;
    height: 45px;
    width: 45px;
    p {
      font-size: 1.7rem;
    }
  }
}
</style>
