<template>
  <div class="help" :class="color">
    <div class="help-content shadowed">
      <h1>{{ $t(`help.instructions`) }}</h1>
      <h2>{{ $t(`help.howTo`) }}</h2>
      <div class="tabs">
        <button
          class="tab"
          @click="tab = 1"
          :class="tab == 1 ? 'selected' : ''"
        >
          <p>{{ $t(`help.map`) }}</p>
        </button>
        <button
          class="tab"
          @click="tab = 2"
          :class="tab == 2 ? 'selected' : ''"
        >
          <p>{{ $t(`help.activities`) }}</p>
        </button>
        <button
          class="tab"
          @click="tab = 3"
          :class="tab == 3 ? 'selected' : ''"
        >
          <p>{{ $t(`help.controls`) }}</p>
        </button>
      </div>
      <div class="text">
        <div v-if="tab == 1">
          <p class="paragraph">
            {{ $t(`help.tab1P1`) }}
          </p>
          <p class="paragraph">
            {{ $t(`help.tab1P2`) }}
          </p>
          <p class="paragraph">
            {{ $t(`help.tab1P3`) }}
          </p>
          <img
            class="help-img horizontal thinly-shadowed"
            src="@/assets/img/help/map.jpg"
          />
          <p class="img-about">{{ $t(`help.tab1Legend`) }}</p>
        </div>

        <div v-if="tab == 2">
          <p class="paragraph">
            {{ $t(`help.tab2P1`) }}
          </p>
          <img
            class="help-img horizontal thinly-shadowed"
            src="@/assets/img/help/game_intro.jpg"
          />
          <p class="img-about">{{ $t(`help.tab2Legend`) }}</p>
          <p class="paragraph">
            {{ $t(`help.tab2P2`) }}
          </p>
          <p class="paragraph">
            {{ $t(`help.tab2P3`) }}
          </p>
          <img
            class="help-img horizontal thinly-shadowed"
            src="@/assets/img/help/panel.jpg"
          />
          <p class="img-about">
            {{ $t(`help.tab2Legend2`) }}
          </p>
        </div>

        <div v-if="tab == 3">
          <p class="paragraph">
            {{ $t(`help.tab3P1`) }}
          </p>
          <img
            class="help-img vertical thinly-shadowed"
            src="@/assets/img/help/controls.jpg"
          />
          <p class="paragraph">
            {{ $t(`help.tab3P2`) }}
          </p>
          <p class="paragraph">
            {{ $t(`help.tab3P3`) }}
          </p>
          <p class="paragraph">
            {{ $t(`help.tab3P4`) }}
          </p>
          <p class="paragraph">
            {{ $t(`help.tab3P5`) }}
          </p>
          <img
            class="help-img smaller thinly-shadowed"
            src="@/assets/img/help/sound_controls.jpg"
          />
          <p class="paragraph">
            {{ $t(`help.tab3P6`) }}
          </p>
          <p class="paragraph">
            {{ $t(`help.tab3P7`) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PlatformHelp",
  props: ["color"],
  data() {
    return {
      tab: 1,
    };
  },
};
</script>

<style scoped lang="scss">
.help {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  .help-content {
    height: 85%;
    width: 65%;
    max-width: 850px;
    background: #fff;
    border-radius: 10px;
    overflow-y: scroll;
    h1 {
      font-family: "GoetheFFClanBold", sans-serif;
      font-size: 1.2rem;
      margin: 0.5rem 0;
      text-align: center;
    }
    h2 {
      font-family: "GoetheFFClanBold", sans-serif;
      font-size: 1rem;
      margin: 0.5rem 0;
      text-align: center;
    }
    .tabs {
      width: 90%;
      margin: 0 auto;
      display: flex;
      .tab {
        width: 33.3%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border: 1px solid #000;
        background: #fff;
        font-family: "GoetheFFClan", sans-serif;
        padding: 0;
        line-height: 0;
        cursor: pointer;
        font-size: 0.8rem;
      }
      .selected {
        background: #d4ded5;
        font-family: "GoetheFFClanBold", sans-serif;
      }
    }
    .text {
      width: 90%;
      margin: 0 auto 0.5rem auto;
      border: 1px solid #000;
      padding: 5px;
      box-sizing: border-box;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .paragraph {
        font-family: "GoetheFFClan", sans-serif;
        font-size: 0.7rem;
        text-align: justify;
      }
      .help-img {
        display: block;
        margin: 0 auto;
        border-radius: 5px;
      }
      .horizontal {
        width: 80%;
        max-width: 600px;
      }
      .vertical {
        width: 50px;
      }
      .smaller {
        width: 100px;
      }
      .img-about {
        width: 80%;
        margin: 0.2rem auto 1rem auto;
        font-family: "GoetheFFClanBold", sans-serif;
        font-size: 0.65rem;
      }
    }
  }
}

.green {
  background: #9aca3b;
}

.lightgray {
  background: #d4ded5;
}

@media (min-width: 1024px) {
  .help {
    .help-content {
      height: 85%;
      width: 65%;
      h1 {
        font-size: 1.5rem;
        margin: 1rem 0;
      }
      h2 {
        font-size: 1.2rem;
        margin: 1rem 0;
      }
      .tabs {
        .tab {
          font-size: 1rem;
        }
      }
      .text {
        padding: 10px;
        box-sizing: border-box;
        .paragraph {
          font-size: 0.9rem;
        }
        .vertical {
          width: 100px;
        }
        .smaller {
          width: 200px;
        }
        .img-about {
          margin: 0.5rem auto 1rem auto;
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>
