<template>
  <div class="home-mobile">
    <section class="intro">
      <img class="logo" src="@/assets/img/logo_white_horizontal.svg" />
      <div class="intro-content">
        <h1>{{ $t(`home.login`) }}</h1>
        <h2>{{ $t(`home.playerAccess`) }}</h2>

        <form @submit.prevent="login">
          <input
            type="text"
            class="text-input"
            :placeholder="$t(`home.type`)"
            v-model="token"
          />
          <p class="message" v-if="message">{{ message }}</p>
          <input
            type="submit"
            class="btn"
            :value="$t(`home.access`)"
            :disabled="!token"
          />
        </form>
      </div>
    </section>

    <section class="autobahn-digital">
      <div class="content">
        <h1>{{ $t(`home.autobahn`) }}</h1>
        <p class="subtitle">
          {{ $t(`home.haveFun`) }}
        </p>
        <p class="text">{{ $t(`home.youngAudience`) }}</p>
      </div>
    </section>

    <section class="text-section">
      <div class="content">
        <h1>{{ $t(`home.about`) }}</h1>
        <p>
          {{ $t(`home.aboutP1`) }}
        </p>
      </div>
    </section>

    <section class="text-section padding-bottom">
      <div class="content">
        <h1>{{ $t(`home.goethe`) }}</h1>
        <p>
          {{ $t(`home.goetheP1`) }}
        </p>

        <p>
          {{ $t(`home.goetheP2`) }}
        </p>

        <p>
          {{ $t(`home.goetheP3`) }}
        </p>
      </div>
    </section>

    <div class="footer">
      <img class="logo" src="@/assets/img/logo_white_horizontal.svg" />
      <p class="goethe-links">
        <a href="https://www.goethe.de/ins/br/pt/index.html" target="_blank">{{
          $t(`home.website`)
        }}</a>
      </p>
      <p class="goethe-links">
        <a href="https://blog.pasch-net.de/paschnobrasil/" target="_blank">{{
          $t(`home.pasch`)
        }}</a>
      </p>

      <hr />

      <p class="copyright">
        Copyright © 2023 Goethe-Institut - All Rights Reserved
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeMobile",
  props: ["message"],
  data() {
    return {
      token: null,
    };
  },
  methods: {
    login() {
      this.$emit("login", this.token);
    },
  },
};
</script>

<style scoped lang="scss">
.home-mobile {
  font-family: "GoetheFFClan", sans-serif;
  width: 100vw;
}

.intro {
  background: #9aca3b;
  min-height: 100vh;
  position: relative;
  display: grid;
  place-items: center;
  color: #fff;
  .logo {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 50px;
    width: auto;
  }
  .intro-content {
    width: 75%;
    max-width: 350px;
    text-align: center;
    h1 {
      font-family: "GoetheFFClanBold", sans-serif;
      font-size: 2.5rem;
      margin: 0;
    }
    h2 {
      font-size: 1rem;
      margin: 1.5rem 0;
    }
    .text-input {
      border: 2px solid #fff;
      background: transparent;
      box-sizing: border-box;
      color: #fff;
      width: 100%;
      padding: 15px 15px;
      font-size: 0.9rem;
      border-radius: 10px;
    }
    .text-input::placeholder {
      color: #fff;
    }
    .text-input::-ms-input-placeholder {
      color: #fff;
    }
    .btn {
      margin-top: 1rem;
      border: 2px solid #fff;
      background: #fff;
      color: #9aca3b;
      font-size: 1rem;
      width: 100%;
      padding: 13px 15px;
      border-radius: 10px;
      font-weight: 700;
      cursor: pointer;
    }
    .message {
      color: #788287;
      margin: 0.5rem 0 0 0;
      font-weight: 700;
      font-size: 0.8rem;
      padding: 0 0.5rem;
    }
  }
}

.autobahn-digital {
  background: #fff;
  padding-top: 3rem;
  color: #9aca3b;
  display: grid;
  place-items: center;
  .content {
    width: 75%;
    max-width: 450px;
    h1 {
      font-family: "GoetheFFClanBold", sans-serif;
      font-size: 2.5rem;
      margin: 0;
    }
    .subtitle {
      font-family: "GoetheFFClanBold", sans-serif;
      font-size: 1rem;
    }
    .text {
      font-size: 1rem;
    }
  }
}

.text-section {
  background: #fff;
  padding-top: 2rem;
  display: grid;
  place-items: center;
  .content {
    width: 75%;
    max-width: 450px;
    h1 {
      font-family: "GoetheFFClanBold", sans-serif;
      font-size: 1.8rem;
      margin: 0;
      color: #9aca3b;
    }
    p {
      font-size: 0.9rem;
      color: #788287;
      margin: 1.2rem 0;
    }
  }
}

.padding-bottom {
  padding-bottom: 3.5rem;
}

.footer {
  background: #374105;
  text-align: center;
  padding-bottom: 1.5rem;
  .logo {
    height: 80px;
    margin: 3rem auto;
  }
  .goethe-links {
    font-family: "GoetheFFClanBold", sans-serif;
    font-size: 1rem;
    margin: 0 0 1.5rem 0;
    a {
      color: #fff;
    }
  }
  hr {
    margin: 3rem 12.5% 1.5rem 12.5%;
    width: 75%;
    border: 0;
    height: 1px;
    background: #98c23d;
  }
  .copyright {
    margin: 0;
    color: #d0dabc;
    font-size: 0.65rem;
  }
}
</style>
