export default {
  loading: "LoadingLevel",
  end: "EndLevel",
  lobby: "LobbyLevel",
  map: "MapLevel",
  rostock: "RostockLevel",
  hamburg: "HamburgLevel",
  dresden: "DresdenLevel",
  mainz: "MainzLevel",
  eisenach: "EisenachLevel",
  koln: "KolnLevel",
  munchen: "MunchenLevel",
  weimar: "WeimarLevel",
  berlin: "BerlinLevel",
  frankfurt: "FrankfurtLevel",
  munster: "MunsterLevel",
  bremen: "BremenLevel",
  bonn: "BonnLevel",
  nurnberg: "NurnbergLevel",
  stuttgart: "StuttgartLevel",
};
