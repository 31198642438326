<template>
  <div class="player-qtt">
    <div class="player" v-for="(player, index) in qtt" :key="index">
      <img
        src="@/assets/img/utils/user.svg"
        :class="color == 'lightgray' ? 'black' : 'white'"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "PlayerQtt",
  props: ["qtt", "color"],
};
</script>
<style lang="scss" scoped>
.player-qtt {
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .player {
    width: 15px;
    height: 15px;
    img {
      width: 15px;
    }
  }
}

.white {
  filter: brightness(0) invert(1);
}

@media (min-width: 1024px) {
  .player-qtt {
    bottom: 15px;
    width: 150px;
    .player {
      width: 30px;
      height: 30px;
      img {
        width: 30px;
      }
    }
  }
}
</style>
