<template>
  <div class="city-name thinly-shadowed">
    <p>{{ $t(`cityNames.${name}`) }}</p>
  </div>
</template>

<script>
export default {
  name: "CityName",
  props: ["name"],
};
</script>

<style lang="scss" scoped>
.city-name {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translate(-50%, 0);
  background: #ffd500;
  border-radius: 5px;
  p {
    margin: 0;
    font-family: "GoetheFFClanBold", sans-serif;
    padding: 1px 10px;
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .city-name {
    top: 10px;
    p {
      padding: 2px 10px;
      font-size: 1.3rem;
    }
  }
}
</style>
