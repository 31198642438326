export default {
  rostock: {
    title: "rostock.introTitle",
    howTo: "RostockHowTo",
    char: "rostock/hans-rostock.svg",
    steps: [
      {
        img1: "rostock/rostock-1.jpg",
        img2: "rostock/rostock-2.jpg",
        paragraphs: ["rostock.p1"],
        legend1: "rostock.l1",
        legend2: "rostock.l2",
      },
      {
        img1: "rostock/rostock-3.jpg",
        img2: "rostock/rostock-4.jpg",
        paragraphs: ["rostock.p2"],
        legend1: "rostock.l3",
        legend2: "rostock.l4",
      },
    ],
  },
  hamburg: {
    title: "hamburg.introTitle",
    howTo: "HamburgHowTo",
    char: "hamburg/johann-hamburg.svg",
    steps: [
      {
        img1: "hamburg/hamburg-1.jpg",
        img2: "hamburg/hamburg-2.jpg",
        paragraphs: ["hamburg.p1"],
        legend1: "hamburg.l1",
        legend2: "hamburg.l2",
      },
      {
        img1: "hamburg/hamburg-3.jpg",
        img2: "hamburg/hamburg-4.jpg",
        paragraphs: ["hamburg.p2"],
        legend1: "hamburg.l3",
        legend2: "hamburg.l4",
      },
    ],
  },
  dresden: {
    title: "dresden.introTitle",
    howTo: "DresdenHowTo",
    char: "dresden/johann-dresden.svg",
    steps: [
      {
        img1: "dresden/dresden-1.jpg",
        img2: "dresden/dresden-2.jpg",
        paragraphs: ["dresden.p1"],
        legend1: "dresden.l1",
        legend2: "dresden.l2",
      },
      {
        img1: "dresden/dresden-3.jpg",
        img2: "dresden/dresden-4.jpg",
        paragraphs: ["dresden.p2"],
        legend1: "dresden.l3",
        legend2: "dresden.l4",
      },
      {
        img1: "dresden/dresden-5.jpg",
        img2: "dresden/dresden-6.jpg",
        paragraphs: ["dresden.p3"],
        legend1: "dresden.l5",
        legend2: "dresden.l6",
      },
    ],
  },
  mainz: {
    title: "mainz.introTitle",
    howTo: "MainzHowTo",
    char: "mainz/hans-mainz.svg",
    steps: [
      {
        img1: "mainz/mainz-1.jpg",
        img2: "mainz/mainz-2.jpg",
        paragraphs: ["mainz.p1"],
        legend1: "mainz.l1",
        legend2: "mainz.l2",
      },
      {
        img1: "mainz/mainz-3.jpg",
        img2: "mainz/mainz-4.jpg",
        paragraphs: ["mainz.p2"],
        legend1: "mainz.l3",
        legend2: "mainz.l4",
      },
    ],
  },
  eisenach: {
    title: "eisenach.introTitle",
    howTo: "EisenachHowTo",
    char: "eisenach/hans-eisenach.svg",
    steps: [
      {
        img1: "eisenach/eisenach-1.jpg",
        img2: "eisenach/eisenach-2.jpg",
        paragraphs: ["eisenach.p1"],
        legend1: "eisenach.l1",
        legend2: "eisenach.l2",
      },
      {
        img1: "eisenach/eisenach-3.jpg",
        img2: "eisenach/eisenach-4.jpg",
        paragraphs: ["eisenach.p2"],
        legend1: "eisenach.l3",
        legend2: "eisenach.l4",
      },
    ],
  },
  koln: {
    title: "koln.introTitle",
    howTo: "KolnHowTo",
    char: "koln/heidi-koln.svg",
    steps: [
      {
        img1: "koln/koln-1.jpg",
        img2: "koln/koln-2.jpg",
        paragraphs: ["koln.p1"],
        legend1: "koln.l1",
        legend2: "koln.l2",
      },
      {
        img1: "koln/koln-3.jpg",
        img2: "koln/koln-4.jpg",
        paragraphs: ["koln.p2"],
        legend1: "koln.l3",
        legend2: "koln.l4",
      },
    ],
  },
  munchen: {
    title: "munchen.introTitle",
    howTo: "MunchenHowTo",
    char: "munchen/johann-munchen.svg",
    steps: [
      {
        img1: "munchen/munchen-1.jpg",
        img2: "munchen/munchen-2.jpg",
        paragraphs: ["munchen.p1"],
        legend1: "munchen.l1",
        legend2: "munchen.l2",
      },
      {
        img1: "munchen/munchen-3.jpg",
        img2: "munchen/munchen-4.jpg",
        paragraphs: ["munchen.p2"],
        legend1: "munchen.l3",
        legend2: "munchen.l4",
      },
    ],
  },
  weimar: {
    title: "weimar.introTitle",
    howTo: "WeimarHowTo",
    char: "weimar/hans-weimar.svg",
    steps: [
      {
        img1: "weimar/weimar-1.jpg",
        img2: "weimar/weimar-2.jpg",
        paragraphs: ["weimar.p1"],
        legend1: "weimar.l1",
        legend2: "weimar.l2",
      },
      {
        img1: "weimar/weimar-3.jpg",
        img2: "weimar/weimar-4.jpg",
        paragraphs: ["weimar.p2"],
        legend1: "weimar.l3",
        legend2: "weimar.l4",
      },
    ],
  },
  berlin: {
    title: "berlin.introTitle",
    howTo: "BerlinHowTo",
    char: "berlin/johann-berlin.svg",
    steps: [
      {
        img1: "berlin/berlin-1.jpg",
        img2: "berlin/berlin-2.jpg",
        paragraphs: ["berlin.p1"],
        legend1: "berlin.l1",
        legend2: "berlin.l2",
      },
      {
        img1: "berlin/berlin-3.jpg",
        img2: "berlin/berlin-4.jpg",
        paragraphs: ["berlin.p2"],
        legend1: "berlin.l3",
        legend2: "berlin.l4",
      },
    ],
  },
  frankfurt: {
    title: "frankfurt.introTitle",
    howTo: "FrankfurtHowTo",
    char: "frankfurt/hans-frankfurt.svg",
    steps: [
      {
        img1: "frankfurt/frankfurt-1.jpg",
        img2: "frankfurt/frankfurt-2.jpg",
        paragraphs: ["frankfurt.p1"],
        legend1: "frankfurt.l1",
        legend2: "frankfurt.l2",
      },
      {
        img1: "frankfurt/frankfurt-3.jpg",
        img2: "frankfurt/frankfurt-4.jpg",
        paragraphs: ["frankfurt.p2"],
        legend1: "frankfurt.l3",
        legend2: "frankfurt.l4",
      },
    ],
  },
  munster: {
    title: "munster.introTitle",
    howTo: "MunsterHowTo",
    char: "munster/hans-munster.svg",
    steps: [
      {
        img1: "munster/munster-1.jpg",
        img2: "munster/munster-2.jpg",
        paragraphs: ["munster.p1"],
        legend1: "munster.l1",
        legend2: "munster.l2",
      },
      {
        img1: "munster/munster-3.jpg",
        img2: "munster/munster-4.jpg",
        paragraphs: ["munster.p2"],
        legend1: "munster.l3",
        legend2: "munster.l4",
      },
    ],
  },
  bremen: {
    title: "bremen.introTitle",
    howTo: "BremenHowTo",
    char: "bremen/hans-bremen.svg",
    steps: [
      {
        img1: "bremen/bremen-1.jpg",
        img2: "bremen/bremen-2.jpg",
        paragraphs: ["bremen.p1", "bremen.p2"],
        legend1: "bremen.l1",
        legend2: "bremen.l2",
      },
      {
        img1: "bremen/bremen-3.jpg",
        img2: "bremen/bremen-4.jpg",
        paragraphs: ["bremen.p3"],
        legend1: "bremen.l3",
        legend2: "bremen.l4",
      },
    ],
  },
  bonn: {
    title: "bonn.introTitle",
    howTo: "BonnHowTo",
    char: "bonn/heidi-bonn.svg",
    steps: [
      {
        img1: "bonn/bonn-1.jpg",
        img2: "bonn/bonn-2.jpg",
        paragraphs: ["bonn.p1"],
        legend1: "bonn.l1",
        legend2: "bonn.l2",
      },
      {
        img1: "bonn/bonn-3.jpg",
        img2: "bonn/bonn-4.jpg",
        paragraphs: ["bonn.p2"],
        legend1: "bonn.l3",
        legend2: "bonn.l4",
      },
    ],
  },
  nurnberg: {
    title: "nurnberg.introTitle",
    howTo: "NurnbergHowTo",
    char: "nurnberg/hans-nurnberg.svg",
    steps: [
      {
        img1: "nurnberg/nurnberg-1.jpg",
        img2: "nurnberg/nurnberg-2.jpg",
        paragraphs: ["nurnberg.p1"],
        legend1: "nurnberg.l1",
        legend2: "nurnberg.l2",
      },
    ],
  },
  stuttgart: {
    title: "stuttgart.introTitle",
    howTo: "StuttgartHowTo",
    char: "stuttgart/johann-stuttgart.svg",
    steps: [
      {
        img1: "stuttgart/stuttgart-1.jpg",
        img2: "stuttgart/stuttgart-2.jpg",
        paragraphs: ["stuttgart.p1"],
        legend1: "stuttgart.l1",
        legend2: "stuttgart.l2",
      },
      {
        img1: "stuttgart/stuttgart-3.jpg",
        img2: "stuttgart/stuttgart-4.jpg",
        paragraphs: ["stuttgart.p2"],
        legend1: "stuttgart.l3",
        legend2: "stuttgart.l4",
      },
    ],
  },
};
