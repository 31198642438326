<template>
  <section class="create-session">
    <div class="nav">
      <div>
        <router-link to="/create-session" class="active">
          Create Session
        </router-link>
        <router-link to="/sessions">Sessions</router-link>
        <a @click="logout">Logout</a>
      </div>
    </div>
    <div>
      <h1>Create session</h1>
      <form @submit.prevent="create">
        <date-picker
          v-model="start"
          type="datetime"
          format="'YYYY-MM-DDTHH:mm'"
          value-type="format"
          :minute-step="minuteStep"
          class="text-input"
          placeholder="Start"
          :disabled="loading"
        />
        <br /><br />
        <date-picker
          v-model="end"
          type="datetime"
          format="'YYYY-MM-DDTHH:mm'"
          value-type="format"
          :minute-step="minuteStep"
          class="text-input"
          placeholder="End"
          :disabled="!start || loading"
        />
        <br /><br />
        <input
          type="submit"
          value="Create"
          class="btn"
          :disabled="!start || !end || error || loading"
        />
      </form>
      <p class="saving-message" v-if="loading">Saving...</p>
      <p class="error-message" v-if="error">{{ errorMessage }}</p>
    </div>
  </section>
</template>

<script>
import { apiDBAdmCreateSession } from "@/utils/goethe-apis.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "CreateSession",
  components: { DatePicker },
  data() {
    return {
      start: null,
      end: null,
      loading: false,
      minuteStep: 15,
      error: false,
      errorMessage: "",
    };
  },
  methods: {
    create() {
      this.loading = true;
      const data = {
        int_invited_users: 96,
        dt_start_date: this.computedStart,
        dt_end_date: this.computedEnd,
        str_timezone: Intl.DateTimeFormat()
          .resolvedOptions()
          .timeZone.toString(),
      };
      const token = localStorage.getItem("admAccessToken");
      apiDBAdmCreateSession(data, token)
        .then((res) => {
          if (res.data.status == 201) {
            this.$router.push("/sessions");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    logout() {
      localStorage.removeItem("admAccessToken");
      this.$router.push("/adm-login");
    },
  },
  watch: {
    start() {
      if (!this.computedStart) {
        return;
      }
      const now = new Date();
      const start = new Date(this.computedStart);
      if (start <= now) {
        this.error = true;
        this.errorMessage = "Start time must be in the future";
        return;
      }
      this.error = false;
      this.errorMessage = "";
    },
    end() {
      if (!this.computedStart || !this.computedEnd) {
        return;
      }
      const start = new Date(this.computedStart);
      const end = new Date(this.computedEnd);
      if (end <= start) {
        this.error = true;
        this.errorMessage = "End time must be higher than Start time";
        return;
      }

      const startTest = start.setSeconds(start.getSeconds() + 1799);

      if (startTest >= end) {
        this.error = true;
        this.errorMessage = "A session must be at least 30 minutes long";
        return;
      }

      this.error = false;
      this.errorMessage = "";
    },
  },
  computed: {
    computedStart() {
      if (this.start && this.start != "") {
        return `${this.start.toString().replaceAll("'", "")}:00`;
      }
      return null;
    },
    computedEnd() {
      if (this.end && this.end != "") {
        return `${this.end.toString().replaceAll("'", "")}:00`;
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.create-session {
  background: #d4ded5;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  text-align: center;
  font-family: "GoetheFFClan", sans-serif;
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: #6a7a83;
    display: grid;
    place-items: center;
    a {
      color: #d4ded5;
      text-decoration: none;
      font-weight: 700;
      transition: 0.3s;
      margin: 0 2rem;
      cursor: pointer;
    }
    a:hover,
    .active {
      color: #ed6f23;
    }
  }
  .text-input .mx-input-wrapper input,
  .btn {
    border-radius: 5px;
    border: 1px solid #6a7a83;
    padding: 8px;
    text-align: center;
    box-sizing: border-box;
    transition: 0.3s;
  }
  .text-input {
    width: 250px;
  }
  .btn {
    width: 250px;
    background: #4dc1ec;
    cursor: pointer;
    font-weight: 700;
    color: #fff;
  }
  .btn:hover {
    background: #0a4879;
  }
  .btn:disabled {
    background: #6a7a83;
    cursor: default;
  }
  .saving-message {
    color: #98c23d;
    font-weight: 700;
  }
  .error-message {
    color: #e50019;
    font-weight: 700;
  }
}
</style>
