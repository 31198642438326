<template>
  <div class="shooting-star" @click="clicked(sign)">
    <img class="tail" src="@/assets/img/games/munster/shooting_star.png" />
    <img
      class="birth-sign"
      :src="require(`@/assets/img/games/munster/${sign}.svg`)"
    />
  </div>
</template>
<script>
export default {
  props: ["sign"],
  name: "ShootingStar",
  methods: {
    clicked(sign) {
      this.$emit("chosen", sign);
    },
  },
};
</script>

<style lang="scss" scoped>
.shooting-star {
  position: relative;
  text-align: center;
  width: 40px;
  cursor: pointer;
  .tail {
    width: 25px;
  }
  .birth-sign {
    width: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@media (min-width: 1024px) {
  .shooting-star {
    width: 65px;
    .tail {
      width: 40px;
    }
    .birth-sign {
      width: 65px;
    }
  }
}
</style>
