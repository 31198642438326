<template>
  <div class="level eisenach-level" v-if="step">
    <game-intro
      v-if="step == 'intro'"
      :city="city"
      :seconds="introSeconds"
      @timeoutIntro="timeoutIntro"
    />

    <div class="game shadowed" v-if="step == 'game'">
      <div class="game-panel">
        <div class="intro" v-if="!gameStarted">
          <div class="composer-img">
            <img
              :src="
                require(`@/assets/img/games/eisenach/${introData[introPhase].img}`)
              "
            />
          </div>
          <div class="content">
            <div class="content-inner">
              <p class="composer-name">
                {{ introData[introPhase].composer }}
              </p>
              <p class="song-name">{{ introData[introPhase].song }}</p>
              <p class="about">
                {{ $t(`eisenach.${introData[introPhase].content}`) }}
              </p>
            </div>
          </div>
          <p class="step-counter">{{ introPhase + 1 }} / 4</p>
        </div>

        <div class="running-game" v-else>
          <h1>{{ $t("eisenach.gameTitle") }}</h1>
          <div class="composer composer-left">
            <div class="composer-inner">
              <img
                :src="
                  require(`@/assets/img/games/eisenach/${eisenachData[gamePhase].imgLeft}`)
                "
                @click="pickComposer(eisenachData[gamePhase].left)"
              />
              <p>{{ eisenachData[gamePhase].composerLeft }}</p>
            </div>
          </div>

          <div class="composer composer-right">
            <div class="composer-inner">
              <img
                :src="
                  require(`@/assets/img/games/eisenach/${eisenachData[gamePhase].imgRight}`)
                "
                @click="pickComposer(eisenachData[gamePhase].right)"
              />
              <p>{{ eisenachData[gamePhase].composerRight }}</p>
            </div>
          </div>
          <p class="step-counter">{{ gamePhase + 1 }} / 4</p>
        </div>
      </div>
      <div class="lower-panel">
        <div class="game-info-display">
          <div class="player">
            <div
              class="intro-btn ctrl-btn"
              @click="advanceIntro(-1)"
              v-if="!gameStarted"
            >
              <img src="@/assets/img/utils/previous.svg" />
            </div>

            <audio-player
              :file="
                gameStarted
                  ? eisenachData[gamePhase].file
                  : introData[introPhase].file
              "
              class="player-btn"
              ref="audioPlayerBtn"
            />

            <div
              class="intro-btn ctrl-btn"
              @click="advanceIntro(1)"
              v-if="!gameStarted"
            >
              <img src="@/assets/img/utils/next.svg" v-if="introPhase < 3" />
              <p v-else>GO!</p>
            </div>
          </div>
        </div>
        <game-clock
          class="clock"
          :seconds="seconds"
          :points="pointsDisplay"
          @timeoutLevel="timeoutLevel"
        />
      </div>

      <waiting-players-notification v-if="lockEnd" />
    </div>

    <transition name="slide-down">
      <help :level="city" :color="color" v-if="showHelp" />
    </transition>
    <help-btn
      v-if="step == 'game'"
      :helpShown="showHelp"
      @showHelp="showHelp = !showHelp"
    />
    <player-qtt :qtt="1" :color="color" />
  </div>
</template>

<script>
import GameIntro from "@/components/utils/GameIntro.vue";
import Help from "@/components/utils/Help.vue";
import HelpBtn from "@/components/utils/HelpBtn.vue";
import GameClock from "@/components/utils/GameClock.vue";
import WaitingPlayersNotification from "@/components/utils/WaitingPlayersNotification.vue";
import AudioPlayer from "@/components/utils/AudioPlayer.vue";
import eisenachEnum from "@/enums/eisenach.enum.js";
import PlayerQtt from "@/components/utils/PlayerQtt.vue";
export default {
  components: {
    GameIntro,
    Help,
    HelpBtn,
    GameClock,
    WaitingPlayersNotification,
    AudioPlayer,
    PlayerQtt,
  },
  name: "EisenachLevel",
  props: ["gameData", "canAct", "updateId", "introSeconds", "seconds", "me"],
  watch: {
    updateId() {
      this.updateGame();
    },
  },
  data() {
    return {
      isFirstLoad: true,
      city: "eisenach",
      color: "lightgray",
      step: null,
      waitingStart: false,
      showHelp: false,
      introData: eisenachEnum.intro,
      eisenachData: eisenachEnum.game,
      lockEnd: false,
      gameStarted: false,
      introPhase: 0,
      gamePhase: 0,
    };
  },
  created() {
    this.$emit("fetchLevelTimes");
  },
  methods: {
    timeoutLevel() {
      this.$emit("timeoutLevel", this.city);
    },
    timeoutIntro() {
      this.$emit("timeoutIntro", this.city);
    },
    updateGame() {
      this.step = this.gameData.intro_ended ? "game" : "intro";

      if (this.step != "game" && !this.waitingStart) {
        return;
      }

      if (this.gameData.started && this.waitingStart) {
        this.step = "game";
        this.waitingStart = false;
      }

      if (
        this.gameData.players[this.me] &&
        this.gameData.players[this.me].started
      ) {
        this.gameStarted = true;
        let phase = this.gameData.players[this.me]?.answers?.length || 0;
        if (phase == 4) {
          this.lockEnd = true;
        } else {
          this.gamePhase = this.gameData.players[this.me].answers
            ? this.gameData.players[this.me].answers.length
            : 0;
        }
      } else {
        this.gameStarted = false;
      }
    },
    advanceIntro(val) {
      this.$refs.audioPlayerBtn.stopSound();
      if (val > 0 && this.introPhase == 3) {
        this.gameStarted = true;
        const updateData = { start_player: true };
        this.$emit("updateGame", updateData);
      } else if (val > 0 || (val < 0 && this.introPhase > 0)) {
        this.introPhase += val;
      }
    },
    pickComposer(correct) {
      if (!this.canAct || this.lockEnd) {
        return;
      }
      this.$refs.audioPlayerBtn.stopSound();
      const updateData = { correct: correct };
      this.$emit("updateGame", updateData);
      let feedbackText = this.$t(`eisenach.correct`);
      let context = "success";
      if (!correct) {
        feedbackText = this.$t(`eisenach.incorrect`);
        context = "failure";
      }
      this.$emit("notify", feedbackText, null, context);
    },
  },
  computed: {
    pointsDisplay() {
      return this.gameData.players[this.me]?.points
        ? this.gameData.players[this.me]?.points
        : 0;
    },
  },
};
</script>

<style scoped lang="scss">
.eisenach-level {
  background: #d4ded5;
}

.game-panel {
  display: grid;
  place-items: center;
  //game exclusive
  .intro {
    height: 95%;
    width: 95%;
    display: flex;
    flex-direction: row;
    position: relative;
    .composer-img {
      height: 100%;
      width: 30%;
      display: grid;
      place-items: center;
      img {
        width: 90%;
      }
    }
    .content {
      width: 70%;
      display: grid;
      place-items: center;
      box-sizing: border-box;
      padding: 0 1rem;
      .content-inner {
        width: 100%;
        .composer-name {
          font-family: "TrashHand";
          font-size: 1.4rem;
          margin: 0;
        }
        .song-name {
          font-family: "TrashHand";
          font-size: 1.2rem;
          margin: 0 0 0.6rem 0;
          font-weight: 600;
          color: #b5b5b5;
        }
        .about {
          font-family: "GoetheFFClan";
          font-size: 0.8rem;
          margin: 0;
        }
      }
    }
    .step-counter {
      position: absolute;
      bottom: 0;
      right: 0;
      font-family: "TrashHand", sans-serif;
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
  .running-game {
    display: flex;
    flex-direction: row;
    height: 95%;
    width: 95%;
    position: relative;
    h1 {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      text-align: center;
      font-size: 1.2rem;
      font-family: "TrashHand", sans-serif;
    }
    .composer {
      width: 50%;
      height: 100%;
      text-align: center;
      padding: 0 0.5rem;
      box-sizing: border-box;
      display: grid;
      place-items: center;
      img {
        width: 60%;
        cursor: pointer;
      }
      img:hover,
      img:active {
        animation: shake 5s;
        animation-iteration-count: infinite;
      }
      p {
        margin: 0;
        font-family: "GoetheFFClan", sans-serif;
        font-size: 0.8rem;
      }
    }
    .step-counter {
      margin: 0;
      position: absolute;
      bottom: 0;
      right: 0;
      font-family: "TrashHand", sans-serif;
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
}

.game-info-display {
  display: grid;
  place-items: center;
  .player {
    height: 34px;
    width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .player-btn {
      width: 40%;
    }
    .ctrl-btn {
      width: 20%;
      height: 30px;
      background: #fff;
      border-radius: 15px;
      border: 2px solid #000;
      box-shadow: -0.2rem 0.2rem #000;
      display: grid;
      place-items: center;
      transition: 0.3s;
      cursor: pointer;
      img {
        height: 14px;
        transition: 0.3s;
      }
      p {
        margin: 0.2rem 0 0 0;
        color: #000;
        font-family: "GoetheFFClan", sans-serif;
        font-weight: 700;
      }
    }
    .ctrl-btn:hover {
      background: #000;
      img {
        filter: brightness(0) invert(1);
      }
      p {
        color: #fff;
      }
    }
  }
}

@media (min-width: 1024px) {
  .game-panel {
    .intro {
      .composer-img {
        width: 40%;
        img {
          width: 90%;
        }
      }
      .content {
        width: 60%;
        .content-inner {
          .composer-name {
            font-size: 1.8rem;
          }
          .song-name {
            font-size: 1.5rem;
          }
          .about {
            font-size: 1rem;
          }
        }
      }
      .step-counter {
        font-size: 1.6rem;
      }
    }
    .running-game {
      h1 {
        top: 0;
        font-size: 2rem;
      }
      .composer {
        padding: 0 0.5rem;
        img {
          width: 70%;
        }
        p {
          font-size: 1rem;
        }
      }
      .step-counter {
        font-size: 1.6rem;
      }
    }
  }
}
</style>
