export default [
  {
    item: "elemente",
    img: "elemente.jpg",
    prefix: "Hof der",
    german: "Hof der Elemente",
    letters: ["E", "L", "E", "M", "E", "N", "T", "E"],
    found: [false, true, false, false, false, true, false, false],
    options: ["R", "Q", "L", "E", "U", "M", "S", "N", "T", "A", "W", "Y"],
    taken: [
      false,
      false,
      true,
      false,
      false,
      false,
      false,
      true,
      false,
      false,
      false,
      false,
    ],
    correct: [
      false,
      false,
      true,
      true,
      false,
      true,
      false,
      true,
      true,
      false,
      false,
      false,
    ],
  },
  {
    item: "fabelwesen",
    img: "fabelwesen.jpg",
    prefix: "Hof der",
    german: "Hof der Fabelwesen",
    letters: ["F", "A", "B", "E", "L", "W", "E", "S", "E", "N"],
    found: [true, false, false, false, true, false, false, false, false],
    options: ["B", "A", "F", "E", "X", "P", "K", "W", "V", "S", "N", "L"],
    taken: [
      false,
      false,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      true,
    ],
    correct: [
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      true,
      false,
      true,
      true,
      true,
    ],
  },
  {
    item: "lichts",
    img: "lichts.jpg",
    prefix: "Hof des",
    german: "Hof des Lichts",
    letters: ["L", "I", "C", "H", "T"],
    found: [false, false, false, true, false, false],
    options: ["I", "P", "S", "V", "C", "G", "R", "H", "F", "T", "O", "L"],
    taken: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      true,
      false,
      false,
      false,
      false,
    ],
    correct: [
      true,
      false,
      false,
      false,
      true,
      false,
      false,
      true,
      false,
      true,
      false,
      true,
    ],
  },
  {
    item: "tiere",
    img: "tiere.jpg",
    prefix: "Hof der",
    german: "Hof der Tiere",
    letters: ["T", "I", "E", "R", "E"],
    found: [false, true, false, false, false],
    options: ["P", "I", "S", "E", "H", "G", "R", "Z", "H", "T", "Y", "B"],
    taken: [
      false,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    correct: [
      false,
      true,
      false,
      true,
      false,
      false,
      true,
      false,
      false,
      true,
      false,
      false,
    ],
  },
  {
    item: "metamorphosen",
    img: "metamorphosen.jpg",
    prefix: "Hof der",
    german: "Hof der Metamorphosen",
    letters: ["M", "E", "T", "A", "M", "O", "R", "P", "H", "O", "S", "E", "N"],
    found: [
      false,
      false,
      false,
      true,
      false,
      false,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    options: ["T", "M", "E", "G", "R", "A", "O", "P", "N", "H", "Y", "S"],
    taken: [
      false,
      false,
      false,
      false,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    correct: [
      true,
      true,
      true,
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      true,
    ],
  },
];
