var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.step)?_c('div',{staticClass:"level bonn-level"},[(_vm.step == 'intro')?_c('game-intro',{attrs:{"city":_vm.city,"seconds":_vm.introSeconds},on:{"timeoutIntro":_vm.timeoutIntro}}):_vm._e(),(_vm.step == 'game')?_c('div',{staticClass:"game shadowed"},[_c('div',{staticClass:"game-panel"},[(_vm.phase <= 8)?_c('div',{staticClass:"biography-wrapper"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require(`@/assets/img/games/bonn/${_vm.people[_vm.phase]}.jpg`)}})]),_c('div',{staticClass:"text-wrapper"},[_c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t(`bonn.people.${_vm.people[_vm.phase]}.name`)))]),_c('p',[_vm._v(_vm._s(_vm.$t(`bonn.people.${_vm.people[_vm.phase]}.biography`)))])])])]):_c('div',{staticClass:"people-wrapper"},[_c('h1',[_vm._v(_vm._s(_vm.$t("bonn.gameTitle")))]),_c('div',{staticClass:"people"},_vm._l((_vm.phasePeople),function(person){return _c('div',{key:person,staticClass:"person",class:_vm.found[person]
                ? person
                : _vm.selectedPerson == person
                ? 'selected'
                : 'idle',on:{"click":function($event){return _vm.selectPerson(person)}}},[_c('div',{staticClass:"person-img"},[_c('img',{attrs:{"src":require(`@/assets/img/games/bonn/${person}.jpg`)}})]),_c('div',{staticClass:"person-name"},[_c('p',[_vm._v(_vm._s(_vm.$t(`bonn.people.${person}.name`)))])])])}),0),_c('div',{staticClass:"biographies"},_vm._l((_vm.phaseBiograpies),function(biography){return _c('div',{key:biography,staticClass:"biography",class:_vm.found[biography]
                ? biography
                : _vm.selectedBiography == biography
                ? 'selected'
                : 'idle',on:{"click":function($event){return _vm.selectBiography(biography)}}},[_c('p',[_vm._v(_vm._s(_vm.$t(`bonn.people.${biography}.biography`)))])])}),0)])]),_c('div',{staticClass:"lower-panel"},[_c('div',{staticClass:"game-info-display"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"tries-wrapper"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("bonn.tries"))+" "),_c('span',{staticClass:"text-shadow-extra-thin-white"},[_vm._v(_vm._s(_vm.tries))])])]),_c('div',{staticClass:"buttons-wrapper"},[_c('button',{staticClass:"ctrl-btn thinly-shadowed",attrs:{"disabled":_vm.phase == 0},on:{"click":function($event){return _vm.changePhase('prev')}}},[_vm._v(" "+_vm._s(_vm.$t("bonn.previous"))+" ")]),_c('button',{staticClass:"ctrl-btn thinly-shadowed",attrs:{"disabled":_vm.phase == 11},on:{"click":function($event){return _vm.changePhase('next')}}},[_vm._v(" "+_vm._s(_vm.$t("bonn.next"))+" ")])])])]),_c('game-clock',{staticClass:"clock",attrs:{"seconds":_vm.seconds,"points":_vm.gameData.points},on:{"timeoutLevel":_vm.timeoutLevel}})],1)]):_vm._e(),_c('transition',{attrs:{"name":"slide-down"}},[(_vm.showHelp)?_c('help',{attrs:{"level":_vm.city,"color":_vm.color}}):_vm._e()],1),(_vm.step == 'game')?_c('help-btn',{attrs:{"helpShown":_vm.showHelp},on:{"showHelp":function($event){_vm.showHelp = !_vm.showHelp}}}):_vm._e(),_c('player-qtt',{attrs:{"qtt":4,"color":_vm.color}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }