<template>
  <div class="game-intro">
    <div class="intro-content-columns" v-if="step <= introStepsQtt - 1">
      <div class="column-img">
        <div class="img-legend-group first-group">
          <img
            :src="require(`@/assets/img/intros/${content.steps[step].img1}`)"
          />
          <span class="img-legend">{{ $t(content.steps[step].legend1) }}</span>
        </div>
        <div class="img-legend-group">
          <img
            :src="require(`@/assets/img/intros/${content.steps[step].img2}`)"
          />
          <span class="img-legend">{{ $t(content.steps[step].legend2) }}</span>
        </div>
      </div>
      <div class="column-text">
        <div class="text-wrapper shadowed">
          <h1 v-if="step == 0">
            {{ $t(`${content.title}`) }}
          </h1>
          <p
            v-for="paragraph in content.steps[step].paragraphs"
            :key="paragraph"
          >
            {{ $t(`${paragraph}`) }}
          </p>
        </div>
      </div>
    </div>
    <div class="intro-char" v-if="step == introStepsQtt">
      <img :src="require(`@/assets/img/intros/${charImage}`)" />
    </div>
    <div class="intro-how-to" v-if="step == introStepsQtt + 1">
      <component :is="content.howTo" />
    </div>
    <span class="intro-timer">{{ formattedSecondsLeft }}</span>
    <button class="nav-btn prev-btn" @click.prevent="step -= 1" v-if="step > 0">
      &lt;
    </button>
    <button
      class="nav-btn next-btn"
      @click.prevent="step += 1"
      v-if="step <= introStepsQtt"
    >
      &gt;
    </button>
  </div>
</template>

<script>
import introsContent from "@/enums/intros.enum.js";
import RostockHowTo from "@/components/howTos/RostockHowTo.vue";
import HamburgHowTo from "@/components/howTos/HamburgHowTo.vue";
import DresdenHowTo from "@/components/howTos/DresdenHowTo.vue";
import MainzHowTo from "@/components/howTos/MainzHowTo.vue";
import EisenachHowTo from "@/components/howTos/EisenachHowTo.vue";
import KolnHowTo from "@/components/howTos/KolnHowTo.vue";
import MunchenHowTo from "@/components/howTos/MunchenHowTo.vue";
import WeimarHowTo from "@/components/howTos/WeimarHowTo.vue";
import BerlinHowTo from "@/components/howTos/BerlinHowTo.vue";
import FrankfurtHowTo from "@/components/howTos/FrankfurtHowTo.vue";
import MunsterHowTo from "@/components/howTos/MunsterHowTo.vue";
import BremenHowTo from "@/components/howTos/BremenHowTo.vue";
import BonnHowTo from "@/components/howTos/BonnHowTo.vue";
import NurnbergHowTo from "@/components/howTos/NurnbergHowTo.vue";
import StuttgartHowTo from "@/components/howTos/StuttgartHowTo.vue";
import config from "../../config.json";

export default {
  name: "GameIntro",
  props: ["city", "seconds"],
  components: {
    RostockHowTo,
    HamburgHowTo,
    DresdenHowTo,
    MainzHowTo,
    EisenachHowTo,
    KolnHowTo,
    MunchenHowTo,
    WeimarHowTo,
    BerlinHowTo,
    FrankfurtHowTo,
    MunsterHowTo,
    BremenHowTo,
    BonnHowTo,
    NurnbergHowTo,
    StuttgartHowTo,
  },
  data() {
    return {
      step: 0,
      content: introsContent[this.city],
      introSeconds: this.seconds,
    };
  },
  watch: {
    seconds(val) {
      this.introSeconds = val;
    },
  },
  mounted() {
    this.tryCountdown();
  },
  methods: {
    tryCountdown() {
      this.countDownIntro();
      setTimeout(() => {
        this.tryCountdown();
      }, 1000);
    },
    countDownIntro() {
      if (this.introSeconds) {
        this.introSeconds -= 1;
        if (this.introSeconds == 0) {
          this.$emit("timeoutIntro");
        }
      }
    },
  },
  computed: {
    introStepsQtt() {
      return this.content.steps.length;
    },
    formattedSecondsLeft() {
      var sec_num = parseInt(this.introSeconds, 10);
      var minutes = ("0" + (Math.floor(sec_num / 60) % 60)).slice(-2);
      var seconds = ("0" + (sec_num % 60)).slice(-2);
      return `${minutes} : ${seconds}`;
    },
    locale() {
      try {
        const navLang = navigator.language.slice(0, 2);
        if (["pt", "es", "de"].includes(navLang)) {
          return navLang;
        } else {
          return config.I18N_LOCALE;
        }
      } catch {
        return config.I18N_LOCALE;
      }
    },
    charImage() {
      const splitPath = this.content.char.split("/");
      return `${splitPath[0]}/${this.locale}/${splitPath[1]}`;
    },
  },
};
</script>

<style lang="scss">
.game-intro {
  height: 80%;
  width: 65%;
  position: relative;
  .intro-content-columns {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    .column-img {
      height: 100%;
      width: 40%;
      display: grid;
      place-items: center;
      .first-group {
        margin-bottom: 0rem;
      }
      .img-legend-group {
        width: 80%;
        img {
          display: block;
          width: 100%;
          border-radius: 20px;
          box-shadow: -0.5rem 0.4rem #000;
          margin-bottom: 0.7rem;
        }
        .img-legend {
          width: 100%;
          font-size: 0.5rem;
          font-style: italic;
          display: inline-block;
          line-height: 12px;
          font-family: "GoetheFFClan", sans-serif;
        }
      }
    }
    .column-text {
      height: 100%;
      width: 60%;
      display: grid;
      place-items: center;
      .text-wrapper {
        background: #fff;
        border-radius: 10px;
        padding: 5px;
        box-sizing: border-box;
        h1 {
          font-family: "TrashHand", sans-serif;
          font-size: 1.2rem;
          margin: 0;
          text-align: center;
        }
        p {
          font-family: "GoetheFFClan", sans-serif;
          font-size: 0.7rem;
        }
      }
    }
  }
  .intro-char {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    img {
      width: 80%;
    }
  }
  .intro-how-to {
    width: 100%;
    height: 100%;
  }

  .intro-timer {
    position: absolute;
    padding: 2px 5px;
    background: #fff;
    color: #000;
    border: 2px solid #000;
    box-shadow: -0.2rem 0.1rem #000;
    border-radius: 50px;
    font-size: 0.75rem;
    font-weight: 700;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: "GoetheFFClan", sans-serif;
  }

  .nav-btn {
    position: absolute;
    padding: 4px 10px;
    background: #fff;
    color: #000;
    border: 2px solid #000;
    box-shadow: -0.3rem 0.2rem #000;
    border-radius: 50px;
    font-size: 1.1rem;
    font-weight: 700;
    transition: 0.3s;
    cursor: pointer;
  }
  .nav-btn:hover {
    background: #ccc;
  }
  .prev-btn {
    bottom: 0;
    right: 60px;
  }
  .next-btn {
    bottom: 0;
    right: 0;
  }
}

@media (min-width: 650px) {
  .game-intro {
    .intro-content-columns {
      .column-img {
        width: 50%;
        .first-group {
          margin-bottom: 0.7rem;
        }
        .img-legend-group {
          img {
            margin-bottom: 0.5rem;
          }
          .img-legend {
            font-size: 0.6rem;
            line-height: 12px;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .game-intro {
    .intro-content-columns {
      .column-img {
        width: 50%;
        .first-group {
          margin-bottom: 0rem;
        }
        .img-legend-group {
          width: 300px;
          img {
            margin-bottom: 1rem;
          }
          .img-legend {
            font-size: 1rem;
            line-height: 16px;
          }
        }
      }
      .column-text {
        height: 100%;
        width: 50%;
        .text-wrapper {
          padding: 10px;
          h1 {
            font-size: 2rem;
          }
          p {
            font-size: 1.4rem;
            margin: 3rem 0;
          }
        }
      }
    }
    .intro-char {
      display: grid;
      place-items: center;
      img {
        height: 90%;
      }
    }

    .intro-timer {
      padding: 5px 15px;
      font-size: 1rem;
    }

    .nav-btn {
      padding: 5px 15px;
      font-size: 1.5rem;
    }
    .prev-btn {
      bottom: 0;
      right: 80px;
    }
  }
}
</style>
