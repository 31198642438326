<template>
  <section class="admin-login-session">
    <div class="nav">
      <div>
        <router-link to="/create-session">Create Session</router-link>
        <router-link to="/sessions" class="active">Sessions</router-link>
        <a @click="logout">Logout</a>
      </div>
    </div>
    <div>
      <h1>Open Sessions</h1>
      <h2>All datetimes are displayed in <u>GMT (London) time</u></h2>

      <table class="sessions-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Start</th>
            <th>End</th>
            <th>Timezone</th>
            <th>Status</th>
            <th>Details</th>
            <th>Ranking</th>
            <th>Finish Session</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="session in sessions" :key="session.id">
            <td>{{ session.id }}</td>
            <td>{{ session.dt_start_date }}</td>
            <td>{{ session.dt_end_date }}</td>
            <td>{{ session.str_timezone }}</td>
            <td>{{ session.str_status }}</td>
            <td class="view">
              <span @click="viewDetails(session.id)">View</span>
            </td>
            <td class="view">
              <span @click="viewGroups(session.id)">View</span>
            </td>
            <td class="terminate">
              <span
                v-if="session.str_status === 'ongoing'"
                @click="finishSession(session.id)"
              >
                Finish
              </span>
            </td>
            <td class="delete">
              <span @click="deleteSession(session.id)">Delete</span>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="loading" class="loading-message">Loading...</p>
      <p v-if="errorText" class="error-message">{{ errorText }}</p>

      <div class="details-modal-bg" v-if="showDetails">
        <div class="details-modal">
          <span class="close" @click="showDetails = false">X</span>
          <div class="details-wrapper">
            <div class="left">
              <div>
                <h1>Session #{{ sessionDetails.session.id }}</h1>
                <p>
                  <strong>Start:</strong>
                  {{ this.exportStartDate }}
                </p>
                <p><strong>End:</strong> {{ this.exportEndDate }}</p>
                <p>
                  <strong>Location:</strong>
                  {{ sessionDetails.session.str_timezone }}
                </p>
              </div>
            </div>
            <div class="right">
              <h2>User tokens</h2>
              <button class="download" @click="downloadTokensPdf">
                Download PDF
              </button>
              <button class="download" @click="downloadTokensCsv">
                Download CSV
              </button>
              <div class="table-wrapper">
                <table id="tokensTable" ref="tokensTable">
                  <tr v-for="token in sessionDetails.tokens" :key="token">
                    <td>{{ token }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="details-modal-bg" v-if="showGroups">
        <div class="details-modal">
          <span class="close" @click="showGroups = false">X</span>
          <div class="groups-wrapper">
            <div class="groups-table-wrapper">
              <table ref="groupsTable">
                <thead>
                  <tr>
                    <th>Position</th>
                    <th>ID</th>
                    <th>Points</th>
                    <th>Km</th>
                    <th>Frankfurt Code</th>
                    <th>Players</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(group, index) in sessionGroups" :key="group.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ group.group.id }}</td>
                    <td>{{ group.group.int_points }}</td>
                    <td>{{ group.group.int_km }}</td>
                    <td>{{ group.group.bl_correct_code ? "Yes" : "No" }}</td>
                    <td>
                      <span v-for="n in 4" :key="n"
                        >{{
                          group.players[n - 1] ? `${group.players[n - 1]},` : ""
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  apiDBAdmGetSessions,
  apiDBAdmGetSessionDetails,
  apiDBAdmGetSessionGroups,
  apiDBAdmDeleteSession,
  apiStateFetch,
} from "@/utils/goethe-apis.js";

import config from "../../config.json";

var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "Sessions",
  data() {
    return {
      sessionId: null,
      sessions: [],
      showDetails: false,
      showGroups: false,
      checkedRows: [],
      sessionDetails: {
        session: null,
        tokens: null,
      },
      sessionGroups: [],
      errorText: null,
      loading: false,
    };
  },
  created() {
    this.getSessions();
  },
  methods: {
    getSessions() {
      const token = localStorage.getItem("admAccessToken");
      apiDBAdmGetSessions(false, token)
        .then((res) => {
          if (res.status == 200) {
            this.sessions = res.data.sessions;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    viewDetails(id) {
      if (this.loading) return;

      this.loading = true;
      const token = localStorage.getItem("admAccessToken");
      apiDBAdmGetSessionDetails(id, token)
        .then((res) => {
          if (res.data.status == 200) {
            this.sessionDetails.session = res.data.session;
            this.sessionDetails.tokens = res.data.tokens;
            this.showDetails = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.showDetails = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewGroups(id) {
      if (this.loading) return;

      this.loading = true;
      const token = localStorage.getItem("admAccessToken");
      apiDBAdmGetSessionGroups(id, token)
        .then((res) => {
          if (res.data.status == 200) {
            this.sessionGroups = res.data.groups;
            this.showGroups = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.sessionGroups = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    finishSession(sessionId) {
      if (this.loading) return;

      this.loading = true;
      var fetchData = {
        token: config.STATE_TOKEN,
        request_type: "forceEndSession",
        session_id: sessionId,
      };
      apiStateFetch(fetchData)
        .then((res) => {
          if (res.status == 200) {
            this.getSessions();
            this.errorText = null;
          }
          if (res.data.status == 403) {
            this.errorText = res.data.message;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteSession(id) {
      if (this.loading) return;

      this.loading = true;
      const token = localStorage.getItem("admAccessToken");
      apiDBAdmDeleteSession(id, token)
        .then((res) => {
          if (res.status == 204) {
            this.getSessions();
            this.errorText = null;
          }
          if (res.data.status == 403) {
            this.errorText = res.data.message;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadTokensPdf() {
      var docDefinition = {
        content: [
          {
            text: `Autobahn Digital - Session #${this.sessionDetails.session.id}`,
            style: "header",
          },
          {
            text: `Start: ${this.exportStartDate} | End: ${this.exportEndDate}`,
            style: "subheader",
          },
          {
            text: `Timezone: ${this.sessionDetails.session.str_timezone}`,
            style: "subheader",
          },
          {
            table: {
              heights: 20,
              body: [],
            },
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          subheader: {
            margin: [0, 0, 0, 5],
          },
          tableContent: {
            margin: [0, 4, 0, 4],
          },
        },
      };

      var row = [];
      var counter = 1;
      var contentArray = this.sessionDetails.tokens;
      for (var i = 0; i < contentArray.length; i++) {
        row.push({ text: contentArray[i], style: "tableContent" });
        if (counter < 4) {
          counter++;
        } else {
          counter = 1;
          docDefinition.content[3].table.body.push(row);
          row = [];
        }
      }
      pdfMake.createPdf(docDefinition).download("autobahn_tokens.pdf");
    },
    downloadTokensCsv() {
      var rows = document.querySelectorAll("table#" + "tokensTable" + " tr");
      var csv = [];
      for (var i = 0; i < rows.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll("td");
        for (var j = 0; j < cols.length; j++) {
          var data = cols[j].innerText
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/(\s\s)/gm, " ");
          data = data.replace(/"/g, '""');
          row.push('"' + data + '"');
        }
        csv.push(row.join(","));
      }
      var csv_string = csv.join("\n");
      var filename =
        "export_" +
        "tokensTable" +
        "_" +
        new Date().toLocaleDateString() +
        ".csv";
      var link = document.createElement("a");
      link.style.display = "none";
      link.setAttribute("target", "_blank");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
      );
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    logout() {
      localStorage.removeItem("admAccessToken");
      this.$router.push("/adm-login");
    },
  },
  computed: {
    exportStartDate() {
      if (!this.sessionDetails.session.dt_start_date) {
        return "";
      }
      var date = new Date(this.sessionDetails.session.dt_start_date);
      return date.toLocaleString();
    },
    exportEndDate() {
      if (!this.sessionDetails.session.dt_end_date) {
        return "";
      }
      var date = new Date(this.sessionDetails.session.dt_end_date);
      return date.toLocaleString();
    },
  },
};
</script>

<style scoped lang="scss">
.admin-login-session {
  background: #d4ded5;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  text-align: center;
  font-family: "GoetheFFClan", sans-serif;
  position: relative;
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: #6a7a83;
    display: grid;
    place-items: center;
    a {
      color: #d4ded5;
      text-decoration: none;
      font-weight: 700;
      transition: 0.3s;
      margin: 0 2rem;
      cursor: pointer;
    }
    a:hover,
    .active {
      color: #ed6f23;
    }
  }
  h2 {
    font-size: 1rem;
  }
  .sessions-table {
    width: 90%;
    max-width: 1500px;
    table-layout: fixed;
    border-collapse: collapse;
    th,
    td {
      border-bottom: 1px solid #6a7a83;
      padding: 5px 0;
    }
    td {
      color: #6a7a83;
    }
    .view {
      color: #761e65;
      font-weight: 700;
    }
    .delete {
      color: #e50019;
      font-weight: 700;
    }
    .terminate {
      color: #ed6f23;
      font-weight: 700;
    }
    span {
      cursor: pointer;
    }
  }
  .details-modal-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    .details-modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 80%;
      width: 80%;
      max-height: 600px;
      max-width: 900px;
      background: #fff;
      border-radius: 15px;
      .close {
        position: absolute;
        top: 0;
        right: -50px;
        font-weight: 700;
        background: #fff;
        padding: 10px 15px;
        border-radius: 50%;
        cursor: pointer;
      }
      hr {
        border: 1px solid #98c23d;
        margin-left: 0;
      }
      .details-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        .left {
          height: 100%;
          width: calc(50% - 1px);
          display: grid;
          place-items: center;
          border-right: 2px solid #6a7a83;
          p {
            margin: 0.5rem 0;
          }
        }
        .right {
          height: 100%;
          width: calc(50% - 1px);
          h2 {
            font-size: 1.2rem;
            margin-bottom: 0.3rem;
          }
          .download {
            margin: 1rem 0.5rem;
            padding: 5px 15px;
            background: #4dc1ec;
            color: #fff;
            transition: 0.3s;
            cursor: pointer;
            border: none;
            border-radius: 5px;
          }
          .download:hover {
            background: #0a4879;
          }
          .table-wrapper {
            height: 70%;
            width: 60%;
            margin: 0 auto;
            border: 1px solid #d1d1d1;
            border-radius: 5px;
            overflow-y: scroll;
            table {
              width: 95%;
              margin: 0 auto;
              td {
                padding: 0.5rem 0;
              }
            }
          }
        }
      }
      .groups-wrapper {
        height: 100%;
        width: 100%;
        display: grid;
        place-items: center;
        .groups-table-wrapper {
          height: 90%;
          width: 90%;
          border: 1px solid #d1d1d1;
          border-radius: 5px;
          overflow-y: scroll;
          table {
            width: 95%;
            height: 100%;
            margin: 0 auto;
            td {
              padding: 0.5rem 0;
            }
          }
        }
      }
    }
  }
}

.loading-message {
  color: #4dc1ec;
  font-weight: 700;
}

.error-message {
  color: #e50019;
  font-weight: 700;
}
</style>
