<template>
  <div class="home-desktop">
    <div class="login">
      <img class="logo" src="@/assets/img/logo_white_vertical.svg" />
      <div class="login-content">
        <h1>{{ $t(`home.login`) }}</h1>
        <h2>{{ $t(`home.playerAccess`) }}</h2>

        <form @submit.prevent="login">
          <input
            type="text"
            class="text-input"
            :placeholder="$t(`home.type`)"
            v-model="token"
          />
          <p class="message" v-if="message">{{ message }}</p>
          <input
            type="submit"
            class="btn"
            :value="$t(`home.access`)"
            :disabled="!token"
          />
        </form>
      </div>
    </div>

    <div class="intro">
      <div class="content">
        <h1>{{ $t(`home.autobahn`) }}</h1>
        <p class="subtitle">
          {{ $t(`home.haveFun`) }}
        </p>
        <p class="text">{{ $t(`home.youngAudience`) }}</p>
      </div>
    </div>

    <div class="text-content">
      <p class="title">{{ $t(`home.about`) }}</p>

      <p class="text">
        {{ $t(`home.aboutP1`) }}
      </p>

      <p class="title">{{ $t(`home.goethe`) }}</p>

      <p class="text">
        {{ $t(`home.goetheP1`) }}
      </p>

      <p class="text">
        {{ $t(`home.goetheP2`) }}
      </p>

      <p class="text">
        {{ $t(`home.goetheP3`) }}
      </p>
    </div>

    <div class="footer">
      <img class="logo" src="@/assets/img/logo_white_horizontal.svg" />
      <p class="goethe-links">
        <a href="https://www.goethe.de/ins/br/pt/index.html" target="_blank">{{
          $t(`home.website`)
        }}</a>
      </p>
      <p class="goethe-links">
        <a href="https://blog.pasch-net.de/paschnobrasil/" target="_blank">{{
          $t(`home.pasch`)
        }}</a>
      </p>

      <hr />

      <p class="copyright">
        Copyright © 2023 Goethe-Institut - All Rights Reserved
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeDesktop",
  props: ["message"],
  data() {
    return {
      token: null,
    };
  },
  methods: {
    login() {
      this.$emit("login", this.token);
    },
  },
};
</script>

<style scoped lang="scss">
.home-desktop {
  font-family: "GoetheFFClan", sans-serif;
  width: 100vw;
  overflow-x: hidden;
}

.login {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  min-height: 100%;
  background: #9aca3b;
  display: grid;
  place-items: center;
  color: #fff;
  .logo {
    position: absolute;
    top: 40px;
    right: 80px;
    height: 100px;
  }
  .login-content {
    width: 75%;
    max-width: 400px;
    h1 {
      font-family: "GoetheFFClanBold", sans-serif;
      font-size: 3.5rem;
      margin: 0;
    }
    h2 {
      font-size: 1.3rem;
      margin: 1rem 0 3rem 0;
    }
    .text-input {
      border: 2px solid #fff;
      background: transparent;
      box-sizing: border-box;
      color: #fff;
      width: 100%;
      padding: 15px 15px;
      font-size: 0.9rem;
      border-radius: 10px;
    }
    .text-input::placeholder {
      color: #fff;
    }
    .text-input::-ms-input-placeholder {
      color: #fff;
    }
    .btn {
      margin-top: 1rem;
      border: 2px solid #fff;
      background: #fff;
      color: #9aca3b;
      font-size: 1rem;
      width: 100%;
      padding: 13px 15px;
      border-radius: 10px;
      font-weight: 700;
      cursor: pointer;
    }
    .message {
      color: #788287;
      margin: 1rem 0 0 0;
      font-weight: 700;
    }
  }
}

.intro {
  width: 50%;
  height: 100vh;
  display: grid;
  place-items: center;
  .content {
    margin: 0 12%;
    h1 {
      font-family: "GoetheFFClanBold", sans-serif;
      font-size: 3.5rem;
      margin: 0;
      color: #9aca3b;
    }
    .subtitle {
      font-family: "GoetheFFClanBold", sans-serif;
      font-size: 1.3rem;
      color: #788287;
    }
    .text {
      font-size: 1.1rem;
      color: #788287;
    }
  }
}

.text-content {
  width: 50%;
  padding-bottom: 3rem;
  p {
    margin-left: 12%;
    margin-right: 12%;
  }
  .title {
    font-family: "GoetheFFClanBold", sans-serif;
    color: #9aca3b;
    font-size: 2.8rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  .text {
    color: #788287;
    margin-bottom: 1.7rem;
  }
}

.footer {
  width: 50%;
  background: #374105;
  text-align: center;
  padding-bottom: 1.5rem;
  .logo {
    height: 80px;
    margin: 3rem auto;
  }
  .goethe-links {
    font-family: "GoetheFFClanBold", sans-serif;
    font-size: 1rem;
    margin: 0 0 1.5rem 0;
    a {
      color: #fff;
    }
  }
  hr {
    margin: 3rem 12.5% 1.5rem 12.5%;
    width: 75%;
    border: 0;
    height: 1px;
    background: #98c23d;
  }
  .copyright {
    margin: 0;
    color: #d0dabc;
    font-size: 0.65rem;
  }
}
</style>
