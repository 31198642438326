<template>
  <div class="logout-btn thinly-shadowed" @click="logout">
    <img class="logout-img" src="@/assets/img/utils/logout.svg" />
  </div>
</template>

<script>
export default {
  name: "LogoutBtn",
  methods: {
    logout() {
      this.$emit("logout");
    },
  },
};
</script>

<style scoped lang="scss">
.logout-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  background: #fff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  .logout-img {
    height: 70%;
    width: 70%;
  }
}

@media (min-width: 1024px) {
  .logout-btn {
    top: 15px;
    right: 15px;
    height: 30px;
    width: 30px;
  }
}
</style>
