var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.step)?_c('div',{staticClass:"level weimar-level"},[(_vm.step == 'intro')?_c('game-intro',{attrs:{"city":_vm.city,"seconds":_vm.introSeconds},on:{"timeoutIntro":_vm.timeoutIntro}}):_vm._e(),(_vm.step == 'game')?_c('div',{staticClass:"game shadowed"},[_c('div',{staticClass:"game-panel"},[_c('div',{staticClass:"wrapper"},[(_vm.currentStep <= 6)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t("weimar.game.titleBiography"))+" ")]):_vm._e(),(_vm.currentStep >= 7 && _vm.currentStep <= 10)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t("weimar.game.titleBlanks"))+" ")]):_vm._e(),(_vm.currentStep >= 11 && _vm.currentStep <= 12)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t("weimar.game.titleTrueOrFalse"))+" ")]):_vm._e(),(_vm.currentStep <= 6)?_c('div',{staticClass:"text-wrapper"},[_c('div',{staticClass:"text-holder"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t(`weimar.game.${_vm.gameText[_vm.currentStep]}`))+" ")])]),_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require(`@/assets/img/games/weimar/${_vm.gameText[_vm.currentStep]}.jpg`)}})])]):_vm._e(),(_vm.currentStep >= 7 && _vm.currentStep <= 10)?_c('div',{staticClass:"blanks-wrapper"},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t( `weimar.game.${ _vm.weimarData[_vm.currentStep - _vm.textSlides].question }.text` ))+" "),_c('span',{staticClass:"blanks-answer"},[_vm._v(_vm._s(_vm.weimarData[_vm.currentStep - _vm.textSlides].userAnswer > 0 ? _vm.$t( `weimar.game.${ _vm.weimarData[_vm.currentStep - _vm.textSlides].question }.correct` ) : "__________"))])]),_c('button',{staticClass:"thinly-shadowed",class:_vm.weimarData[_vm.currentStep - _vm.textSlides].userAnswer > 0
                  ? _vm.weimarData[_vm.currentStep - _vm.textSlides].correctAnswer == 1
                    ? 'correct'
                    : 'incorrect'
                  : '',attrs:{"disabled":_vm.weimarData[_vm.currentStep - _vm.textSlides].userAnswer > 0},on:{"click":function($event){return _vm.pickOption(_vm.currentStep - _vm.textSlides, 1)}}},[_vm._v(" "+_vm._s(_vm.$t( `weimar.game.${ _vm.weimarData[_vm.currentStep - _vm.textSlides].question }.op1` ))+" ")]),_c('button',{staticClass:"thinly-shadowed",class:_vm.weimarData[_vm.currentStep - _vm.textSlides].userAnswer > 0
                  ? _vm.weimarData[_vm.currentStep - _vm.textSlides].correctAnswer == 2
                    ? 'correct'
                    : 'incorrect'
                  : '',attrs:{"disabled":_vm.weimarData[_vm.currentStep - _vm.textSlides].userAnswer > 0},on:{"click":function($event){return _vm.pickOption(_vm.currentStep - _vm.textSlides, 2)}}},[_vm._v(" "+_vm._s(_vm.$t( `weimar.game.${ _vm.weimarData[_vm.currentStep - _vm.textSlides].question }.op2` ))+" ")])])]):_vm._e(),(_vm.currentStep >= 11 && _vm.currentStep <= 12)?_c('div',{staticClass:"true-false-wrapper"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( `weimar.game.${ _vm.weimarData[_vm.currentStep - _vm.textSlides].question }.text` ))+" ")]),_c('button',{staticClass:"thinly-shadowed",class:_vm.weimarData[_vm.currentStep - _vm.textSlides].userAnswer > 0
                ? _vm.weimarData[_vm.currentStep - _vm.textSlides].correctAnswer == 1
                  ? 'correct'
                  : 'incorrect'
                : '',attrs:{"disabled":_vm.weimarData[_vm.currentStep - _vm.textSlides].userAnswer > 0},on:{"click":function($event){return _vm.pickOption(_vm.currentStep - _vm.textSlides, 1)}}},[_vm._v(" "+_vm._s(_vm.$t("weimar.false"))+" ")]),_c('button',{staticClass:"thinly-shadowed",class:_vm.weimarData[_vm.currentStep - _vm.textSlides].userAnswer > 0
                ? _vm.weimarData[_vm.currentStep - _vm.textSlides].correctAnswer == 2
                  ? 'correct'
                  : 'incorrect'
                : '',attrs:{"disabled":_vm.weimarData[_vm.currentStep - _vm.textSlides].userAnswer > 0},on:{"click":function($event){return _vm.pickOption(_vm.currentStep - _vm.textSlides, 2)}}},[_vm._v(" "+_vm._s(_vm.$t("weimar.true"))+" ")]),(_vm.weimarData[_vm.currentStep - _vm.textSlides].userAnswer > 0)?_c('p',{staticClass:"true-false-answer"},[_vm._v(" "+_vm._s(_vm.$t( `weimar.game.${ _vm.weimarData[_vm.currentStep - _vm.textSlides].question }.correct` ))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"lower-panel text-shadow-extra-thin-black"},[_c('div',{staticClass:"game-info-display"},[_c('div',[(_vm.currentStep > 0)?_c('button',{staticClass:"thinly-shadowed",on:{"click":function($event){_vm.currentStep -= 1}}},[_vm._v(" "+_vm._s(_vm.$t("weimar.prev"))+" ")]):_vm._e(),(_vm.currentStep < 12)?_c('button',{staticClass:"thinly-shadowed",on:{"click":function($event){_vm.currentStep += 1}}},[_vm._v(" "+_vm._s(_vm.$t("weimar.next"))+" ")]):_vm._e()])]),_c('game-clock',{staticClass:"clock",attrs:{"seconds":_vm.seconds,"points":_vm.pointsDisplay},on:{"timeoutLevel":_vm.timeoutLevel}})],1),(_vm.lockEnd)?_c('waiting-players-notification'):_vm._e()],1):_vm._e(),_c('transition',{attrs:{"name":"slide-down"}},[(_vm.showHelp)?_c('help',{attrs:{"level":_vm.city,"color":_vm.color}}):_vm._e()],1),(_vm.step == 'game')?_c('help-btn',{attrs:{"helpShown":_vm.showHelp},on:{"showHelp":function($event){_vm.showHelp = !_vm.showHelp}}}):_vm._e(),_c('player-qtt',{attrs:{"qtt":1,"color":_vm.color}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }